.grid {
  z-index: 3;

  .grid-item {
    height: 220px;
    padding-bottom: 20px;
    z-index: 2;

    >a {
      @include size(100%);
      display: block;
      position: relative;

      &:hover {

        .caption {
          background: #000;
        }
      }
    }

    .caption {
      color: $white;
      background: rgba(#000, .7);
      transition: background-color .3s;
      padding: 15px;
      position: absolute;
      top: 100%;
      left: 0;
      right: 20%;
      transform: translateY(-100%);
      display: flex;
      align-items: center;
      min-height: 68px;
    }
  }
}
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar";

@for $i from 1 through length($color-values) {
  .covered-#{nth($color-names, $i)} {
    background: nth($color-values, $i) !important;
  }

  .colored-#{nth($color-names, $i)} {
    color: nth($color-values, $i) !important;
  }

  .hover-colored-#{nth($color-names, $i)} {

    &:hover,
    &:focus {
      color: nth($color-values, $i) !important;
    }
  }

  .hover-covered-#{nth($color-names, $i)} {

    &:hover,
    &:focus {
      background: nth($color-values, $i) !important;
    }
  }

  .top-banner {

    .slick-dots {

      &.active-#{nth($color-names, $i)} {

        button {

          &:hover,
          &:focus {
            background: nth($color-values, $i) !important;
          }
        }

        .slick-active {
          button {
            background: nth($color-values, $i) !important;
          }
        }
      }
      &.active-prod1 {

        button {

          &:hover,
          &:focus {
            background: #008c58 !important;
          }
        }

        .slick-active {
          button {
            background: #008c58 !important;
          }
        }
      }
    }
  }

  .bottom-nav {

    .header:not(.smallHeader) & {

      .hover-covered-#{nth($color-names, $i)} {

        @include media(">lg") {
          color: $gray-600 !important;
        }
      }
    }
  }

  .btn {

    &.btn-#{nth($color-names, $i)} {

      &.bordered {
        border: 1px solid nth($color-values, $i);
      }
    }
  }
}

@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}
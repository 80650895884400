.same-height-image {
  position: relative;
  z-index: 1;

  @include media(">sm") {
    padding-bottom: 70px;
  }

  p {

    &:last-child {

      @include media(">sm") {
        margin-bottom: 0;
      }
    }
  }

  .img-holder {
    min-height: 140px;

    @include media(">sm") {
      min-height: 100%;
    }

    @include media(">xlg") {
      min-height: 394px;
    }
  }

  .bg-holder {
    background-position: 0 75%;

    @include media(">sm") {
      background-position: 34% 0;
      background-size: 178%;
    }

    @include media(">lg") {
      background-position: center center;
      background-size: cover;
    }

    @include media(">xlg") {
      background-position: center 70%;
    }
  }

  .text-holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }
}

.d-flex-custom {
  @include media(">sm") {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

.image-cover {
  height: 100%;

  img {
    object-fit: cover;
    height: 100%;
    width: 100% !important;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: auto;
    }
  }
}
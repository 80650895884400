.template-img-holder {
  height: 100px;

  img {
    @include size(100%);
  }
}

.slick-wrap {
  position: relative;
  width: calc(100% - 80px);

  @include media(">xlg") {
    width: calc(100% - 65px);
  }
}

.arrow-separator {
  margin: 20px 0;
  position: relative;
  border-bottom: 2px dashed #dadada;

  &:after {
    position: absolute;
    content: "";
    border-width: 4px 0 4px 12px;
    border-color: $white $white $white #dadada;
    border-style: solid;
    top: -3px;
    right: 0;
  }
}

.format-table {
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    right: -5px;
    width: 5px;
    top: 0;
    bottom: 0;
    box-shadow: -5px 0 5px 0 rgba(#dadada, .5);
    position: absolute;

    @include media(">xlg") {
      display: none;
    }
  }

  .slick-control {
    position: absolute;
    z-index: 8;
    left: 0;
    top: 150px;
    transform: translateX(-82px) rotate(90deg);
    text-shadow: 0 0 10px #dadada;
    color: $white;
    transition: color .3s;
    font-size: 60px;

    @include media(">xlg") {
      display: none;
    }

    &:hover {
      color: $success;
    }

    &.slick-next {
      transform: translateX(-12px) rotate(-90deg);
      left: auto;
      right: 0;
    }
  }

  .slick-track,
  .slick-list {
    min-width: 100%;
  }
}

.data-titles {
  width: 55px;
  position: relative;
  margin-right: 15px;
  overflow: hidden;
  background: $white;
  border-right: 1px solid #dadada;
  margin-top: 75px;
  height: 100%;
  max-height: 670px;

  @include media(">lg") {
    margin-top: 85px;
    max-height: 675px;
  }

  @include media(">xlg") {
    z-index: -2;
  }

  span {
    padding: 15px 15px 15px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #dadada;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.lang-en .data-titles {
  width: 60px;
  margin-right: 10px;
}

.lang-fr .data-titles {
  width: 80px;
  margin-right: 10px;
}

.lang-it .data-titles {
  width: 85px;
  margin-right: 10px;
}

.lang-es .data-titles {
  width: 75px;
  margin-right: 10px;
}

.column-title {
  padding: 15px 15px 15px;
  margin: 0 7px 0 10px;
  border-bottom: 1px solid #dadada;
}

.items-holder,
.scroll-wrap {
  padding: 10px;
  border-left: 1px solid #dadada;

  @include media(">xlg") {
    padding-top: 0;
    margin-top: 10px;
  }
}

.item-caption {
  padding: 5px 15px 15px 15px;
  white-space: nowrap;
}

.data-content {

  @include media(">xlg") {
    display: flex;
    flex-wrap: nowrap;
  }
}

.data-slide {

  @include media(">xlg") {
    flex-grow: 1;
  }
}

.item-image {
  width: 62px;
  margin: 0 auto;
}
.format-block {
  margin-bottom: 20px;

  .top-block {
    margin-bottom: 20px;
  }

  .img-holder {
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }
}

.common-data-list {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0;

  dt {
    font-weight: normal;
    flex-shrink: 0;
    margin-right: 5px;
  }

  dd {
    margin: 0;
  }
}
.footer {
  background: $gray-600;
  padding: 30px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media(">sm") {
    padding: 50px 0;
  }

  @include media(">xlg") {
    padding-top: 90px;
    display: flex;
    align-items: center;
  }

  .top-nav {
    padding-top: 0;

    @include media(">sm") {
      padding: 0;
    }

    @include media(">xlg") {
      margin-right: 0;
    }

    li {

      @include media(">sm") {
        margin-bottom: 0;
      }
    }
  }

  .lang-list {
    display: flex;
    justify-content: center;

    @include media(">md") {
      margin: 0 15px;
      order: 10;
    }

    li {
      padding: 0 10px;
      position: relative;

      &:not(:first-child) {

        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 1px;
          background: $white;
          content: "";
          left: 0;
        }
      }
    }
  }

  .navigation-bottom {
    padding-bottom: 0;
  }

  a {
    font-size: 16px;
    font-weight: 500;
  }

  .nav-block {

    .block-title {
      border-bottom: 1px solid rgba($white, .5);

      @include media(">lg") {
        display: flex;
        height: 100%;
        min-height: 65px;
      }
      @include media(">xlg") {
        display: block;
        height: auto;
        min-height: inherit;
      }
    }

    div{
      &:first-child{
        a{
          @include media(">lg") {
            display: inline-block;
            align-self: flex-end;

          }
          @include media(">xlg") {
            display: block;
            align-self: flex-end;
          }
        }
      }
    }
  }
}

.navigation-top {

  @include media(">sm") {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  @include media(">md") {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  .socials-holder {

    @include media(">sm") {
      order: 15;
    }
  }

  .footer & {

    margin-bottom: 30px;

    a {

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    li {
      line-height: 1;
    }
  }
}

.footer-nav-links {

  li {

    @include media(">sm") {
      display: inline-block;
      vertical-align: top;
      padding: 0 10px;
    }
  }

  .footer-bottom & {

    @include media(">sm") {
      text-align: right;
    }
  }
}

.nav-block {

  .block-title {
    border-bottom: 1px solid rgba($white, .5);
  }
}

.footer-box-line {
  border-bottom: 1px solid #dadada;
  margin-top: 40px;
}
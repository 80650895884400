.recomended-item {
  position: relative;
  z-index: 1;
  display: block;
  margin-top: 20px;

  .item-title-holder {
    background: $white;
    display: block;
    position: absolute;
    padding: 12px 20px 12px 15px;
    top: 100%;
    transform: translateY(-100%);
    margin-top: -20px;
    color: $gray-900;
    transition: color .3s, background-color .3s;

    @include media(">sm") {
      margin-top: -30px;
      padding: 11px 20px 11px 15px;
      font-size: 16px;
      line-height: 18px;
    }

    @include media(">lg") {
      margin-top: -40px;
    }
  }

  &:hover,
  &:focus {

    .item-title-holder {
      color: $white;
      background: $gray-600;
    }
  }
}
.controls-holder {
  background-color: $white;
  padding: 15px;
  max-height: 150px;
  overflow-y: hidden;

  @include media(">sm") {
    max-height: 300px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &.show {
    overflow-y: auto;
  }

  .custom-control-label {
    position: relative;

    &:before {
      transform: none;
      top: 0;
    }

    &:after {
      transform: none;
      top: 0;
    }
  }
}

.filter-control {
  margin-bottom: 20px;
  position: relative;

  .btn.btn-info:not(:disabled):hover, .btn.btn-info:not(:disabled):active {
    background: $success;
  }
}

#controls-wrap {
  position: relative;
  z-index: 9;
  transition: transform .1s;
  width: 100%;

  &.fixed-position {
    height: 290px;
    margin-left: -100vw;
  }
}

.controls-opener {
  position: absolute;
  z-index: 10;
  right: -100vw;
  top: -60px;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  transform: rotate(45deg) translate(18px, -21px);

  @include media(">sm") {
    transform: rotate(45deg) translate(57px, -36px);
    right: calc(-150vw + 385px);
  }

  @include media(">md") {
    right: calc(-150vw + 520px);
  }

  i {
    transform: rotate(45deg) translateY(10px);
  }

  button {
    @include size(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    background-color: rgba($success, .7);
    color: $white;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    padding: 0;
  }

  .fixed-position & {
    opacity: 1;
    visibility: visible;

    .opened-controls & {

      + .controls-row {

        [class^="col-"] {
          transform: translateX(100vw);
        }
      }
    }
  }
}

.controls-row {
  padding-right: 15px;
  padding-left: 15px;

  .fixed-position & {

    [class^="col-"] {
      transform: translateX(0);
      transition: transform .3s;

      @for $i from 1 through 4 {
        $timeout: .15 * $i;

        &:nth-of-type(#{$i}) {
          transition-delay: #{$timeout}s;
        }
      }
    }
  }
}

.opened-controls {

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
    background: rgba(#000, .6);
    animation: fade-to-dark .3s;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s, visibility .3s;
  }

  &.noOverlay {

    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
}

#filtration-count {
  min-height: 20px;
}

@keyframes fade-to-dark {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.page-actions-aside {

  @include media(">md") {
    flex-shrink: 0;
    width: 200px;
    padding-left: 40px;
  }

  @include media(">lg") {
    padding-top: 30px;
  }

  a {
    @include size(70px);
    margin: 0 auto 20px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(">md") {
      @include size(160px);
      font-size: 80px;
    }

    i {
      margin: 0;
    }
  }
}
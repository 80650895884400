.systemfinder {

  .tab-pane {

    @include media(">lg") {
      opacity: 1;
    }
  }

  .fieldset {
    align-items: stretch;
    padding-bottom: 50px;

    @include media(">lg") {
      align-items: flex-start;
    }

    .layout-wrap {
      align-items: stretch;
      min-height: 100%;
      flex-grow: 1;

      @include media(">lg") {
        align-items: flex-start;
      }
    }

    >.tab-content {

      > .active {
        display: flex;
        flex-wrap: wrap;

        @include media(">lg") {
          display: block;
        }
      }
    }
  }

  .tab-content {
    flex-grow: 1;
  }

  .deep-title {
    margin-bottom: 10px;
  }

  .med {
    font-size: 15px;

    @include media(">sm") {
      font-size: 30px;
      font-weight: 300;
    }
  }

  .content-wrap {
    padding: 67px 0 30px;

    @include media(">sm") {
      padding: 90px 0 65px;
    }

    @include media(">lg") {
      padding: 110px 0 65px;
    }
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
  }

  h6 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .custom-checkbox {
    margin-bottom: 10px;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;

    .custom-control-label {
      width: 100%;
      cursor: pointer;
      display: flex;
      color: $white;
      line-height: 20px;

      span {
        padding: 6px 40px 6px 10px;
        transition: background-color .3s;

        .hasActiveBox & {
          background-color: #43464a;
        }

        &:last-child {
          flex-grow: 1;
        }

        &:not(:first-child) {
          position: relative;

          &:before,
          &:after {
            position: absolute;
            height: 1px;
            width: 16px;
            content: "";
            transform: rotate(45deg) translateY(-50%);
            background: #fff;
            left: -23px;
            top: 50%;
          }

          &:after {
            transform: rotate(-45deg) translateY(-50%);
          }
        }
      }

      &:before {
        @include size(30px);
        border-radius: 0;
        top: 0;
        transform: none;
        background: #a2a4a7;
        pointer-events: auto;
      }

      &:after {
        @include size(auto);
        top: 15px;
        left: 16px;
        transform: translate(-50%, -50%);
        color: #000;
        background-image: none !important;
        content: "\e915";
        font-family: 'icomoon' !important;
        opacity: 0;
        transition: opacity .3s;
      }

      &:hover {

        span {
          background: #2a2b2e;
        }
      }

      &:focus {

        &:before {
          box-shadow: none;
        }
      }
    }

    .custom-control-input {

      &:disabled {

        ~ .custom-control-label {
          color: #6f7175;
          pointer-events: none;
          cursor: not-allowed;

          &:before {
            background: #6f7175;
          }

          span {
            background: transparent;
          }

          + .tip {

            i {
              color: #6f7175 !important;
            }
          }
        }
      }

      &:checked {

        ~ .custom-control-label {

          span {
            background: #2a2b2e;
          }

          &:before {
            background: #a2a4a7;
            box-shadow: none;
          }

          &:after {
            color: #000;
            opacity: 1;
          }
        }
      }

      &:hover {

        ~ .custom-control-label {

          span {
            background: #2a2b2e;
          }
        }
      }

      &:focus {

        ~ .custom-control-label {

          &:before {
            box-shadow: none;
          }
        }
      }
    }

    .tip {
      right: 0;
    }
  }
}

.nav-pills {
  width: 225px;
  margin-right: 30px;
  position: relative;

  &:after {

    @include media(">md") {
      @include size(1px, 100%);
      position: absolute;
      content: "";
      right: -15px;
      top: 0;
      background: #dadada;
    }

    .block-inverse & {
      background: $white;
    }
  }

  .btn {
    padding-top: 0;
    padding-bottom: 0;
  }

  .amount {
    margin-right: 5px;
  }

  li {

    &:not(.nav-item) {
      opacity: .2;
      font-size: 14px;
      padding: 12px 15px;
      text-transform: uppercase;
      font-weight: 300;
    }
  }

  .nav-item {

    + li {

      &:not(.nav-item) {
        margin-top: 20px;
      }
    }
  }
}

.result-review {

  .badge {
    align-items: center;
    padding: 0 15px;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 10px;

    i {
      margin-right: 15px;
    }

    .close {
      margin-left: 15px;
      text-shadow: none;
      color: $white;
      opacity: 1;
      font-size: inherit;

      i {
        margin-right: 0;
      }
    }

    &:hover,
    &:focus {

      .close {
        color: inherit;
      }
    }
  }

  .submit-holder {
    margin-bottom: 10px;

    .btn {
      white-space: nowrap;

      i {

        @include media(">md") {
          margin-right: 0;
        }

        @include media(">lg") {
          margin-right: 15px;
        }
      }
    }
  }
}

.modalContainer {

  .modal-dialog {
    @include make-container;
    @include make-container-max-widths($container-max-widths, $grid-breakpoints);
  }
}
/* Slider */
@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=695fdabe-5695-4a6d-aa8b-da776f937652");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
.covered-white {
  background: #fff !important; }

.colored-white {
  color: #fff !important; }

.hover-colored-white:hover, .hover-colored-white:focus {
  color: #fff !important; }

.hover-covered-white:hover, .hover-covered-white:focus {
  background: #fff !important; }

.top-banner .slick-dots.active-white button:hover, .top-banner .slick-dots.active-white button:focus {
  background: #fff !important; }

.top-banner .slick-dots.active-white .slick-active button {
  background: #fff !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-white {
    color: #54575c !important; } }

.btn.btn-white.bordered {
  border: 1px solid #fff; }

.covered-black {
  background: #000 !important; }

.colored-black {
  color: #000 !important; }

.hover-colored-black:hover, .hover-colored-black:focus {
  color: #000 !important; }

.hover-covered-black:hover, .hover-covered-black:focus {
  background: #000 !important; }

.top-banner .slick-dots.active-black button:hover, .top-banner .slick-dots.active-black button:focus {
  background: #000 !important; }

.top-banner .slick-dots.active-black .slick-active button {
  background: #000 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-black {
    color: #54575c !important; } }

.btn.btn-black.bordered {
  border: 1px solid #000; }

.covered-prod1 {
  background: #5f8890 !important; }

.colored-prod1 {
  color: #5f8890 !important; }

.hover-colored-prod1:hover, .hover-colored-prod1:focus {
  color: #5f8890 !important; }

.hover-covered-prod1:hover, .hover-covered-prod1:focus {
  background: #5f8890 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #5f8890 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #5f8890 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-prod1 {
    color: #54575c !important; } }

.btn.btn-prod1.bordered {
  border: 1px solid #5f8890; }

.covered-prod2 {
  background: #6b7983 !important; }

.colored-prod2 {
  color: #6b7983 !important; }

.hover-colored-prod2:hover, .hover-colored-prod2:focus {
  color: #6b7983 !important; }

.hover-covered-prod2:hover, .hover-covered-prod2:focus {
  background: #6b7983 !important; }

.top-banner .slick-dots.active-prod2 button:hover, .top-banner .slick-dots.active-prod2 button:focus {
  background: #6b7983 !important; }

.top-banner .slick-dots.active-prod2 .slick-active button {
  background: #6b7983 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-prod2 {
    color: #54575c !important; } }

.btn.btn-prod2.bordered {
  border: 1px solid #6b7983; }

.covered-prod3 {
  background: #83afc3 !important; }

.colored-prod3 {
  color: #83afc3 !important; }

.hover-colored-prod3:hover, .hover-colored-prod3:focus {
  color: #83afc3 !important; }

.hover-covered-prod3:hover, .hover-covered-prod3:focus {
  background: #83afc3 !important; }

.top-banner .slick-dots.active-prod3 button:hover, .top-banner .slick-dots.active-prod3 button:focus {
  background: #83afc3 !important; }

.top-banner .slick-dots.active-prod3 .slick-active button {
  background: #83afc3 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-prod3 {
    color: #54575c !important; } }

.btn.btn-prod3.bordered {
  border: 1px solid #83afc3; }

.covered-prod4 {
  background: #5b8083 !important; }

.colored-prod4 {
  color: #5b8083 !important; }

.hover-colored-prod4:hover, .hover-colored-prod4:focus {
  color: #5b8083 !important; }

.hover-covered-prod4:hover, .hover-covered-prod4:focus {
  background: #5b8083 !important; }

.top-banner .slick-dots.active-prod4 button:hover, .top-banner .slick-dots.active-prod4 button:focus {
  background: #5b8083 !important; }

.top-banner .slick-dots.active-prod4 .slick-active button {
  background: #5b8083 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-prod4 {
    color: #54575c !important; } }

.btn.btn-prod4.bordered {
  border: 1px solid #5b8083; }

.covered-prod5 {
  background: #668997 !important; }

.colored-prod5 {
  color: #668997 !important; }

.hover-colored-prod5:hover, .hover-colored-prod5:focus {
  color: #668997 !important; }

.hover-covered-prod5:hover, .hover-covered-prod5:focus {
  background: #668997 !important; }

.top-banner .slick-dots.active-prod5 button:hover, .top-banner .slick-dots.active-prod5 button:focus {
  background: #668997 !important; }

.top-banner .slick-dots.active-prod5 .slick-active button {
  background: #668997 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-prod5 {
    color: #54575c !important; } }

.btn.btn-prod5.bordered {
  border: 1px solid #668997; }

.covered-transparent {
  background: rgba(0, 0, 0, 0) !important; }

.colored-transparent {
  color: rgba(0, 0, 0, 0) !important; }

.hover-colored-transparent:hover, .hover-colored-transparent:focus {
  color: rgba(0, 0, 0, 0) !important; }

.hover-covered-transparent:hover, .hover-covered-transparent:focus {
  background: rgba(0, 0, 0, 0) !important; }

.top-banner .slick-dots.active-transparent button:hover, .top-banner .slick-dots.active-transparent button:focus {
  background: rgba(0, 0, 0, 0) !important; }

.top-banner .slick-dots.active-transparent .slick-active button {
  background: rgba(0, 0, 0, 0) !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-transparent {
    color: #54575c !important; } }

.btn.btn-transparent.bordered {
  border: 1px solid rgba(0, 0, 0, 0); }

.covered-success {
  background: #008c58 !important; }

.colored-success {
  color: #008c58 !important; }

.hover-colored-success:hover, .hover-colored-success:focus {
  color: #008c58 !important; }

.hover-covered-success:hover, .hover-covered-success:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-success button:hover, .top-banner .slick-dots.active-success button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-success .slick-active button {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-success {
    color: #54575c !important; } }

.btn.btn-success.bordered {
  border: 1px solid #008c58; }

.covered-gray100 {
  background: #f7f7f7 !important; }

.colored-gray100 {
  color: #f7f7f7 !important; }

.hover-colored-gray100:hover, .hover-colored-gray100:focus {
  color: #f7f7f7 !important; }

.hover-covered-gray100:hover, .hover-covered-gray100:focus {
  background: #f7f7f7 !important; }

.top-banner .slick-dots.active-gray100 button:hover, .top-banner .slick-dots.active-gray100 button:focus {
  background: #f7f7f7 !important; }

.top-banner .slick-dots.active-gray100 .slick-active button {
  background: #f7f7f7 !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-gray100 {
    color: #54575c !important; } }

.btn.btn-gray100.bordered {
  border: 1px solid #f7f7f7; }

.covered-gray600 {
  background: #54575c !important; }

.colored-gray600 {
  color: #54575c !important; }

.hover-colored-gray600:hover, .hover-colored-gray600:focus {
  color: #54575c !important; }

.hover-covered-gray600:hover, .hover-covered-gray600:focus {
  background: #54575c !important; }

.top-banner .slick-dots.active-gray600 button:hover, .top-banner .slick-dots.active-gray600 button:focus {
  background: #54575c !important; }

.top-banner .slick-dots.active-gray600 .slick-active button {
  background: #54575c !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-gray600 {
    color: #54575c !important; } }

.btn.btn-gray600.bordered {
  border: 1px solid #54575c; }

.covered-info {
  background: #54575c !important; }

.colored-info {
  color: #54575c !important; }

.hover-colored-info:hover, .hover-colored-info:focus {
  color: #54575c !important; }

.hover-covered-info:hover, .hover-covered-info:focus {
  background: #54575c !important; }

.top-banner .slick-dots.active-info button:hover, .top-banner .slick-dots.active-info button:focus {
  background: #54575c !important; }

.top-banner .slick-dots.active-info .slick-active button {
  background: #54575c !important; }

.top-banner .slick-dots.active-prod1 button:hover, .top-banner .slick-dots.active-prod1 button:focus {
  background: #008c58 !important; }

.top-banner .slick-dots.active-prod1 .slick-active button {
  background: #008c58 !important; }

@media (min-width: 1220px) {
  .header:not(.smallHeader) .bottom-nav .hover-covered-info {
    color: #54575c !important; } }

.btn.btn-info.bordered {
  border: 1px solid #54575c; }

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("../fonts/helveticaNeue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
  src: url("../fonts/helveticaNeue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"), url("../fonts/helveticaNeue/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"), url("../fonts/helveticaNeue/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"), url("../fonts/helveticaNeue/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype"), url("../fonts/helveticaNeue/82a4e1c2-7ffd-4c58-86fa-4010a6723c8e.svg#82a4e1c2-7ffd-4c58-86fa-4010a6723c8e") format("svg");
  font-weight: normal; }

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("../fonts/helveticaNeue/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
  src: url("../fonts/helveticaNeue/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"), url("../fonts/helveticaNeue/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"), url("../fonts/helveticaNeue/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"), url("../fonts/helveticaNeue/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"), url("../fonts/helveticaNeue/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
  font-weight: 500; }

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("../fonts/helveticaNeue/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix");
  src: url("../fonts/helveticaNeue/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix") format("eot"), url("../fonts/helveticaNeue/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2") format("woff2"), url("../fonts/helveticaNeue/050b1948-f226-4d20-a65a-15d8ed031222.woff") format("woff"), url("../fonts/helveticaNeue/beb88be6-2a60-46fe-914f-6892b3ae46ce.ttf") format("truetype"), url("../fonts/helveticaNeue/e5567978-93ef-4de1-804d-1f0e3654a014.svg#e5567978-93ef-4de1-804d-1f0e3654a014") format("svg");
  font-weight: 600; }

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("../fonts/helveticaNeue/170b06bb-fec9-4e1b-9fa8-ddc9b686d8b5.eot?#iefix");
  src: url("../fonts/helveticaNeue/170b06bb-fec9-4e1b-9fa8-ddc9b686d8b5.eot?#iefix") format("eot"), url("../fonts/helveticaNeue/76623803-2b62-4c34-be21-c81ead28b379.woff2") format("woff2"), url("../fonts/helveticaNeue/327669a6-efac-4cce-9e96-13dd2d2e680e.woff") format("woff"), url("../fonts/helveticaNeue/b32ece14-a4d6-4dd6-959c-aad90747cb77.ttf") format("truetype"), url("../fonts/helveticaNeue/92786305-3921-440d-8e97-3188c1fa8070.svg#92786305-3921-440d-8e97-3188c1fa8070") format("svg");
  font-weight: 300; }

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("../fonts/helveticaNeue/aabcacc3-942c-44aa-9cec-85c00e69b36d.eot?#iefix");
  src: url("../fonts/helveticaNeue/aabcacc3-942c-44aa-9cec-85c00e69b36d.eot?#iefix") format("eot"), url("../fonts/helveticaNeue/b73a7721-ce68-4068-be2f-e354675b54c0.woff2") format("woff2"), url("../fonts/helveticaNeue/1be79393-9ab6-4f5e-8ff8-786d00be4238.woff") format("woff"), url("../fonts/helveticaNeue/c2335bef-8128-4a40-bd76-bb763d638a84.ttf") format("truetype"), url("../fonts/helveticaNeue/9874e59c-6906-4c68-a432-cabfeaf49c06.svg#9874e59c-6906-4c68-a432-cabfeaf49c06") format("svg");
  font-weight: 700; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?cmvaog");
  src: url("../fonts/icomoon.eot?cmvaog#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?cmvaog") format("truetype"), url("../fonts/icomoon.woff?cmvaog") format("woff"), url("../fonts/icomoon.svg?cmvaog#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon2-"], [class*=" icon2-"], [class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  transform: scale(2);
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-check:before {
  content: "\e916"; }

.icon-youtube:before {
  content: "\e915"; }

.icon-angle-down:before {
  content: "\e909"; }

.icon-angle-down-bold:before {
  content: "\e909";
  font-weight: bold; }

.icon-angle-left:before {
  content: "\e90a"; }

.icon-facebook:before {
  content: "\e911"; }

.icon-instagram:before {
  content: "\e913"; }

.icon-angle-right:before {
  content: "\e90b"; }

.icon-angle-up:before {
  content: "\e90c"; }

.icon-arrow-left:before {
  content: "\e90d"; }

.icon-pinterest:before {
  content: "\e914"; }

.icon-arrow-right:before {
  content: "\e90e"; }

.icon-arrow-up:before {
  content: "\e90f"; }

.icon-close:before {
  content: "\e910"; }

.icon-info-circle:before {
  content: "\e912"; }

.icon2-img:before,
.icon-img:before {
  content: "\e807"; }

.icon2-surface:before,
.icon-surface:before {
  content: "\e906"; }

.icon-angle-up:before {
  content: "\e901";
  transform: rotate(180deg); }

.icon-mail:before {
  content: "\e914"; }

.icon2-textur:before,
.icon-textur:before {
  content: "\e907"; }

.icon2-cart:before,
.icon-cart:before {
  content: "\e905"; }

.icon2-comments:before,
.icon-comments:before {
  content: "\e908"; }

.icon2-arrows:before,
.icon-arrows:before {
  content: "\e904"; }

.icon2-univer:before,
.icon-univer:before {
  content: "\e900"; }

.icon2-farben:before,
.icon-farben:before {
  content: "\e903"; }

.icon2-snow:before,
.icon-snow:before {
  content: "\e902"; }

.icon2-cog:before,
.icon-cog:before {
  content: "\e901"; }

.icon2-architekt:before,
.icon-architekt:before {
  content: "\e800"; }

.icon2-bim:before,
.icon-bim:before {
  content: "\e801"; }

.icon2-cad-files:before,
.icon-cad-files:before {
  content: "\e803"; }

.icon2-downloads:before,
.icon-downloads:before {
  content: "\e804"; }

.icon2-bestellen:before,
.icon-bestellen:before {
  content: "\e802"; }

.icon2-leistungserkla:before,
.icon-leistungserkla:before {
  content: "\e805"; }

.icon2-news:before,
.icon-news:before {
  content: "\e806"; }

.icon2-referenzen:before,
.icon-referenzen:before {
  content: "\e807"; }

.icon2-regeldetails:before,
.icon-regeldetails:before {
  content: "\e808"; }

.icon2-technische:before,
.icon-technische:before {
  content: "\e809"; }

.icon2-zertifizierungen:before,
.icon-zertifizierungen:before {
  content: "\e80a"; }

.icon2-catalog:before,
.icon-catalog:before {
  content: "\e802"; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Helvetica Neue LT", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #1a1a1b;
  text-align: left;
  background-color: #fff;
  letter-spacing: 0.25px; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: 600; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #54575c;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

.top-banner h1, .top-banner .h1 {
  font-size: 30px; }

h2, .h2 {
  font-size: 22px; }

h1, .h1, h3, .h3 {
  font-size: 22px; }

h4, .h4 {
  font-size: 22px; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #54575c; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #54575c; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 709px) {
    .container {
      max-width: 680px; } }
  @media (min-width: 979px) {
    .container {
      max-width: 950px; } }
  @media (min-width: 1219px) {
    .container {
      max-width: 1160px; } }
  @media (min-width: 1799px) {
    .container {
      max-width: 1810px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .sidebar-newsletter__item, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col-25, .col-26, .col-27, .col-28, .col-29, .col-30, .col-31, .col-32, .col-33, .col-34, .col-35, .col-36, .col-37, .col-38, .col-39, .col-40, .col-41, .col-42, .col-43, .col-44, .col-45, .col-46, .col-47, .col-48, .col-49, .col-50, .col-51, .col-52, .col-53, .col-54, .col-55, .col-56, .col-57, .col-58, .col-59, .col-60, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm-25, .col-sm-26, .col-sm-27, .col-sm-28, .col-sm-29, .col-sm-30, .col-sm-31, .col-sm-32, .col-sm-33, .col-sm-34, .col-sm-35, .col-sm-36, .col-sm-37, .col-sm-38, .col-sm-39, .col-sm-40, .col-sm-41, .col-sm-42, .col-sm-43, .col-sm-44, .col-sm-45, .col-sm-46, .col-sm-47, .col-sm-48, .col-sm-49, .col-sm-50, .col-sm-51, .col-sm-52, .col-sm-53, .col-sm-54, .col-sm-55, .col-sm-56, .col-sm-57, .col-sm-58, .col-sm-59, .col-sm-60, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md-25, .col-md-26, .col-md-27, .col-md-28, .col-md-29, .col-md-30, .col-md-31, .col-md-32, .col-md-33, .col-md-34, .col-md-35, .col-md-36, .col-md-37, .col-md-38, .col-md-39, .col-md-40, .col-md-41, .col-md-42, .col-md-43, .col-md-44, .col-md-45, .col-md-46, .col-md-47, .col-md-48, .col-md-49, .col-md-50, .col-md-51, .col-md-52, .col-md-53, .col-md-54, .col-md-55, .col-md-56, .col-md-57, .col-md-58, .col-md-59, .col-md-60, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg-25, .col-lg-26, .col-lg-27, .col-lg-28, .col-lg-29, .col-lg-30, .col-lg-31, .col-lg-32, .col-lg-33, .col-lg-34, .col-lg-35, .col-lg-36, .col-lg-37, .col-lg-38, .col-lg-39, .col-lg-40, .col-lg-41, .col-lg-42, .col-lg-43, .col-lg-44, .col-lg-45, .col-lg-46, .col-lg-47, .col-lg-48, .col-lg-49, .col-lg-50, .col-lg-51, .col-lg-52, .col-lg-53, .col-lg-54, .col-lg-55, .col-lg-56, .col-lg-57, .col-lg-58, .col-lg-59, .col-lg-60, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl-25, .col-xl-26, .col-xl-27, .col-xl-28, .col-xl-29, .col-xl-30, .col-xl-31, .col-xl-32, .col-xl-33, .col-xl-34, .col-xl-35, .col-xl-36, .col-xl-37, .col-xl-38, .col-xl-39, .col-xl-40, .col-xl-41, .col-xl-42, .col-xl-43, .col-xl-44, .col-xl-45, .col-xl-46, .col-xl-47, .col-xl-48, .col-xl-49, .col-xl-50, .col-xl-51, .col-xl-52, .col-xl-53, .col-xl-54, .col-xl-55, .col-xl-56, .col-xl-57, .col-xl-58, .col-xl-59, .col-xl-60, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 1.66667%;
  max-width: 1.66667%; }

.col-2 {
  flex: 0 0 3.33333%;
  max-width: 3.33333%; }

.col-3 {
  flex: 0 0 5%;
  max-width: 5%; }

.col-4 {
  flex: 0 0 6.66667%;
  max-width: 6.66667%; }

.col-5 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-6 {
  flex: 0 0 10%;
  max-width: 10%; }

.col-7 {
  flex: 0 0 11.66667%;
  max-width: 11.66667%; }

.col-8 {
  flex: 0 0 13.33333%;
  max-width: 13.33333%; }

.col-9 {
  flex: 0 0 15%;
  max-width: 15%; }

.col-10, .sidebar-newsletter__item {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-11 {
  flex: 0 0 18.33333%;
  max-width: 18.33333%; }

.col-12 {
  flex: 0 0 20%;
  max-width: 20%; }

.col-13 {
  flex: 0 0 21.66667%;
  max-width: 21.66667%; }

.col-14 {
  flex: 0 0 23.33333%;
  max-width: 23.33333%; }

.col-15 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-16 {
  flex: 0 0 26.66667%;
  max-width: 26.66667%; }

.col-17 {
  flex: 0 0 28.33333%;
  max-width: 28.33333%; }

.col-18 {
  flex: 0 0 30%;
  max-width: 30%; }

.col-19 {
  flex: 0 0 31.66667%;
  max-width: 31.66667%; }

.col-20 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-21 {
  flex: 0 0 35%;
  max-width: 35%; }

.col-22 {
  flex: 0 0 36.66667%;
  max-width: 36.66667%; }

.col-23 {
  flex: 0 0 38.33333%;
  max-width: 38.33333%; }

.col-24 {
  flex: 0 0 40%;
  max-width: 40%; }

.col-25 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-26 {
  flex: 0 0 43.33333%;
  max-width: 43.33333%; }

.col-27 {
  flex: 0 0 45%;
  max-width: 45%; }

.col-28 {
  flex: 0 0 46.66667%;
  max-width: 46.66667%; }

.col-29 {
  flex: 0 0 48.33333%;
  max-width: 48.33333%; }

.col-30 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-31 {
  flex: 0 0 51.66667%;
  max-width: 51.66667%; }

.col-32 {
  flex: 0 0 53.33333%;
  max-width: 53.33333%; }

.col-33 {
  flex: 0 0 55%;
  max-width: 55%; }

.col-34 {
  flex: 0 0 56.66667%;
  max-width: 56.66667%; }

.col-35 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-36 {
  flex: 0 0 60%;
  max-width: 60%; }

.col-37 {
  flex: 0 0 61.66667%;
  max-width: 61.66667%; }

.col-38 {
  flex: 0 0 63.33333%;
  max-width: 63.33333%; }

.col-39 {
  flex: 0 0 65%;
  max-width: 65%; }

.col-40 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-41 {
  flex: 0 0 68.33333%;
  max-width: 68.33333%; }

.col-42 {
  flex: 0 0 70%;
  max-width: 70%; }

.col-43 {
  flex: 0 0 71.66667%;
  max-width: 71.66667%; }

.col-44 {
  flex: 0 0 73.33333%;
  max-width: 73.33333%; }

.col-45 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-46 {
  flex: 0 0 76.66667%;
  max-width: 76.66667%; }

.col-47 {
  flex: 0 0 78.33333%;
  max-width: 78.33333%; }

.col-48 {
  flex: 0 0 80%;
  max-width: 80%; }

.col-49 {
  flex: 0 0 81.66667%;
  max-width: 81.66667%; }

.col-50 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-51 {
  flex: 0 0 85%;
  max-width: 85%; }

.col-52 {
  flex: 0 0 86.66667%;
  max-width: 86.66667%; }

.col-53 {
  flex: 0 0 88.33333%;
  max-width: 88.33333%; }

.col-54 {
  flex: 0 0 90%;
  max-width: 90%; }

.col-55 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-56 {
  flex: 0 0 93.33333%;
  max-width: 93.33333%; }

.col-57 {
  flex: 0 0 95%;
  max-width: 95%; }

.col-58 {
  flex: 0 0 96.66667%;
  max-width: 96.66667%; }

.col-59 {
  flex: 0 0 98.33333%;
  max-width: 98.33333%; }

.col-60 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 61; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.order-25 {
  order: 25; }

.order-26 {
  order: 26; }

.order-27 {
  order: 27; }

.order-28 {
  order: 28; }

.order-29 {
  order: 29; }

.order-30 {
  order: 30; }

.order-31 {
  order: 31; }

.order-32 {
  order: 32; }

.order-33 {
  order: 33; }

.order-34 {
  order: 34; }

.order-35 {
  order: 35; }

.order-36 {
  order: 36; }

.order-37 {
  order: 37; }

.order-38 {
  order: 38; }

.order-39 {
  order: 39; }

.order-40 {
  order: 40; }

.order-41 {
  order: 41; }

.order-42 {
  order: 42; }

.order-43 {
  order: 43; }

.order-44 {
  order: 44; }

.order-45 {
  order: 45; }

.order-46 {
  order: 46; }

.order-47 {
  order: 47; }

.order-48 {
  order: 48; }

.order-49 {
  order: 49; }

.order-50 {
  order: 50; }

.order-51 {
  order: 51; }

.order-52 {
  order: 52; }

.order-53 {
  order: 53; }

.order-54 {
  order: 54; }

.order-55 {
  order: 55; }

.order-56 {
  order: 56; }

.order-57 {
  order: 57; }

.order-58 {
  order: 58; }

.order-59 {
  order: 59; }

.order-60 {
  order: 60; }

.offset-1 {
  margin-left: 1.66667%; }

.offset-2 {
  margin-left: 3.33333%; }

.offset-3 {
  margin-left: 5%; }

.offset-4 {
  margin-left: 6.66667%; }

.offset-5 {
  margin-left: 8.33333%; }

.offset-6 {
  margin-left: 10%; }

.offset-7 {
  margin-left: 11.66667%; }

.offset-8 {
  margin-left: 13.33333%; }

.offset-9 {
  margin-left: 15%; }

.offset-10 {
  margin-left: 16.66667%; }

.offset-11 {
  margin-left: 18.33333%; }

.offset-12 {
  margin-left: 20%; }

.offset-13 {
  margin-left: 21.66667%; }

.offset-14 {
  margin-left: 23.33333%; }

.offset-15 {
  margin-left: 25%; }

.offset-16 {
  margin-left: 26.66667%; }

.offset-17 {
  margin-left: 28.33333%; }

.offset-18 {
  margin-left: 30%; }

.offset-19 {
  margin-left: 31.66667%; }

.offset-20 {
  margin-left: 33.33333%; }

.offset-21 {
  margin-left: 35%; }

.offset-22 {
  margin-left: 36.66667%; }

.offset-23 {
  margin-left: 38.33333%; }

.offset-24 {
  margin-left: 40%; }

.offset-25 {
  margin-left: 41.66667%; }

.offset-26 {
  margin-left: 43.33333%; }

.offset-27 {
  margin-left: 45%; }

.offset-28 {
  margin-left: 46.66667%; }

.offset-29 {
  margin-left: 48.33333%; }

.offset-30 {
  margin-left: 50%; }

.offset-31 {
  margin-left: 51.66667%; }

.offset-32 {
  margin-left: 53.33333%; }

.offset-33 {
  margin-left: 55%; }

.offset-34 {
  margin-left: 56.66667%; }

.offset-35 {
  margin-left: 58.33333%; }

.offset-36 {
  margin-left: 60%; }

.offset-37 {
  margin-left: 61.66667%; }

.offset-38 {
  margin-left: 63.33333%; }

.offset-39 {
  margin-left: 65%; }

.offset-40 {
  margin-left: 66.66667%; }

.offset-41 {
  margin-left: 68.33333%; }

.offset-42 {
  margin-left: 70%; }

.offset-43 {
  margin-left: 71.66667%; }

.offset-44 {
  margin-left: 73.33333%; }

.offset-45 {
  margin-left: 75%; }

.offset-46 {
  margin-left: 76.66667%; }

.offset-47 {
  margin-left: 78.33333%; }

.offset-48 {
  margin-left: 80%; }

.offset-49 {
  margin-left: 81.66667%; }

.offset-50 {
  margin-left: 83.33333%; }

.offset-51 {
  margin-left: 85%; }

.offset-52 {
  margin-left: 86.66667%; }

.offset-53 {
  margin-left: 88.33333%; }

.offset-54 {
  margin-left: 90%; }

.offset-55 {
  margin-left: 91.66667%; }

.offset-56 {
  margin-left: 93.33333%; }

.offset-57 {
  margin-left: 95%; }

.offset-58 {
  margin-left: 96.66667%; }

.offset-59 {
  margin-left: 98.33333%; }

@media (min-width: 709px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 1.66667%;
    max-width: 1.66667%; }
  .col-sm-2 {
    flex: 0 0 3.33333%;
    max-width: 3.33333%; }
  .col-sm-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-sm-4 {
    flex: 0 0 6.66667%;
    max-width: 6.66667%; }
  .col-sm-5 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-sm-7 {
    flex: 0 0 11.66667%;
    max-width: 11.66667%; }
  .col-sm-8 {
    flex: 0 0 13.33333%;
    max-width: 13.33333%; }
  .col-sm-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-sm-10 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-11 {
    flex: 0 0 18.33333%;
    max-width: 18.33333%; }
  .col-sm-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-sm-13 {
    flex: 0 0 21.66667%;
    max-width: 21.66667%; }
  .col-sm-14 {
    flex: 0 0 23.33333%;
    max-width: 23.33333%; }
  .col-sm-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-16 {
    flex: 0 0 26.66667%;
    max-width: 26.66667%; }
  .col-sm-17 {
    flex: 0 0 28.33333%;
    max-width: 28.33333%; }
  .col-sm-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-sm-19 {
    flex: 0 0 31.66667%;
    max-width: 31.66667%; }
  .col-sm-20 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-sm-22 {
    flex: 0 0 36.66667%;
    max-width: 36.66667%; }
  .col-sm-23 {
    flex: 0 0 38.33333%;
    max-width: 38.33333%; }
  .col-sm-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-sm-25 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-26 {
    flex: 0 0 43.33333%;
    max-width: 43.33333%; }
  .col-sm-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-sm-28 {
    flex: 0 0 46.66667%;
    max-width: 46.66667%; }
  .col-sm-29 {
    flex: 0 0 48.33333%;
    max-width: 48.33333%; }
  .col-sm-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-31 {
    flex: 0 0 51.66667%;
    max-width: 51.66667%; }
  .col-sm-32 {
    flex: 0 0 53.33333%;
    max-width: 53.33333%; }
  .col-sm-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-sm-34 {
    flex: 0 0 56.66667%;
    max-width: 56.66667%; }
  .col-sm-35 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-sm-37 {
    flex: 0 0 61.66667%;
    max-width: 61.66667%; }
  .col-sm-38 {
    flex: 0 0 63.33333%;
    max-width: 63.33333%; }
  .col-sm-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-sm-40 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-41 {
    flex: 0 0 68.33333%;
    max-width: 68.33333%; }
  .col-sm-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-sm-43 {
    flex: 0 0 71.66667%;
    max-width: 71.66667%; }
  .col-sm-44 {
    flex: 0 0 73.33333%;
    max-width: 73.33333%; }
  .col-sm-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-46 {
    flex: 0 0 76.66667%;
    max-width: 76.66667%; }
  .col-sm-47 {
    flex: 0 0 78.33333%;
    max-width: 78.33333%; }
  .col-sm-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-sm-49 {
    flex: 0 0 81.66667%;
    max-width: 81.66667%; }
  .col-sm-50 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-sm-52 {
    flex: 0 0 86.66667%;
    max-width: 86.66667%; }
  .col-sm-53 {
    flex: 0 0 88.33333%;
    max-width: 88.33333%; }
  .col-sm-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-sm-55 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-56 {
    flex: 0 0 93.33333%;
    max-width: 93.33333%; }
  .col-sm-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-sm-58 {
    flex: 0 0 96.66667%;
    max-width: 96.66667%; }
  .col-sm-59 {
    flex: 0 0 98.33333%;
    max-width: 98.33333%; }
  .col-sm-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 61; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .order-sm-25 {
    order: 25; }
  .order-sm-26 {
    order: 26; }
  .order-sm-27 {
    order: 27; }
  .order-sm-28 {
    order: 28; }
  .order-sm-29 {
    order: 29; }
  .order-sm-30 {
    order: 30; }
  .order-sm-31 {
    order: 31; }
  .order-sm-32 {
    order: 32; }
  .order-sm-33 {
    order: 33; }
  .order-sm-34 {
    order: 34; }
  .order-sm-35 {
    order: 35; }
  .order-sm-36 {
    order: 36; }
  .order-sm-37 {
    order: 37; }
  .order-sm-38 {
    order: 38; }
  .order-sm-39 {
    order: 39; }
  .order-sm-40 {
    order: 40; }
  .order-sm-41 {
    order: 41; }
  .order-sm-42 {
    order: 42; }
  .order-sm-43 {
    order: 43; }
  .order-sm-44 {
    order: 44; }
  .order-sm-45 {
    order: 45; }
  .order-sm-46 {
    order: 46; }
  .order-sm-47 {
    order: 47; }
  .order-sm-48 {
    order: 48; }
  .order-sm-49 {
    order: 49; }
  .order-sm-50 {
    order: 50; }
  .order-sm-51 {
    order: 51; }
  .order-sm-52 {
    order: 52; }
  .order-sm-53 {
    order: 53; }
  .order-sm-54 {
    order: 54; }
  .order-sm-55 {
    order: 55; }
  .order-sm-56 {
    order: 56; }
  .order-sm-57 {
    order: 57; }
  .order-sm-58 {
    order: 58; }
  .order-sm-59 {
    order: 59; }
  .order-sm-60 {
    order: 60; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 1.66667%; }
  .offset-sm-2 {
    margin-left: 3.33333%; }
  .offset-sm-3 {
    margin-left: 5%; }
  .offset-sm-4 {
    margin-left: 6.66667%; }
  .offset-sm-5 {
    margin-left: 8.33333%; }
  .offset-sm-6 {
    margin-left: 10%; }
  .offset-sm-7 {
    margin-left: 11.66667%; }
  .offset-sm-8 {
    margin-left: 13.33333%; }
  .offset-sm-9 {
    margin-left: 15%; }
  .offset-sm-10 {
    margin-left: 16.66667%; }
  .offset-sm-11 {
    margin-left: 18.33333%; }
  .offset-sm-12 {
    margin-left: 20%; }
  .offset-sm-13 {
    margin-left: 21.66667%; }
  .offset-sm-14 {
    margin-left: 23.33333%; }
  .offset-sm-15 {
    margin-left: 25%; }
  .offset-sm-16 {
    margin-left: 26.66667%; }
  .offset-sm-17 {
    margin-left: 28.33333%; }
  .offset-sm-18 {
    margin-left: 30%; }
  .offset-sm-19 {
    margin-left: 31.66667%; }
  .offset-sm-20 {
    margin-left: 33.33333%; }
  .offset-sm-21 {
    margin-left: 35%; }
  .offset-sm-22 {
    margin-left: 36.66667%; }
  .offset-sm-23 {
    margin-left: 38.33333%; }
  .offset-sm-24 {
    margin-left: 40%; }
  .offset-sm-25 {
    margin-left: 41.66667%; }
  .offset-sm-26 {
    margin-left: 43.33333%; }
  .offset-sm-27 {
    margin-left: 45%; }
  .offset-sm-28 {
    margin-left: 46.66667%; }
  .offset-sm-29 {
    margin-left: 48.33333%; }
  .offset-sm-30 {
    margin-left: 50%; }
  .offset-sm-31 {
    margin-left: 51.66667%; }
  .offset-sm-32 {
    margin-left: 53.33333%; }
  .offset-sm-33 {
    margin-left: 55%; }
  .offset-sm-34 {
    margin-left: 56.66667%; }
  .offset-sm-35 {
    margin-left: 58.33333%; }
  .offset-sm-36 {
    margin-left: 60%; }
  .offset-sm-37 {
    margin-left: 61.66667%; }
  .offset-sm-38 {
    margin-left: 63.33333%; }
  .offset-sm-39 {
    margin-left: 65%; }
  .offset-sm-40 {
    margin-left: 66.66667%; }
  .offset-sm-41 {
    margin-left: 68.33333%; }
  .offset-sm-42 {
    margin-left: 70%; }
  .offset-sm-43 {
    margin-left: 71.66667%; }
  .offset-sm-44 {
    margin-left: 73.33333%; }
  .offset-sm-45 {
    margin-left: 75%; }
  .offset-sm-46 {
    margin-left: 76.66667%; }
  .offset-sm-47 {
    margin-left: 78.33333%; }
  .offset-sm-48 {
    margin-left: 80%; }
  .offset-sm-49 {
    margin-left: 81.66667%; }
  .offset-sm-50 {
    margin-left: 83.33333%; }
  .offset-sm-51 {
    margin-left: 85%; }
  .offset-sm-52 {
    margin-left: 86.66667%; }
  .offset-sm-53 {
    margin-left: 88.33333%; }
  .offset-sm-54 {
    margin-left: 90%; }
  .offset-sm-55 {
    margin-left: 91.66667%; }
  .offset-sm-56 {
    margin-left: 93.33333%; }
  .offset-sm-57 {
    margin-left: 95%; }
  .offset-sm-58 {
    margin-left: 96.66667%; }
  .offset-sm-59 {
    margin-left: 98.33333%; } }

@media (min-width: 979px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 1.66667%;
    max-width: 1.66667%; }
  .col-md-2 {
    flex: 0 0 3.33333%;
    max-width: 3.33333%; }
  .col-md-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-md-4 {
    flex: 0 0 6.66667%;
    max-width: 6.66667%; }
  .col-md-5 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-md-7 {
    flex: 0 0 11.66667%;
    max-width: 11.66667%; }
  .col-md-8 {
    flex: 0 0 13.33333%;
    max-width: 13.33333%; }
  .col-md-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-md-10 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-11 {
    flex: 0 0 18.33333%;
    max-width: 18.33333%; }
  .col-md-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-md-13 {
    flex: 0 0 21.66667%;
    max-width: 21.66667%; }
  .col-md-14 {
    flex: 0 0 23.33333%;
    max-width: 23.33333%; }
  .col-md-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-16 {
    flex: 0 0 26.66667%;
    max-width: 26.66667%; }
  .col-md-17 {
    flex: 0 0 28.33333%;
    max-width: 28.33333%; }
  .col-md-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-md-19 {
    flex: 0 0 31.66667%;
    max-width: 31.66667%; }
  .col-md-20 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-md-22 {
    flex: 0 0 36.66667%;
    max-width: 36.66667%; }
  .col-md-23 {
    flex: 0 0 38.33333%;
    max-width: 38.33333%; }
  .col-md-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-md-25 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-26 {
    flex: 0 0 43.33333%;
    max-width: 43.33333%; }
  .col-md-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-md-28 {
    flex: 0 0 46.66667%;
    max-width: 46.66667%; }
  .col-md-29 {
    flex: 0 0 48.33333%;
    max-width: 48.33333%; }
  .col-md-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-31 {
    flex: 0 0 51.66667%;
    max-width: 51.66667%; }
  .col-md-32 {
    flex: 0 0 53.33333%;
    max-width: 53.33333%; }
  .col-md-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-md-34 {
    flex: 0 0 56.66667%;
    max-width: 56.66667%; }
  .col-md-35 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-md-37 {
    flex: 0 0 61.66667%;
    max-width: 61.66667%; }
  .col-md-38 {
    flex: 0 0 63.33333%;
    max-width: 63.33333%; }
  .col-md-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-md-40 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-41 {
    flex: 0 0 68.33333%;
    max-width: 68.33333%; }
  .col-md-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-md-43 {
    flex: 0 0 71.66667%;
    max-width: 71.66667%; }
  .col-md-44 {
    flex: 0 0 73.33333%;
    max-width: 73.33333%; }
  .col-md-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-46 {
    flex: 0 0 76.66667%;
    max-width: 76.66667%; }
  .col-md-47 {
    flex: 0 0 78.33333%;
    max-width: 78.33333%; }
  .col-md-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-md-49 {
    flex: 0 0 81.66667%;
    max-width: 81.66667%; }
  .col-md-50 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-md-52 {
    flex: 0 0 86.66667%;
    max-width: 86.66667%; }
  .col-md-53 {
    flex: 0 0 88.33333%;
    max-width: 88.33333%; }
  .col-md-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-md-55 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-56 {
    flex: 0 0 93.33333%;
    max-width: 93.33333%; }
  .col-md-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-md-58 {
    flex: 0 0 96.66667%;
    max-width: 96.66667%; }
  .col-md-59 {
    flex: 0 0 98.33333%;
    max-width: 98.33333%; }
  .col-md-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 61; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .order-md-25 {
    order: 25; }
  .order-md-26 {
    order: 26; }
  .order-md-27 {
    order: 27; }
  .order-md-28 {
    order: 28; }
  .order-md-29 {
    order: 29; }
  .order-md-30 {
    order: 30; }
  .order-md-31 {
    order: 31; }
  .order-md-32 {
    order: 32; }
  .order-md-33 {
    order: 33; }
  .order-md-34 {
    order: 34; }
  .order-md-35 {
    order: 35; }
  .order-md-36 {
    order: 36; }
  .order-md-37 {
    order: 37; }
  .order-md-38 {
    order: 38; }
  .order-md-39 {
    order: 39; }
  .order-md-40 {
    order: 40; }
  .order-md-41 {
    order: 41; }
  .order-md-42 {
    order: 42; }
  .order-md-43 {
    order: 43; }
  .order-md-44 {
    order: 44; }
  .order-md-45 {
    order: 45; }
  .order-md-46 {
    order: 46; }
  .order-md-47 {
    order: 47; }
  .order-md-48 {
    order: 48; }
  .order-md-49 {
    order: 49; }
  .order-md-50 {
    order: 50; }
  .order-md-51 {
    order: 51; }
  .order-md-52 {
    order: 52; }
  .order-md-53 {
    order: 53; }
  .order-md-54 {
    order: 54; }
  .order-md-55 {
    order: 55; }
  .order-md-56 {
    order: 56; }
  .order-md-57 {
    order: 57; }
  .order-md-58 {
    order: 58; }
  .order-md-59 {
    order: 59; }
  .order-md-60 {
    order: 60; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 1.66667%; }
  .offset-md-2 {
    margin-left: 3.33333%; }
  .offset-md-3 {
    margin-left: 5%; }
  .offset-md-4 {
    margin-left: 6.66667%; }
  .offset-md-5 {
    margin-left: 8.33333%; }
  .offset-md-6 {
    margin-left: 10%; }
  .offset-md-7 {
    margin-left: 11.66667%; }
  .offset-md-8 {
    margin-left: 13.33333%; }
  .offset-md-9 {
    margin-left: 15%; }
  .offset-md-10 {
    margin-left: 16.66667%; }
  .offset-md-11 {
    margin-left: 18.33333%; }
  .offset-md-12 {
    margin-left: 20%; }
  .offset-md-13 {
    margin-left: 21.66667%; }
  .offset-md-14 {
    margin-left: 23.33333%; }
  .offset-md-15 {
    margin-left: 25%; }
  .offset-md-16 {
    margin-left: 26.66667%; }
  .offset-md-17 {
    margin-left: 28.33333%; }
  .offset-md-18 {
    margin-left: 30%; }
  .offset-md-19 {
    margin-left: 31.66667%; }
  .offset-md-20 {
    margin-left: 33.33333%; }
  .offset-md-21 {
    margin-left: 35%; }
  .offset-md-22 {
    margin-left: 36.66667%; }
  .offset-md-23 {
    margin-left: 38.33333%; }
  .offset-md-24 {
    margin-left: 40%; }
  .offset-md-25 {
    margin-left: 41.66667%; }
  .offset-md-26 {
    margin-left: 43.33333%; }
  .offset-md-27 {
    margin-left: 45%; }
  .offset-md-28 {
    margin-left: 46.66667%; }
  .offset-md-29 {
    margin-left: 48.33333%; }
  .offset-md-30 {
    margin-left: 50%; }
  .offset-md-31 {
    margin-left: 51.66667%; }
  .offset-md-32 {
    margin-left: 53.33333%; }
  .offset-md-33 {
    margin-left: 55%; }
  .offset-md-34 {
    margin-left: 56.66667%; }
  .offset-md-35 {
    margin-left: 58.33333%; }
  .offset-md-36 {
    margin-left: 60%; }
  .offset-md-37 {
    margin-left: 61.66667%; }
  .offset-md-38 {
    margin-left: 63.33333%; }
  .offset-md-39 {
    margin-left: 65%; }
  .offset-md-40 {
    margin-left: 66.66667%; }
  .offset-md-41 {
    margin-left: 68.33333%; }
  .offset-md-42 {
    margin-left: 70%; }
  .offset-md-43 {
    margin-left: 71.66667%; }
  .offset-md-44 {
    margin-left: 73.33333%; }
  .offset-md-45 {
    margin-left: 75%; }
  .offset-md-46 {
    margin-left: 76.66667%; }
  .offset-md-47 {
    margin-left: 78.33333%; }
  .offset-md-48 {
    margin-left: 80%; }
  .offset-md-49 {
    margin-left: 81.66667%; }
  .offset-md-50 {
    margin-left: 83.33333%; }
  .offset-md-51 {
    margin-left: 85%; }
  .offset-md-52 {
    margin-left: 86.66667%; }
  .offset-md-53 {
    margin-left: 88.33333%; }
  .offset-md-54 {
    margin-left: 90%; }
  .offset-md-55 {
    margin-left: 91.66667%; }
  .offset-md-56 {
    margin-left: 93.33333%; }
  .offset-md-57 {
    margin-left: 95%; }
  .offset-md-58 {
    margin-left: 96.66667%; }
  .offset-md-59 {
    margin-left: 98.33333%; } }

@media (min-width: 1219px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 1.66667%;
    max-width: 1.66667%; }
  .col-lg-2 {
    flex: 0 0 3.33333%;
    max-width: 3.33333%; }
  .col-lg-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-lg-4 {
    flex: 0 0 6.66667%;
    max-width: 6.66667%; }
  .col-lg-5 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-lg-7 {
    flex: 0 0 11.66667%;
    max-width: 11.66667%; }
  .col-lg-8 {
    flex: 0 0 13.33333%;
    max-width: 13.33333%; }
  .col-lg-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-lg-10 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-11 {
    flex: 0 0 18.33333%;
    max-width: 18.33333%; }
  .col-lg-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-lg-13 {
    flex: 0 0 21.66667%;
    max-width: 21.66667%; }
  .col-lg-14 {
    flex: 0 0 23.33333%;
    max-width: 23.33333%; }
  .col-lg-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-16 {
    flex: 0 0 26.66667%;
    max-width: 26.66667%; }
  .col-lg-17 {
    flex: 0 0 28.33333%;
    max-width: 28.33333%; }
  .col-lg-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-lg-19 {
    flex: 0 0 31.66667%;
    max-width: 31.66667%; }
  .col-lg-20 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-lg-22 {
    flex: 0 0 36.66667%;
    max-width: 36.66667%; }
  .col-lg-23 {
    flex: 0 0 38.33333%;
    max-width: 38.33333%; }
  .col-lg-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-lg-25 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-26 {
    flex: 0 0 43.33333%;
    max-width: 43.33333%; }
  .col-lg-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-lg-28 {
    flex: 0 0 46.66667%;
    max-width: 46.66667%; }
  .col-lg-29 {
    flex: 0 0 48.33333%;
    max-width: 48.33333%; }
  .col-lg-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-31 {
    flex: 0 0 51.66667%;
    max-width: 51.66667%; }
  .col-lg-32 {
    flex: 0 0 53.33333%;
    max-width: 53.33333%; }
  .col-lg-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-lg-34 {
    flex: 0 0 56.66667%;
    max-width: 56.66667%; }
  .col-lg-35 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-lg-37 {
    flex: 0 0 61.66667%;
    max-width: 61.66667%; }
  .col-lg-38 {
    flex: 0 0 63.33333%;
    max-width: 63.33333%; }
  .col-lg-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-lg-40 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-41 {
    flex: 0 0 68.33333%;
    max-width: 68.33333%; }
  .col-lg-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-lg-43 {
    flex: 0 0 71.66667%;
    max-width: 71.66667%; }
  .col-lg-44 {
    flex: 0 0 73.33333%;
    max-width: 73.33333%; }
  .col-lg-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-46 {
    flex: 0 0 76.66667%;
    max-width: 76.66667%; }
  .col-lg-47 {
    flex: 0 0 78.33333%;
    max-width: 78.33333%; }
  .col-lg-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-lg-49 {
    flex: 0 0 81.66667%;
    max-width: 81.66667%; }
  .col-lg-50 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-lg-52 {
    flex: 0 0 86.66667%;
    max-width: 86.66667%; }
  .col-lg-53 {
    flex: 0 0 88.33333%;
    max-width: 88.33333%; }
  .col-lg-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-lg-55 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-56 {
    flex: 0 0 93.33333%;
    max-width: 93.33333%; }
  .col-lg-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-lg-58 {
    flex: 0 0 96.66667%;
    max-width: 96.66667%; }
  .col-lg-59 {
    flex: 0 0 98.33333%;
    max-width: 98.33333%; }
  .col-lg-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 61; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .order-lg-25 {
    order: 25; }
  .order-lg-26 {
    order: 26; }
  .order-lg-27 {
    order: 27; }
  .order-lg-28 {
    order: 28; }
  .order-lg-29 {
    order: 29; }
  .order-lg-30 {
    order: 30; }
  .order-lg-31 {
    order: 31; }
  .order-lg-32 {
    order: 32; }
  .order-lg-33 {
    order: 33; }
  .order-lg-34 {
    order: 34; }
  .order-lg-35 {
    order: 35; }
  .order-lg-36 {
    order: 36; }
  .order-lg-37 {
    order: 37; }
  .order-lg-38 {
    order: 38; }
  .order-lg-39 {
    order: 39; }
  .order-lg-40 {
    order: 40; }
  .order-lg-41 {
    order: 41; }
  .order-lg-42 {
    order: 42; }
  .order-lg-43 {
    order: 43; }
  .order-lg-44 {
    order: 44; }
  .order-lg-45 {
    order: 45; }
  .order-lg-46 {
    order: 46; }
  .order-lg-47 {
    order: 47; }
  .order-lg-48 {
    order: 48; }
  .order-lg-49 {
    order: 49; }
  .order-lg-50 {
    order: 50; }
  .order-lg-51 {
    order: 51; }
  .order-lg-52 {
    order: 52; }
  .order-lg-53 {
    order: 53; }
  .order-lg-54 {
    order: 54; }
  .order-lg-55 {
    order: 55; }
  .order-lg-56 {
    order: 56; }
  .order-lg-57 {
    order: 57; }
  .order-lg-58 {
    order: 58; }
  .order-lg-59 {
    order: 59; }
  .order-lg-60 {
    order: 60; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 1.66667%; }
  .offset-lg-2 {
    margin-left: 3.33333%; }
  .offset-lg-3 {
    margin-left: 5%; }
  .offset-lg-4 {
    margin-left: 6.66667%; }
  .offset-lg-5 {
    margin-left: 8.33333%; }
  .offset-lg-6 {
    margin-left: 10%; }
  .offset-lg-7 {
    margin-left: 11.66667%; }
  .offset-lg-8 {
    margin-left: 13.33333%; }
  .offset-lg-9 {
    margin-left: 15%; }
  .offset-lg-10 {
    margin-left: 16.66667%; }
  .offset-lg-11 {
    margin-left: 18.33333%; }
  .offset-lg-12 {
    margin-left: 20%; }
  .offset-lg-13 {
    margin-left: 21.66667%; }
  .offset-lg-14 {
    margin-left: 23.33333%; }
  .offset-lg-15 {
    margin-left: 25%; }
  .offset-lg-16 {
    margin-left: 26.66667%; }
  .offset-lg-17 {
    margin-left: 28.33333%; }
  .offset-lg-18 {
    margin-left: 30%; }
  .offset-lg-19 {
    margin-left: 31.66667%; }
  .offset-lg-20 {
    margin-left: 33.33333%; }
  .offset-lg-21 {
    margin-left: 35%; }
  .offset-lg-22 {
    margin-left: 36.66667%; }
  .offset-lg-23 {
    margin-left: 38.33333%; }
  .offset-lg-24 {
    margin-left: 40%; }
  .offset-lg-25 {
    margin-left: 41.66667%; }
  .offset-lg-26 {
    margin-left: 43.33333%; }
  .offset-lg-27 {
    margin-left: 45%; }
  .offset-lg-28 {
    margin-left: 46.66667%; }
  .offset-lg-29 {
    margin-left: 48.33333%; }
  .offset-lg-30 {
    margin-left: 50%; }
  .offset-lg-31 {
    margin-left: 51.66667%; }
  .offset-lg-32 {
    margin-left: 53.33333%; }
  .offset-lg-33 {
    margin-left: 55%; }
  .offset-lg-34 {
    margin-left: 56.66667%; }
  .offset-lg-35 {
    margin-left: 58.33333%; }
  .offset-lg-36 {
    margin-left: 60%; }
  .offset-lg-37 {
    margin-left: 61.66667%; }
  .offset-lg-38 {
    margin-left: 63.33333%; }
  .offset-lg-39 {
    margin-left: 65%; }
  .offset-lg-40 {
    margin-left: 66.66667%; }
  .offset-lg-41 {
    margin-left: 68.33333%; }
  .offset-lg-42 {
    margin-left: 70%; }
  .offset-lg-43 {
    margin-left: 71.66667%; }
  .offset-lg-44 {
    margin-left: 73.33333%; }
  .offset-lg-45 {
    margin-left: 75%; }
  .offset-lg-46 {
    margin-left: 76.66667%; }
  .offset-lg-47 {
    margin-left: 78.33333%; }
  .offset-lg-48 {
    margin-left: 80%; }
  .offset-lg-49 {
    margin-left: 81.66667%; }
  .offset-lg-50 {
    margin-left: 83.33333%; }
  .offset-lg-51 {
    margin-left: 85%; }
  .offset-lg-52 {
    margin-left: 86.66667%; }
  .offset-lg-53 {
    margin-left: 88.33333%; }
  .offset-lg-54 {
    margin-left: 90%; }
  .offset-lg-55 {
    margin-left: 91.66667%; }
  .offset-lg-56 {
    margin-left: 93.33333%; }
  .offset-lg-57 {
    margin-left: 95%; }
  .offset-lg-58 {
    margin-left: 96.66667%; }
  .offset-lg-59 {
    margin-left: 98.33333%; } }

@media (min-width: 1799px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 1.66667%;
    max-width: 1.66667%; }
  .col-xl-2 {
    flex: 0 0 3.33333%;
    max-width: 3.33333%; }
  .col-xl-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-xl-4 {
    flex: 0 0 6.66667%;
    max-width: 6.66667%; }
  .col-xl-5 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-xl-7 {
    flex: 0 0 11.66667%;
    max-width: 11.66667%; }
  .col-xl-8 {
    flex: 0 0 13.33333%;
    max-width: 13.33333%; }
  .col-xl-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-xl-10 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-11 {
    flex: 0 0 18.33333%;
    max-width: 18.33333%; }
  .col-xl-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-xl-13 {
    flex: 0 0 21.66667%;
    max-width: 21.66667%; }
  .col-xl-14 {
    flex: 0 0 23.33333%;
    max-width: 23.33333%; }
  .col-xl-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-16 {
    flex: 0 0 26.66667%;
    max-width: 26.66667%; }
  .col-xl-17 {
    flex: 0 0 28.33333%;
    max-width: 28.33333%; }
  .col-xl-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-xl-19 {
    flex: 0 0 31.66667%;
    max-width: 31.66667%; }
  .col-xl-20 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-xl-22 {
    flex: 0 0 36.66667%;
    max-width: 36.66667%; }
  .col-xl-23 {
    flex: 0 0 38.33333%;
    max-width: 38.33333%; }
  .col-xl-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-xl-25 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-26 {
    flex: 0 0 43.33333%;
    max-width: 43.33333%; }
  .col-xl-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-xl-28 {
    flex: 0 0 46.66667%;
    max-width: 46.66667%; }
  .col-xl-29 {
    flex: 0 0 48.33333%;
    max-width: 48.33333%; }
  .col-xl-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-31 {
    flex: 0 0 51.66667%;
    max-width: 51.66667%; }
  .col-xl-32 {
    flex: 0 0 53.33333%;
    max-width: 53.33333%; }
  .col-xl-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-xl-34 {
    flex: 0 0 56.66667%;
    max-width: 56.66667%; }
  .col-xl-35 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-xl-37 {
    flex: 0 0 61.66667%;
    max-width: 61.66667%; }
  .col-xl-38 {
    flex: 0 0 63.33333%;
    max-width: 63.33333%; }
  .col-xl-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-xl-40 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-41 {
    flex: 0 0 68.33333%;
    max-width: 68.33333%; }
  .col-xl-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-xl-43 {
    flex: 0 0 71.66667%;
    max-width: 71.66667%; }
  .col-xl-44 {
    flex: 0 0 73.33333%;
    max-width: 73.33333%; }
  .col-xl-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-46 {
    flex: 0 0 76.66667%;
    max-width: 76.66667%; }
  .col-xl-47 {
    flex: 0 0 78.33333%;
    max-width: 78.33333%; }
  .col-xl-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-xl-49 {
    flex: 0 0 81.66667%;
    max-width: 81.66667%; }
  .col-xl-50 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-xl-52 {
    flex: 0 0 86.66667%;
    max-width: 86.66667%; }
  .col-xl-53 {
    flex: 0 0 88.33333%;
    max-width: 88.33333%; }
  .col-xl-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-xl-55 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-56 {
    flex: 0 0 93.33333%;
    max-width: 93.33333%; }
  .col-xl-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-xl-58 {
    flex: 0 0 96.66667%;
    max-width: 96.66667%; }
  .col-xl-59 {
    flex: 0 0 98.33333%;
    max-width: 98.33333%; }
  .col-xl-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 61; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .order-xl-25 {
    order: 25; }
  .order-xl-26 {
    order: 26; }
  .order-xl-27 {
    order: 27; }
  .order-xl-28 {
    order: 28; }
  .order-xl-29 {
    order: 29; }
  .order-xl-30 {
    order: 30; }
  .order-xl-31 {
    order: 31; }
  .order-xl-32 {
    order: 32; }
  .order-xl-33 {
    order: 33; }
  .order-xl-34 {
    order: 34; }
  .order-xl-35 {
    order: 35; }
  .order-xl-36 {
    order: 36; }
  .order-xl-37 {
    order: 37; }
  .order-xl-38 {
    order: 38; }
  .order-xl-39 {
    order: 39; }
  .order-xl-40 {
    order: 40; }
  .order-xl-41 {
    order: 41; }
  .order-xl-42 {
    order: 42; }
  .order-xl-43 {
    order: 43; }
  .order-xl-44 {
    order: 44; }
  .order-xl-45 {
    order: 45; }
  .order-xl-46 {
    order: 46; }
  .order-xl-47 {
    order: 47; }
  .order-xl-48 {
    order: 48; }
  .order-xl-49 {
    order: 49; }
  .order-xl-50 {
    order: 50; }
  .order-xl-51 {
    order: 51; }
  .order-xl-52 {
    order: 52; }
  .order-xl-53 {
    order: 53; }
  .order-xl-54 {
    order: 54; }
  .order-xl-55 {
    order: 55; }
  .order-xl-56 {
    order: 56; }
  .order-xl-57 {
    order: 57; }
  .order-xl-58 {
    order: 58; }
  .order-xl-59 {
    order: 59; }
  .order-xl-60 {
    order: 60; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 1.66667%; }
  .offset-xl-2 {
    margin-left: 3.33333%; }
  .offset-xl-3 {
    margin-left: 5%; }
  .offset-xl-4 {
    margin-left: 6.66667%; }
  .offset-xl-5 {
    margin-left: 8.33333%; }
  .offset-xl-6 {
    margin-left: 10%; }
  .offset-xl-7 {
    margin-left: 11.66667%; }
  .offset-xl-8 {
    margin-left: 13.33333%; }
  .offset-xl-9 {
    margin-left: 15%; }
  .offset-xl-10 {
    margin-left: 16.66667%; }
  .offset-xl-11 {
    margin-left: 18.33333%; }
  .offset-xl-12 {
    margin-left: 20%; }
  .offset-xl-13 {
    margin-left: 21.66667%; }
  .offset-xl-14 {
    margin-left: 23.33333%; }
  .offset-xl-15 {
    margin-left: 25%; }
  .offset-xl-16 {
    margin-left: 26.66667%; }
  .offset-xl-17 {
    margin-left: 28.33333%; }
  .offset-xl-18 {
    margin-left: 30%; }
  .offset-xl-19 {
    margin-left: 31.66667%; }
  .offset-xl-20 {
    margin-left: 33.33333%; }
  .offset-xl-21 {
    margin-left: 35%; }
  .offset-xl-22 {
    margin-left: 36.66667%; }
  .offset-xl-23 {
    margin-left: 38.33333%; }
  .offset-xl-24 {
    margin-left: 40%; }
  .offset-xl-25 {
    margin-left: 41.66667%; }
  .offset-xl-26 {
    margin-left: 43.33333%; }
  .offset-xl-27 {
    margin-left: 45%; }
  .offset-xl-28 {
    margin-left: 46.66667%; }
  .offset-xl-29 {
    margin-left: 48.33333%; }
  .offset-xl-30 {
    margin-left: 50%; }
  .offset-xl-31 {
    margin-left: 51.66667%; }
  .offset-xl-32 {
    margin-left: 53.33333%; }
  .offset-xl-33 {
    margin-left: 55%; }
  .offset-xl-34 {
    margin-left: 56.66667%; }
  .offset-xl-35 {
    margin-left: 58.33333%; }
  .offset-xl-36 {
    margin-left: 60%; }
  .offset-xl-37 {
    margin-left: 61.66667%; }
  .offset-xl-38 {
    margin-left: 63.33333%; }
  .offset-xl-39 {
    margin-left: 65%; }
  .offset-xl-40 {
    margin-left: 66.66667%; }
  .offset-xl-41 {
    margin-left: 68.33333%; }
  .offset-xl-42 {
    margin-left: 70%; }
  .offset-xl-43 {
    margin-left: 71.66667%; }
  .offset-xl-44 {
    margin-left: 73.33333%; }
  .offset-xl-45 {
    margin-left: 75%; }
  .offset-xl-46 {
    margin-left: 76.66667%; }
  .offset-xl-47 {
    margin-left: 78.33333%; }
  .offset-xl-48 {
    margin-left: 80%; }
  .offset-xl-49 {
    margin-left: 81.66667%; }
  .offset-xl-50 {
    margin-left: 83.33333%; }
  .offset-xl-51 {
    margin-left: 85%; }
  .offset-xl-52 {
    margin-left: 86.66667%; }
  .offset-xl-53 {
    margin-left: 88.33333%; }
  .offset-xl-54 {
    margin-left: 90%; }
  .offset-xl-55 {
    margin-left: 91.66667%; }
  .offset-xl-56 {
    margin-left: 93.33333%; }
  .offset-xl-57 {
    margin-left: 95%; }
  .offset-xl-58 {
    margin-left: 96.66667%; }
  .offset-xl-59 {
    margin-left: 98.33333%; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 38px;
  color: #1a1a1b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dadada;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #1a1a1b;
    background-color: #fff;
    border-color: #1a1a1b;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #dadada;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #1a1a1b;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0 + 1px);
  padding-bottom: calc(0 + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 38px; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: 38px;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 10px; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #54575c; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #008c58; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 140, 88, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #008c58; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #008c58;
    box-shadow: 0 0 0 0.2rem rgba(0, 140, 88, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #008c58; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #008c58; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #0dffa5; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #00bf78; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 140, 88, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #008c58; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 140, 88, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 709px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0 solid transparent;
  padding: 0 15px;
  font-size: 1rem;
  line-height: 40px;
  border-radius: 0;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 1; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #54575c;
  border-color: #54575c; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #424448;
    border-color: #3c3e41; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(84, 87, 92, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #54575c;
    border-color: #54575c; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3c3e41;
    border-color: #36373b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(84, 87, 92, 0.5); }

.btn-success {
  color: #fff;
  background-color: #008c58;
  border-color: #008c58; }
  .btn-success:hover {
    color: #fff;
    background-color: #006640;
    border-color: #005938; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(0, 140, 88, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #008c58;
    border-color: #008c58; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #005938;
    border-color: #004c30; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 140, 88, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ec1124;
  border-color: #ec1124; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c80e1f;
    border-color: #bc0e1d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(236, 17, 36, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ec1124;
    border-color: #ec1124; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bc0e1d;
    border-color: #b10d1b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(236, 17, 36, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f7f7f7;
  border-color: #f7f7f7; }
  .btn-light:hover {
    color: #212529;
    background-color: #e4e4e4;
    border-color: #dedede; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(247, 247, 247, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dedede;
    border-color: #d7d7d7; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(247, 247, 247, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #54575c;
  background-color: transparent;
  background-image: none;
  border-color: #54575c; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #54575c;
    border-color: #54575c; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(84, 87, 92, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #54575c;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #54575c;
    border-color: #54575c; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(84, 87, 92, 0.5); }

.btn-outline-success {
  color: #008c58;
  background-color: transparent;
  background-image: none;
  border-color: #008c58; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #008c58;
    border-color: #008c58; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(0, 140, 88, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #008c58;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #008c58;
    border-color: #008c58; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 140, 88, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #ec1124;
  background-color: transparent;
  background-image: none;
  border-color: #ec1124; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ec1124;
    border-color: #ec1124; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(236, 17, 36, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ec1124;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ec1124;
    border-color: #ec1124; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(236, 17, 36, 0.5); }

.btn-outline-light {
  color: #f7f7f7;
  background-color: transparent;
  background-image: none;
  border-color: #f7f7f7; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(247, 247, 247, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f7f7f7;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(247, 247, 247, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #54575c; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 10px; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1a1a1b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f7f7f7; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #54575c;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #54575c;
  white-space: nowrap; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 38px;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #54575c; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.1rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.1rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0 35px 0 15px;
  line-height: 38px;
  color: #1a1a1b;
  vertical-align: middle;
  background: transparent none no-repeat right 15px center;
  background-size: 8px 10px;
  border: 1px solid #dadada;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #1a1a1b;
    outline: 0;
    box-shadow: none; }
    .custom-select:focus::-ms-value {
      color: #1a1a1b;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 15px;
    background-image: none; }
  .custom-select:disabled {
    color: #dadada;
    background-color: #f7f7f7; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #54575c; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #54575c;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 20px; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 20px; }

.card-headline {
  font-size: 126%;
  font-weight: 600;
  line-height: 1.6;
  margin-top: -8px; }

.card-header {
  padding: 0.75rem 20px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -10px;
  margin-bottom: -0.75rem;
  margin-left: -10px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -10px;
  margin-left: -10px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 709px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 709px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 709px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #1a1a1b;
  content: ">"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #1a1a1b; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #54575c; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3c3e41; }

.badge-success {
  color: #fff;
  background-color: #008c58; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #005938; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #ec1124; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bc0e1d; }

.badge-light {
  color: #212529;
  background-color: #f7f7f7; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dedede; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 709px) {
  .modal-dialog {
    max-width: calc(100% - 40px);
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1219px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Helvetica Neue LT", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #54575c !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3c3e41 !important; }

.bg-success {
  background-color: #008c58 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #005938 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #ec1124 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bc0e1d !important; }

.bg-light {
  background-color: #f7f7f7 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dedede !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #54575c !important; }

.border-success {
  border-color: #008c58 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #ec1124 !important; }

.border-light {
  border-color: #f7f7f7 !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 709px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 979px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1219px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1799px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 709px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 979px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1219px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1799px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 709px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 979px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1219px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1799px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 709px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 979px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1219px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1799px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 709px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 979px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1219px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1799px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #54575c !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #3c3e41 !important; }

.text-success {
  color: #008c58 !important; }

a.text-success:hover, a.text-success:focus {
  color: #005938 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #ec1124 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bc0e1d !important; }

.text-light {
  color: #f7f7f7 !important; }

a.text-light:hover, a.text-light:focus {
  color: #dedede !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #54575c !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

.custom-control-input:not(:checked) ~ .custom-control-label:hover:after {
  opacity: .1; }

.card .custom-control-label {
  padding-top: .4em; }

.custom-radio,
.custom-checkbox {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 0; }
  .custom-radio .custom-control-label,
  .custom-checkbox .custom-control-label {
    font-weight: 500;
    color: #54575c;
    cursor: pointer;
    padding-left: 40px;
    line-height: 26px; }
    .custom-radio .custom-control-label:before,
    .custom-checkbox .custom-control-label:before {
      width: 26px;
      height: 26px;
      background: #e8e8e8;
      top: 7px;
      z-index: 1; }
    .custom-radio .custom-control-label:after,
    .custom-checkbox .custom-control-label:after {
      width: 12px;
      height: 12px;
      top: 50%;
      transform: translateY(-50%);
      left: 7px;
      z-index: 2;
      background: #e8e8e8;
      background-image: none !important;
      border-radius: 50%;
      opacity: 0;
      transition: opacity .3s; }
  .custom-radio .custom-control-input:checked + .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked + .custom-control-label:before {
    background: #e8e8e8; }
  .custom-radio .custom-control-input:checked + .custom-control-label:after,
  .custom-checkbox .custom-control-input:checked + .custom-control-label:after {
    opacity: 1; }
  .custom-radio .custom-control-input:active ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:active ~ .custom-control-label:before {
    background-color: #e8e8e8; }
  .custom-radio .custom-control-input:focus + .custom-control-label:before,
  .custom-checkbox .custom-control-input:focus + .custom-control-label:before {
    outline: none;
    box-shadow: none; }
  .custom-radio .custom-control-input:disabled + .custom-control-label,
  .custom-checkbox .custom-control-input:disabled + .custom-control-label {
    color: #e8e8e8;
    cursor: not-allowed; }
    .custom-radio .custom-control-input:disabled + .custom-control-label:before,
    .custom-checkbox .custom-control-input:disabled + .custom-control-label:before {
      background: #e8e8e8; }

.custom-radio .custom-control-input:checked + .custom-control-label:after {
  opacity: 1;
  background: #54575c;
  transform: none;
  top: 14px; }

.custom-checkbox .custom-control-input:indeterminate + .custom-control-label:before {
  background: #e8e8e8; }

.custom-checkbox .custom-control-input:indeterminate:disabled + .custom-control-label:before {
  background: #e8e8e8; }

.custom-checkbox .custom-control-label:before {
  border-radius: 0; }

.custom-checkbox .custom-control-label:after {
  width: auto;
  height: auto;
  top: 17%;
  left: 6px;
  background-image: none !important;
  background-color: transparent;
  content: "\e916";
  font-family: 'icomoon' !important;
  font-weight: bold;
  transform: scale(1.9); }

.form-control input {
  font-size: 14px;
  transition: border-top-color .3s; }

.custom-select {
  font-weight: 500;
  position: relative;
  z-index: 2; }
  .custom-select option {
    font-weight: 500; }
  .custom-select:disabled {
    z-index: 0;
    cursor: not-allowed; }
    .custom-select:disabled ~ i {
      color: #dadada; }

.select-wrap {
  background: #fff;
  position: relative;
  z-index: 0; }
  .select-wrap i {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%) scale(2);
    color: #1a1a1b;
    font-size: 20px;
    right: 25px; }

.form-control {
  color: #6f6f6f;
  min-height: 40px; }
  .form-control:focus {
    color: #1a1a1b; }

select.form-control:hover {
  color: #1a1a1b; }

.flash-notice {
  border: none;
  padding: 15px;
  background: #008c58;
  color: #fff;
  margin-bottom: 30px; }
  .flash-notice p {
    margin: 0; }

.flash-error {
  border: none;
  padding: 15px;
  background: #ff0000;
  color: #fff;
  margin-bottom: 30px; }
  .flash-error p {
    margin: 0; }

.lang-es .custom-radio .custom-control-label:after {
  top: 0;
  transform: translateY(120%); }

.btn {
  text-align: left;
  font-weight: 500;
  white-space: normal;
  line-height: 1.6; }
  .btn span {
    line-height: 22px; }
  .btn:disabled {
    background: #fff;
    color: #e8e8e8;
    cursor: not-allowed; }
  .btn i {
    margin-right: 15px;
    line-height: 40px; }
  .btn.reduced {
    line-height: 30px; }
    .btn.reduced i {
      line-height: 30px; }
    .btn.reduced:hover, .btn.reduced:active {
      background: #fff !important;
      color: #54575c !important; }
  .btn.bordered {
    line-height: 38px; }
    .btn.bordered i {
      line-height: 38px; }
  .btn.flex-row-reverse i {
    margin-right: 0;
    margin-left: 15px;
    width: 16px; }
  .btn.btn-info:not(:disabled) {
    background: #fff;
    color: #54575c; }
    .btn.btn-info:not(:disabled):hover, .btn.btn-info:not(:disabled):active {
      background: #54575c;
      color: #fff; }
  .btn.btn-default:not(:disabled) {
    color: #fff;
    background: #54575c; }
    .btn.btn-default:not(:disabled):hover, .btn.btn-default:not(:disabled):focus, .btn.btn-default:not(:disabled):active, .btn.btn-default:not(:disabled).active {
      color: #54575c;
      background: #fff; }
  .btn.btn-success:not(:disabled) {
    background: #fff;
    color: #008c58; }
    .btn.btn-success:not(:disabled):hover, .btn.btn-success:not(:disabled):focus, .btn.btn-success:not(:disabled):active {
      background: #008c58;
      color: #fff; }
  .btn.btn-icon-right {
    padding-top: 9px;
    padding-bottom: 9px; }
    .btn.btn-icon-right i {
      margin-left: 15px;
      margin-right: 0; }

.btn-custom.btn:not(:disabled):not(.disabled) {
  background: none;
  color: #ffffff;
  padding: 9px 15px; }

.btn-custom.btn:not(:disabled):not(.disabled):hover {
  background: #ffffff;
  color: #54575c; }

.tip {
  position: absolute;
  bottom: 80px;
  right: 20px;
  padding: 5px;
  z-index: 9;
  overflow: hidden;
  margin-left: 20px; }
  .tip i {
    transform: translateY(-50%);
    transition: transform .3s linear .6s;
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 20px; }
  .tip .tip-content {
    color: #fff;
    padding: 10px 40px 10px 10px;
    background: transparent;
    max-height: 40px;
    width: 40px;
    position: relative;
    transition: max-height .3s linear .6s, width .3s linear .6s; }
    .tip .tip-content p {
      margin-bottom: 0; }
    .tip .tip-content:before {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      z-index: -1;
      bottom: 50px;
      transform: translateX(-40px);
      background: transparent;
      transition: background-color .3s linear .3s, transform .3s linear .3s; }
      @media (min-width: 1220px) {
        .tip .tip-content:before {
          bottom: 0; } }
    .tip .tip-content:after {
      content: "";
      height: 150px;
      max-height: 1px;
      width: 100%;
      display: block;
      transition: max-height .3s linear .6s; }
      @media (min-width: 1220px) {
        .tip .tip-content:after {
          display: none; } }
    .tip .tip-content > p {
      opacity: 0;
      transition: opacity .3s linear 0s; }
  .tip:hover, .tip:focus {
    outline: none; }
    .tip:hover i, .tip:focus i {
      transform: translateY(calc(-50% - 25px));
      transition-delay: 0s; }
      @media (min-width: 1220px) {
        .tip:hover i, .tip:focus i {
          transform: translateY(-50%); } }
    .tip:hover .tip-content, .tip:focus .tip-content {
      visibility: visible;
      width: 100%;
      max-height: 350px;
      transition-delay: 0s;
      padding-right: 60px; }
      @media (min-width: 710px) {
        .tip:hover .tip-content, .tip:focus .tip-content {
          width: 530px; } }
      @media (min-width: 1220px) {
        .tip:hover .tip-content, .tip:focus .tip-content {
          width: 530px; } }
      @media (min-width: 1800px) {
        .tip:hover .tip-content, .tip:focus .tip-content {
          width: 700px; } }
      .tip:hover .tip-content:before, .tip:focus .tip-content:before {
        background-color: #54575c;
        transform: translateX(-100%);
        transition-delay: .3s; }
      .tip:hover .tip-content:after, .tip:focus .tip-content:after {
        max-height: 50px;
        transition-delay: 0s; }
      .tip:hover .tip-content > p, .tip:focus .tip-content > p {
        opacity: 1;
        transition-delay: .6s; }

body {
  min-width: 320px;
  font-size: 14px;
  overflow: hidden; }
  @media (min-width: 710px) {
    body {
      font-size: 15px; } }
  @media (min-width: 1220px) {
    body {
      font-size: 16px; } }
  body.initialStatePassed {
    overflow: auto; }
    body.initialStatePassed.modal-open {
      overflow: hidden; }
  body.initialStatePassing {
    overflow: auto; }

p {
  line-height: 1.6; }

a {
  color: #1a1a1b;
  transition: color .3s; }
  a:hover, a:focus {
    color: #008c58; }

.lead {
  font-family: Serif;
  font-style: italic;
  letter-spacing: 0.5px; }

.med {
  font-weight: 600; }

ol, ul {
  padding-left: 20px; }
  ol.default-list li, ul.default-list li {
    margin-bottom: 5px;
    line-height: 1.6; }

dt {
  font-weight: normal;
  margin-right: 5px; }

#wrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden; }

@media (max-width: 708px) {
  .container {
    padding-right: 20px;
    padding-left: 20px; } }

.top-banner h1 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px; }
  @media (min-width: 710px) {
    .top-banner h1 {
      font-size: 50px;
      line-height: 60px; } }
  @media (min-width: 1220px) {
    .top-banner h1 {
      font-size: 80px;
      line-height: 90px; } }
  @media (min-width: 1800px) {
    .top-banner h1 {
      font-size: 120px;
      line-height: 130px; } }

.top-banner h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px; }
  @media (min-width: 710px) {
    .top-banner h3 {
      font-size: 20px;
      line-height: 32px; } }
  @media (min-width: 1220px) {
    .top-banner h3 {
      font-size: 26px;
      line-height: 42px; } }
  @media (min-width: 1800px) {
    .top-banner h3 {
      font-size: 30px;
      line-height: 48px; } }

h2 {
  line-height: 27px;
  font-weight: 300; }
  @media (min-width: 710px) {
    h2 {
      font-size: 45px;
      line-height: 54px; } }
  @media (min-width: 1220px) {
    h2 {
      font-size: 50px;
      line-height: 60px; } }
  @media (min-width: 1800px) {
    h2 {
      font-size: 70px;
      line-height: 84px; } }
  h2 small {
    font-weight: 300; }
    @media (min-width: 980px) {
      h2 small {
        font-size: 100%; } }
  h2 b {
    font-weight: 700; }
    h2 b small {
      font-weight: 700;
      display: inline-block;
      vertical-align: top;
      line-height: 1.8;
      font-size: 50%; }

h1, h3 {
  line-height: 35px;
  font-weight: 300;
  color: #1a1a1b; }
  @media (min-width: 710px) {
    h1, h3 {
      font-size: 30px;
      line-height: 48px; } }
  @media (min-width: 1220px) {
    h1, h3 {
      font-size: 36px;
      line-height: 60px; } }

h4 {
  font-size: 125%;
  font-weight: 600;
  line-height: 1.6; }

.title-holder {
  margin-bottom: 20px; }

img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%; }

.full-height {
  min-height: 100%; }

.bg-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .bg-holder picture {
    display: none; }

.common-section {
  padding: 30px 0; }
  .common-section .img-holder {
    margin-bottom: 15px;
    position: relative; }
    @media (min-width: 710px) {
      .common-section .img-holder {
        margin-bottom: 30px; } }
    .common-section .img-holder img {
      width: 100%; }

.no-transition * {
  transition: none !important; }

.no-transition header:before, .no-transition header:after {
  transition: none !important; }

.go-to-content {
  color: #fff;
  font-size: 60px;
  transition: color .3s;
  line-height: 1;
  margin: 30px 0;
  display: inline-block; }
  @media (min-width: 1220px) {
    .go-to-content {
      font-size: 80px; } }
  @media (min-width: 1800px) {
    .go-to-content {
      font-size: 100px; } }
  .go-to-content:hover, .go-to-content:focus {
    color: #008c58; }

.block-inverse {
  color: #fff; }
  .block-inverse h1,
  .block-inverse h2,
  .block-inverse h3,
  .block-inverse h4,
  .block-inverse h5,
  .block-inverse h6 {
    color: #fff; }
  .block-inverse .breadcrumb-item {
    font-size: 12px; }
    @media (min-width: 710px) {
      .block-inverse .breadcrumb-item {
        font-size: 16px; } }
    .block-inverse .breadcrumb-item a {
      color: #fff;
      transition: color 0.3s; }
      .block-inverse .breadcrumb-item a:hover, .block-inverse .breadcrumb-item a:focus {
        color: #008c58; }
    .block-inverse .breadcrumb-item.active {
      color: #fff; }
    .block-inverse .breadcrumb-item:before {
      color: #fff; }

.block-inverse.get-image {
  background-attachment: fixed;
  background-size: cover; }
  .block-inverse.get-image p {
    letter-spacing: 0.5px; }
  .block-inverse.get-image .med {
    letter-spacing: 1px; }

.block-inverse.get-image.content-wrap {
  background-attachment: local; }

.breadcrumb {
  margin-bottom: 0; }

.breadcrumb-item a, .breadcrumb-item:before {
  color: inherit; }

.breadcrumb-item:not(.active):hover {
  text-decoration: underline; }

.content-wrap {
  padding: 65px 0 15px;
  background-repeat: repeat-y;
  background-size: 100%;
  background-position: center top;
  background-size: cover; }
  @media (min-width: 710px) {
    .content-wrap {
      padding: 80px 0 20px; } }
  @media (min-width: 1220px) {
    .content-wrap {
      padding: 115px 0 25px; } }
  .content-wrap.bg-no-repeat {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .content-wrap .tip {
    bottom: 0;
    right: 0;
    display: none; }
    @media (min-width: 710px) {
      .content-wrap .tip {
        display: block; } }
    .content-wrap .tip .tip-content:after {
      display: none; }
    .content-wrap .tip .tip-content:before {
      bottom: 0; }
    .content-wrap .tip:hover i, .content-wrap .tip:focus i {
      transform: translateY(-50%); }
  .content-wrap.extended {
    padding: 80px 0 15px; }
    @media (min-width: 710px) {
      .content-wrap.extended {
        padding: 115px 0 40px; } }
    @media (min-width: 1220px) {
      .content-wrap.extended {
        padding: 140px 0 35px; } }

.content-wrap-project {
  background-color: #54575c;
  color: #ffffff; }

.layout-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%; }
  @media (min-width: 980px) {
    .layout-wrap {
      flex-direction: row;
      align-items: flex-start; } }
  .layout-wrap .layout-wrap {
    flex-wrap: wrap; }
    @media (min-width: 1220px) {
      .layout-wrap .layout-wrap {
        flex-wrap: nowrap; } }
    .layout-wrap .layout-wrap .page-content .row {
      display: flex; }
      @media (min-width: 710px) {
        .layout-wrap .layout-wrap .page-content .row {
          display: block; } }
  .layout-wrap .tab-content .layout-wrap {
    flex-wrap: nowrap; }

.page-content {
  flex-grow: 1;
  overflow: hidden; }
  .page-content .project-teaser-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .page-content .project-teaser-wrapper .cmf-block {
      float: left;
      width: 100%;
      padding: 15px; }
      @media (min-width: 710px) {
        .page-content .project-teaser-wrapper .cmf-block {
          width: 50%; } }
      .page-content .project-teaser-wrapper .cmf-block:first-child {
        width: 100%; }

.block-inverse hr {
  border-top-color: #fff; }

.img-quote {
  margin: 0;
  color: #000; }
  .img-quote q:before, .img-quote q:after {
    display: none; }
  .img-quote cite {
    color: #1a1a1b;
    font-style: normal; }

.first-letter-keratwin:first-letter {
  float: left;
  font-size: 475%;
  color: #5f8890;
  line-height: 1;
  margin-right: 5px; }
  @media (min-width: 1220px) {
    .first-letter-keratwin:first-letter {
      font-size: 470%; } }

.first-letter-kerashape:first-letter {
  float: left;
  font-size: 475%;
  color: #83afc3;
  line-height: 1;
  margin-right: 5px; }
  @media (min-width: 1220px) {
    .first-letter-kerashape:first-letter {
      font-size: 470%; } }

.first-letter-keraion:first-letter {
  float: left;
  font-size: 475%;
  color: #6b7983;
  line-height: 1;
  margin-right: 5px; }
  @media (min-width: 1220px) {
    .first-letter-keraion:first-letter {
      font-size: 470%; } }

.first-letter-kerajoin:first-letter {
  float: left;
  font-size: 475%;
  color: #668997;
  line-height: 1;
  margin-right: 5px; }
  @media (min-width: 1220px) {
    .first-letter-kerajoin:first-letter {
      font-size: 470%; } }

.first-letter-kerayou:first-letter {
  float: left;
  font-size: 475%;
  color: #5b8083;
  line-height: 1;
  margin-right: 5px; }
  @media (min-width: 1220px) {
    .first-letter-kerayou:first-letter {
      font-size: 470%; } }

.new-item {
  padding: 0 18px;
  margin-right: 15px;
  border: 1px solid #ec1124;
  transform: translateY(-20%); }

@media (min-width: 1800px) {
  .xl-covered-gray-100 {
    background: #f7f7f7; } }

@media (min-width: 710px) {
  .sm-img-cover {
    overflow: hidden;
    position: relative;
    width: 100%; } }

.sm-img-cover img {
  width: 100%; }
  @media (min-width: 710px) {
    .sm-img-cover img {
      max-width: none;
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); } }

@media (min-width: 710px) {
  .sm-shrink {
    flex-shrink: 1; } }

.title {
  text-overflow: ellipsis;
  display: block; }

.display_none {
  display: none; }

.footer {
  background: #54575c;
  padding: 30px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 710px) {
    .footer {
      padding: 50px 0; } }
  @media (min-width: 1800px) {
    .footer {
      padding-top: 90px;
      display: flex;
      align-items: center; } }
  .footer .top-nav {
    padding-top: 0; }
    @media (min-width: 710px) {
      .footer .top-nav {
        padding: 0; } }
    @media (min-width: 1800px) {
      .footer .top-nav {
        margin-right: 0; } }
    @media (min-width: 710px) {
      .footer .top-nav li {
        margin-bottom: 0; } }
  .footer .lang-list {
    display: flex;
    justify-content: center; }
    @media (min-width: 980px) {
      .footer .lang-list {
        margin: 0 15px;
        order: 10; } }
    .footer .lang-list li {
      padding: 0 10px;
      position: relative; }
      .footer .lang-list li:not(:first-child):before {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #fff;
        content: "";
        left: 0; }
  .footer .navigation-bottom {
    padding-bottom: 0; }
  .footer a {
    font-size: 16px;
    font-weight: 500; }
  .footer .nav-block .block-title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    @media (min-width: 1220px) {
      .footer .nav-block .block-title {
        display: flex;
        height: 100%;
        min-height: 65px; } }
    @media (min-width: 1800px) {
      .footer .nav-block .block-title {
        display: block;
        height: auto;
        min-height: inherit; } }
  @media (min-width: 1220px) {
    .footer .nav-block div:first-child a {
      display: inline-block;
      align-self: flex-end; } }
  @media (min-width: 1800px) {
    .footer .nav-block div:first-child a {
      display: block;
      align-self: flex-end; } }

@media (min-width: 710px) {
  .navigation-top {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap; } }

@media (min-width: 980px) {
  .navigation-top {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end; } }

@media (min-width: 710px) {
  .navigation-top .socials-holder {
    order: 15; } }

.footer .navigation-top {
  margin-bottom: 30px; }
  .footer .navigation-top a:hover, .footer .navigation-top a:focus {
    text-decoration: none; }
  .footer .navigation-top li {
    line-height: 1; }

@media (min-width: 710px) {
  .footer-nav-links li {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px; } }

@media (min-width: 710px) {
  .footer-bottom .footer-nav-links {
    text-align: right; } }

.nav-block .block-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

.footer-box-line {
  border-bottom: 1px solid #dadada;
  margin-top: 40px; }

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10 !important;
  height: auto !important;
  display: block !important;
  overflow: visible;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .header:before {
    position: absolute;
    content: "";
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(to bottom, black -100%, rgba(0, 0, 0, 0) 100%); }
    @media (min-width: 710px) {
      .header:before {
        height: 120px; } }
    @media (min-width: 1220px) {
      .header:before {
        height: 140px; } }
    @media (min-width: 1800px) {
      .header:before {
        height: 180px; } }
  .header:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background: #54575c;
    content: "";
    transform: translateX(-100%);
    transition: transform .3s;
    width: 100%; }
    @media (min-width: 710px) {
      .header:after {
        transition: transform .2s; } }
    @media (min-width: 1220px) {
      .header:after {
        bottom: auto;
        top: 100%;
        transform: none;
        background: #fff;
        max-width: 1130px;
        height: 1px;
        margin: 0 auto; } }
    @media (min-width: 1800px) {
      .header:after {
        max-width: 1780px; } }
  .header.show {
    z-index: 2000 !important; }
    .header.show:after {
      transform: none; }
  .header.collapsing {
    z-index: 1000 !important; }
  @media (min-width: 1220px) {
    .header.smallHeader:before {
      height: 120px; } }
  .header .nav-block li:last-child {
    margin-bottom: 0; }
  .header .socials li a {
    font-size: 20px !important;
    opacity: 0.7; }

.header a {
  color: #fff; }

.header .nav-block,
.header .navigation-bottom {
  padding: 0 15px 9px; }
  @media (min-width: 1220px) {
    .header .nav-block,
    .header .navigation-bottom {
      padding: 0 0 9px; } }
  .header .nav-block .block-title,
  .header .navigation-bottom .block-title {
    margin-bottom: 11px; }
  .header .nav-block a,
  .header .navigation-bottom a {
    display: block;
    padding: 9px 15px;
    margin: 0 0 -1px;
    transition: color .3s, background-color .3s;
    overflow: hidden;
    line-height: 22px; }
    @media (min-width: 1220px) {
      .header .nav-block a,
      .header .navigation-bottom a {
        padding: 9px 10px; } }
    @media (min-width: 1800px) {
      .header .nav-block a,
      .header .navigation-bottom a {
        padding: 9px 15px; } }
    .header .nav-block a:hover, .header .nav-block a:focus,
    .header .navigation-bottom a:hover,
    .header .navigation-bottom a:focus {
      background: #fff;
      color: #54575c; }
    .header .nav-block a i,
    .header .navigation-bottom a i {
      float: right;
      margin-right: 30px;
      line-height: 22px;
      display: none; }
  .header .nav-block b,
  .header .navigation-bottom b {
    font-weight: 600; }
  .header .nav-block ul,
  .header .navigation-bottom ul {
    margin-bottom: 0; }
  .header .nav-block li,
  .header .navigation-bottom li {
    margin-bottom: 11px; }

.header .navigation-bottom {
  text-align: center; }
  @media (min-width: 710px) {
    .header .navigation-bottom {
      text-align: right; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 86px;
    height: 86px; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) > .container [class*="col-"] {
    width: auto;
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
    flex-shrink: 0; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-content {
    overflow: visible;
    flex-grow: 1;
    transition: none; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block {
    position: relative;
    z-index: 1;
    padding: 0; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    position: absolute;
    top: 36px;
    right: 0;
    width: auto;
    background: #fff;
    padding: 15px;
    z-index: 200; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul:before {
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    bottom: 100%;
    width: 100%; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul a {
    color: #54575c;
    margin: 0;
    white-space: nowrap; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul a:hover, body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul a:focus {
    background: #54575c;
    color: #fff; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block .block-title {
    border: none;
    margin-bottom: 0; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block .block-title a {
    padding: 0;
    display: inline;
    margin: 0;
    font-size: 18px;
    font-weight: 300; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block .block-title a:hover, body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block .block-title a:focus {
    background: transparent;
    color: #fff;
    text-decoration: none; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block:hover > ul {
    opacity: 1;
    visibility: visible; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .navigation-bottom {
    display: none; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav {
    padding: 0;
    margin: 0 -15px 15px; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav > li {
    margin: 0 15px; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav .socials a {
    font-size: 20px; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav a {
    font-size: 16px;
    font-weight: 300; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav a:hover, body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav a:focus {
    text-decoration: none; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) #nav {
    padding: 0; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .nav-head {
    padding: 0;
    border: none; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .bottom-nav {
    margin-bottom: 0; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .bottom-nav a {
    position: relative; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .bottom-nav a i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px; } }

@media (min-width: 1220px) {
  body:not(.initialStatePassed) .header:not(.smallHeader) .bottom-nav [class*="hover-covered-"]:hover, body:not(.initialStatePassed) .header:not(.smallHeader) .bottom-nav [class*="hover-covered-"]:focus {
    color: #fff !important; } }

@media (min-width: 1220px) {
  .header.smallHeader:after {
    display: none; } }

.footer a {
  color: #fff; }

.footer .nav-block,
.footer .navigation-bottom {
  padding: 0 15px 9px; }
  @media (min-width: 1220px) {
    .footer .nav-block,
    .footer .navigation-bottom {
      padding: 0 0 9px; } }
  .footer .nav-block .block-title,
  .footer .navigation-bottom .block-title {
    margin-bottom: 11px; }
  .footer .nav-block a,
  .footer .navigation-bottom a {
    display: block;
    padding: 9px 15px;
    margin: 0 0 -1px;
    transition: color .3s, background-color .3s;
    overflow: hidden;
    line-height: 22px; }
    @media (min-width: 1220px) {
      .footer .nav-block a,
      .footer .navigation-bottom a {
        padding: 9px 10px; } }
    @media (min-width: 1800px) {
      .footer .nav-block a,
      .footer .navigation-bottom a {
        padding: 9px 15px; } }
    .footer .nav-block a:hover, .footer .nav-block a:focus,
    .footer .navigation-bottom a:hover,
    .footer .navigation-bottom a:focus {
      background: #fff;
      color: #54575c; }
    .footer .nav-block a i,
    .footer .navigation-bottom a i {
      float: right;
      margin-right: 30px;
      line-height: 22px;
      display: none; }
  .footer .nav-block b,
  .footer .navigation-bottom b {
    font-weight: 600; }
  .footer .nav-block ul,
  .footer .navigation-bottom ul {
    margin-bottom: 0; }
  .footer .nav-block li,
  .footer .navigation-bottom li {
    margin-bottom: 11px; }

.footer .navigation-bottom {
  text-align: center; }
  @media (min-width: 710px) {
    .footer .navigation-bottom {
      text-align: right; } }

@media (min-width: 1220px) {
  .footer.smallHeader:after {
    display: none; } }

.nav-head {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff; }
  @media (min-width: 710px) {
    .nav-head {
      padding: 17px 0; } }
  @media (min-width: 1220px) {
    .smallHeader .nav-head {
      width: 100%; } }

.logo, #block-cms-blocks-logo {
  max-width: 210px;
  line-height: 1; }
  @media (min-width: 710px) {
    .logo, #block-cms-blocks-logo {
      max-width: 380px; } }
  @media (min-width: 980px) {
    .logo, #block-cms-blocks-logo {
      width: 380px; } }
  .logo img, #block-cms-blocks-logo img {
    width: 100%; }

.nav-content {
  background: #54575c;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
  transform: translateX(-100vw);
  transition: transform .3s;
  height: 100vh;
  overflow-y: auto;
  padding-top: 40px;
  display: block !important; }
  @media (min-width: 710px) {
    .nav-content {
      padding-top: 90px;
      transition: transform .2s; } }
  @media (min-width: 1220px) {
    .nav-content {
      position: static;
      transform: none;
      background: transparent;
      padding-top: 0;
      height: auto;
      z-index: 2;
      transition: none; } }
  .nav-content a {
    font-size: 16px;
    font-weight: 500; }
  @media (min-width: 1220px) {
    .smallHeader .nav-content {
      position: absolute;
      transform: translateX(-100vw);
      background: #54575c;
      padding-top: 90px;
      z-index: -2;
      height: 100vh;
      transition: transform .3s;
      display: flex;
      align-items: center;
      justify-content: center; } }
  @media (min-width: 1220px) {
    .smallHeader .nav-content .bottom-nav .row {
      justify-content: center !important; } }
  header.show .nav-content {
    transform: none;
    margin-left: 0 !important; }

.nav-toggler {
  width: 18px;
  height: 16px;
  position: relative;
  margin-left: 10px; }
  @media (min-width: 710px) {
    .nav-toggler {
      width: 30px;
      height: 26px; } }
  @media (min-width: 1220px) {
    .nav-toggler {
      display: none; } }
  @media (min-width: 1220px) {
    .initialStatePassed .nav-toggler {
      display: block; } }
  .nav-toggler:before, .nav-toggler:after,
  .nav-toggler span {
    width: 100%;
    height: 4px;
    position: absolute;
    background: #fff;
    right: 0;
    width: 18px; }
    @media (min-width: 710px) {
      .nav-toggler:before, .nav-toggler:after,
      .nav-toggler span {
        height: 6px;
        left: 0;
        width: 100%; } }
  .nav-toggler:before, .nav-toggler:after {
    transform: none;
    transition: transform .3s;
    content: "";
    transform-origin: left; }
  .nav-toggler span {
    transition: opacity .3s;
    opacity: 1;
    top: 6px; }
    @media (min-width: 710px) {
      .nav-toggler span {
        top: 10px; } }
  .nav-toggler:after {
    top: 12px; }
    @media (min-width: 710px) {
      .nav-toggler:after {
        top: 20px; } }
  .nav-toggler[aria-expanded="true"] span {
    opacity: 0; }
  .nav-toggler[aria-expanded="true"]:before {
    transform: rotate(42deg); }
  .nav-toggler[aria-expanded="true"]:after {
    transform: rotate(-42deg); }

.top-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 0; }
  @media (min-width: 710px) {
    .top-nav {
      justify-content: flex-end; } }
  @media (min-width: 980px) {
    .top-nav {
      align-items: center; } }
  .header .top-nav {
    margin-bottom: 20px; }
  .top-nav > li {
    order: 3;
    margin: 0 14px; }
    @media (min-width: 710px) {
      .top-nav > li {
        line-height: 1; } }
    .top-nav > li.language-select {
      order: 1; }
      @media (min-width: 710px) {
        .top-nav > li.language-select {
          order: 10; } }

.language-select {
  width: 100%; }
  @media (min-width: 710px) {
    .language-select {
      width: auto; } }
  @media (min-width: 1220px) {
    header:not(.small-header) .language-select {
      position: relative; } }
  @media (min-width: 1220px) {
    header:not(.small-header) .language-select:hover .lang-options, header:not(.small-header) .language-select:focus .lang-options {
      visibility: visible;
      opacity: 1; } }
  .language-select .lang-options {
    opacity: 0; }
    .language-select .lang-options li {
      padding: 0 10px;
      margin-bottom: 11px; }

.lang-select-opener {
  display: none; }
  @media (min-width: 1220px) {
    header:not(.smallHeader) .lang-select-opener {
      display: inline-block;
      position: relative;
      text-align: right; } }
  header:not(.smallHeader) .lang-select-opener i {
    margin-left: 5px; }

.lang-options {
  display: flex;
  justify-content: center; }
  .header .lang-options {
    margin-bottom: 40px;
    margin-top: 20px; }
    @media (min-width: 710px) {
      .header .lang-options {
        margin-bottom: 0;
        margin-top: 0; } }
  .lang-options .active a {
    pointer-events: none; }
  @media (min-width: 1220px) {
    header:not(.smallHeader) .lang-options {
      flex-direction: column;
      position: absolute;
      top: 25px;
      right: 0;
      padding: 15px 5px;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      background: #fff; } }
  @media (min-width: 1220px) {
    header:not(.smallHeader) .lang-options .active a {
      background: #54575c;
      color: #fff; } }
  @media (min-width: 1220px) {
    header:not(.smallHeader) .lang-options li {
      display: block; } }
  @media (min-width: 1220px) {
    header:not(.smallHeader) .lang-options a {
      padding: 9px 15px;
      display: block;
      color: #54575c;
      background: #fff;
      transition: color 0.3s, background 0.3s;
      text-align: center; } }
  @media (min-width: 1220px) {
    header:not(.smallHeader) .lang-options a:hover {
      color: #fff;
      background: #54575c;
      text-decoration: none !important; } }
  .lang-options li {
    position: relative;
    line-height: 1;
    margin-bottom: 0; }
    .lang-options li:not(:first-child):before {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: #fff;
      content: "";
      left: 0; }
    .lang-options li:last-child {
      margin-bottom: 0;
      padding: 0 10px; }

.socials-holder {
  width: 100%; }
  @media (min-width: 980px) {
    .socials-holder {
      width: auto; } }
  .top-nav .socials-holder {
    order: 2; }
    @media (min-width: 710px) {
      .top-nav .socials-holder {
        order: 15; } }

.socials {
  margin-bottom: 20px; }
  @media (min-width: 710px) {
    .socials {
      margin-top: 20px; } }
  @media (min-width: 980px) {
    .socials {
      margin: 0; } }
  .socials li {
    margin: 0 7px; }
    .socials li a {
      font-size: 40px !important;
      transition: color .3s; }
      .socials li a:hover {
        text-decoration: none !important; }
  .socials i {
    transform: scale(1); }

.smallHeader .language-select .lang-options li {
  margin-bottom: 0;
  padding: 0 10px; }

.header.show .language-select .lang-options {
  opacity: 1; }
  .header.show .language-select .lang-options li {
    margin-bottom: 0;
    padding: 0 10px; }

.header.show .socials li a {
  font-size: 40px !important;
  opacity: 1; }

.no-link .nav-block .block-title > a {
  pointer-events: none; }

body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav > li:hover > ul {
  /*rainer*/
  margin-top: 0; }

.multiwrap {
  display: flex;
  flex-direction: column;
  height: 100vh; }
  @media (min-width: 980px) {
    .multiwrap {
      flex-direction: row; } }
  .multiwrap h2 {
    transform-origin: top left;
    transform: scale(0.5);
    transition: transform .5s; }
  .multiwrap .page-title-holder {
    padding-top: 50px;
    max-width: 100%;
    transition: top .5s; }
    @media (min-width: 710px) {
      .multiwrap .page-title-holder {
        padding-top: 80px; } }
    @media (min-width: 980px) {
      .multiwrap .page-title-holder {
        transition: max-width .5s, left .5s;
        padding-top: 125px; } }
    @media (min-width: 980px) {
      .multiwrap .page-title-holder {
        padding-top: 180px; } }
  .multiwrap .go-to-content {
    opacity: 0;
    transition: opacity .5s, color .3s; }
  .multiwrap .top-banner {
    min-height: 1px;
    max-height: 20vh;
    transition: max-height .5s; }
    @media (min-width: 980px) {
      .multiwrap .top-banner {
        max-width: 20%;
        min-height: 100vh;
        height: 100vh;
        transition: max-width .5s; } }
  .multiwrap:hover .top-banner, .multiwrap:focus .top-banner {
    max-width: 100%;
    flex-grow: 1;
    overflow: hidden; }
    .multiwrap:hover .top-banner:not(:hover), .multiwrap:hover .top-banner:not(:focus), .multiwrap:focus .top-banner:not(:hover), .multiwrap:focus .top-banner:not(:focus) {
      min-height: 1px;
      max-height: 10vh; }
      @media (min-width: 980px) {
        .multiwrap:hover .top-banner:not(:hover), .multiwrap:hover .top-banner:not(:focus), .multiwrap:focus .top-banner:not(:hover), .multiwrap:focus .top-banner:not(:focus) {
          max-width: 150px;
          min-height: 100vh; } }
      @media (min-width: 1220px) {
        .multiwrap:hover .top-banner:not(:hover), .multiwrap:hover .top-banner:not(:focus), .multiwrap:focus .top-banner:not(:hover), .multiwrap:focus .top-banner:not(:focus) {
          max-width: 200px; } }
      .multiwrap:hover .top-banner:not(:hover) .page-title-holder, .multiwrap:hover .top-banner:not(:focus) .page-title-holder, .multiwrap:focus .top-banner:not(:hover) .page-title-holder, .multiwrap:focus .top-banner:not(:focus) .page-title-holder {
        max-height: 400px;
        top: -10px; }
        @media (min-width: 980px) {
          .multiwrap:hover .top-banner:not(:hover) .page-title-holder, .multiwrap:hover .top-banner:not(:focus) .page-title-holder, .multiwrap:focus .top-banner:not(:hover) .page-title-holder, .multiwrap:focus .top-banner:not(:focus) .page-title-holder {
            max-width: 400px;
            left: -50%; } }
    .multiwrap:hover .top-banner:hover, .multiwrap:focus .top-banner:hover {
      max-width: 100%;
      max-height: 60vh; }
      @media (min-width: 980px) {
        .multiwrap:hover .top-banner:hover, .multiwrap:focus .top-banner:hover {
          max-width: 100%;
          min-height: 100vh; } }
      @media (min-width: 1220px) {
        .multiwrap:hover .top-banner:hover, .multiwrap:focus .top-banner:hover {
          max-width: 100%; } }
      .multiwrap:hover .top-banner:hover .go-to-content, .multiwrap:focus .top-banner:hover .go-to-content {
        opacity: 1; }
      .multiwrap:hover .top-banner:hover h2, .multiwrap:focus .top-banner:hover h2 {
        transform: scale(1); }
      @media (min-width: 980px) {
        .multiwrap:hover .top-banner:hover .page-title-holder, .multiwrap:focus .top-banner:hover .page-title-holder {
          max-width: none;
          left: 0; } }

.top-banner {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end; }
  .initialStatePassed .top-banner {
    display: none; }
  .initialStatePassed2 .top-banner {
    display: none; }
  .initialtemp .top-banner {
    display: none; }
  .initialStatePassed.initialStatePassing .staticBanner .top-banner {
    display: flex; }
  .top-banner .slider {
    flex-grow: 1;
    display: flex;
    position: relative;
    z-index: 1; }
    .top-banner .slider .slick-list {
      width: 100%;
      position: relative;
      z-index: 1; }
    .top-banner .slider .slick-track {
      min-height: 100%;
      display: flex; }
    .top-banner .slider ~ .container {
      left: 0;
      right: 0;
      position: absolute;
      z-index: 1000;
      width: 100px; }
  .top-banner > .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto; }
  .top-banner .slick-slide {
    width: 100vw;
    height: auto;
    min-height: 100vh; }
    @media (orientation: landscape) {
      .top-banner .slick-slide {
        height: 100vh; } }
    @media (min-width: 710px) {
      .top-banner .slick-slide {
        height: 100vh; } }
  .top-banner .slick-dots {
    position: absolute;
    top: auto !important;
    left: 0;
    right: 0;
    padding: 0 15px;
    margin: 0 auto;
    list-style: none;
    z-index: 8;
    width: 100%;
    bottom: 244px !important; }
    @media (min-width: 710px) {
      .top-banner .slick-dots {
        max-width: 680px;
        bottom: 21px !important; } }
    @media (min-width: 980px) {
      .top-banner .slick-dots {
        max-width: 950px; } }
    @media (min-width: 1220px) {
      .top-banner .slick-dots {
        max-width: 1160px; } }
    @media (min-width: 1800px) {
      .top-banner .slick-dots {
        max-width: 1810px; } }
    .top-banner .slick-dots li {
      display: inline-block;
      position: relative;
      z-index: 90; }
      .top-banner .slick-dots li button {
        width: 60px;
        height: 10px;
        background: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0;
        margin: 0 20px 0 0;
        border: none;
        text-indent: -9999px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        outline: none;
        box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.2);
        transition: background-color .3s; }
      .top-banner .slick-dots li.slick-active button {
        background: #54575c; }
  .top-banner .breadcrumb {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    margin: 0 auto;
    padding: 0 20px;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2); }
    @media (min-width: 710px) {
      .top-banner .breadcrumb {
        top: 90px;
        padding: 0 15px; } }
    @media (min-width: 1220px) {
      .top-banner .breadcrumb {
        top: 145px; } }
    .top-banner .breadcrumb .breadcrumb-item {
      color: #fff;
      position: relative;
      z-index: 100; }
      .top-banner .breadcrumb .breadcrumb-item:hover, .top-banner .breadcrumb .breadcrumb-item:focus {
        text-decoration: underline; }
      .top-banner .breadcrumb .breadcrumb-item a, .top-banner .breadcrumb .breadcrumb-item:before {
        color: inherit; }
      .top-banner .breadcrumb .breadcrumb-item.active {
        color: #fff;
        pointer-events: none; }
  .top-banner .page-title h2 {
    font-size: 22px;
    line-height: 28px; }
    @media (min-width: 710px) {
      .top-banner .page-title h2 {
        font-size: 50px;
        line-height: 60px; } }
    @media (min-width: 1220px) {
      .top-banner .page-title h2 {
        font-size: 80px;
        line-height: 90px; } }
    @media (min-width: 1800px) {
      .top-banner .page-title h2 {
        font-size: 120px;
        line-height: 130px; } }
  .top-banner .page-title h1 a, .top-banner .page-title h3 a {
    color: #fff; }
    .top-banner .page-title h1 a:focus, .top-banner .page-title h3 a:focus {
      outline: none; }

.home-banner-items {
  padding-bottom: 0; }
  @media (min-width: 710px) {
    .home-banner-items {
      position: absolute;
      top: 100%;
      right: 0;
      transform: translateY(-100%);
      z-index: 5;
      padding-bottom: 15px; } }
  .home-banner-items > div > div > p {
    margin-bottom: 0; }

.banner-link {
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  color: #fff;
  margin: 0 -20px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0px 15px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0 15px rgba(0, 0, 0, 0.15));
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 0 0 100px rgba(0, 0, 0, 0.5);
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out; }
  @media (min-width: 710px) {
    .banner-link {
      margin: 0 0 15px;
      min-width: 300px;
      padding: 18px 20px; } }
  @media (min-width: 1220px) {
    .banner-link {
      min-width: 370px;
      padding: 34px 40px; } }
  @media (min-width: 1800px) {
    .banner-link {
      min-width: 630px; } }
  .banner-link:hover, .banner-link:focus {
    color: #fff;
    text-decoration: none; }
    @media (min-width: 710px) {
      .banner-link:hover, .banner-link:focus {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05); } }
    @media (min-width: 1800px) {
      .banner-link:hover, .banner-link:focus {
        transform: scale(1.03);
        -webkit-transform: scale(1.03);
        -moz-transform: scale(1.03); } }
  .banner-link strong {
    font-size: 22px;
    font-weight: 300;
    margin-top: 10px; }
    @media (min-width: 710px) {
      .banner-link strong {
        font-size: 30px; } }
    @media (min-width: 1220px) {
      .banner-link strong {
        font-size: 36px; } }
    @media (min-width: 1800px) {
      .banner-link strong {
        font-size: 70px; } }

.page-title-holder {
  position: absolute;
  top: 0;
  color: #fff;
  left: 0;
  right: 0;
  z-index: 2;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 100px rgba(0, 0, 0, 0.5);
  padding-top: 90px;
  letter-spacing: 0.7px; }
  @media (min-width: 710px) {
    .page-title-holder {
      padding-top: 107px; } }
  @media (min-width: 1220px) {
    .page-title-holder {
      padding-top: 120px; } }
  @media (min-width: 1800px) {
    .page-title-holder {
      padding-top: 160px; } }

.initialStatePassed.initialStatePassing .top-banner.slider-banner {
  display: block; }

.initialStatePassed .top-banner.slider-banner {
  display: block; }

body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul {
  margin-top: -111111111111px;
  /*rainer*/ }

body:not(.initialStatePassed) .header:not(.smallHeader).show .nav-block > ul {
  margin-top: 0;
  /*rainer*/ }

body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block:hover > ul {
  margin-top: 0; }

.smallHeader .nav-content {
  margin-left: -999999999px;
  opacity: 0; }

.smallHeader.show.in .nav-content {
  opacity: 1; }

.recomended-item {
  position: relative;
  z-index: 1;
  display: block;
  margin-top: 20px; }
  .recomended-item .item-title-holder {
    background: #fff;
    display: block;
    position: absolute;
    padding: 12px 20px 12px 15px;
    top: 100%;
    transform: translateY(-100%);
    margin-top: -20px;
    color: #1a1a1b;
    transition: color .3s, background-color .3s; }
    @media (min-width: 710px) {
      .recomended-item .item-title-holder {
        margin-top: -30px;
        padding: 11px 20px 11px 15px;
        font-size: 16px;
        line-height: 18px; } }
    @media (min-width: 1220px) {
      .recomended-item .item-title-holder {
        margin-top: -40px; } }
  .recomended-item:hover .item-title-holder, .recomended-item:focus .item-title-holder {
    color: #fff;
    background: #54575c; }

.sidebar {
  position: relative;
  z-index: 5;
  font-size: 16px;
  margin-bottom: 20px; }
  @media (min-width: 980px) {
    .sidebar {
      width: 225px;
      margin-right: 30px;
      flex-shrink: 0;
      font-weight: 500; } }
  @media (min-width: 1220px) {
    .sidebar {
      margin-right: 55px; } }
  @media (min-width: 980px) {
    .sidebar:after {
      width: 1px;
      height: 100%;
      position: absolute;
      content: "";
      right: -15px;
      top: 0;
      background: #dadada; } }
  .block-inverse .sidebar:after {
    background: #fff; }
  .sidebar ul {
    margin: 0; }
  @media (min-width: 980px) {
    .sidebar .subnav {
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s; } }
  .sidebar .subnav a {
    padding-left: 15px;
    font-size: 14px;
    margin-left: 10px; }
  .sidebar .subnav .sub-active a {
    pointer-events: none;
    color: #fff;
    background: #54575c; }
  .sidebar .sidebar-nav-item {
    margin-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px; }
    @media (min-width: 980px) {
      .sidebar .sidebar-nav-item {
        margin-left: 0;
        margin-right: 0; } }
    .sidebar .sidebar-nav-item:last-child {
      margin-bottom: 0; }
    @media (min-width: 980px) {
      .sidebar .sidebar-nav-item.active .subnav {
        max-height: 300px; } }
    .sidebar .sidebar-nav-item.active > a.colored-success {
      color: #ffffff !important;
      background: #008c58; }
  .sidebar .sidebar-nav-item.child-item {
    margin: 10px 0; }
  .sidebar .collapse.show.in .sidebar-nav-item.child-item {
    margin: 10px 0 10px 20px; }
  .sidebar a {
    display: flex;
    padding: 10px 15px;
    transition: color .3s, background-color .3s;
    color: #54575c;
    background: #fff;
    align-items: center; }
    .sidebar a > div {
      width: 100%; }
    .sidebar a:hover, .sidebar a:focus {
      color: #fff;
      background: #54575c; }
    .sidebar a i {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      text-align: center;
      font-size: 20px; }
    .sidebar a.colored-success:hover, .sidebar a.colored-success:focus {
      color: #fff !important;
      background: #008c58; }
  .sidebar .active:not(.sidebar-nav-item) > a {
    color: #fff;
    background: #54575c; }
    .sidebar .active:not(.sidebar-nav-item) > a i.icon-angle-down-bold {
      float: right;
      margin-right: -5px; }
  @media (min-width: 980px) {
    .sidebar .active > a {
      color: #fff;
      background: #54575c; } }
  .block-inverse .sidebar .collapse {
    background: #54575c; }
    @media (min-width: 980px) {
      .block-inverse .sidebar .collapse {
        background: transparent; } }
  .block-inverse .sidebar a {
    color: #fff;
    background: none; }
    .block-inverse .sidebar a:hover, .block-inverse .sidebar a:focus {
      color: #54575c;
      background: #fff; }
  .block-inverse .sidebar .active > a {
    color: #54575c;
    background: #fff; }
  .sidebar:not(.sidebar-right) nav {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 20;
    top: 100%;
    background: #fff;
    display: block;
    max-height: 300px; }
    @media (min-width: 710px) {
      .sidebar:not(.sidebar-right) nav {
        max-height: none;
        padding-top: 15px;
        padding-bottom: 5px; } }
    @media (min-width: 980px) {
      .sidebar:not(.sidebar-right) nav {
        opacity: 1;
        visibility: visible;
        max-height: none;
        position: static;
        padding-top: 0; } }
    .sidebar:not(.sidebar-right) nav.show {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
      .sidebar:not(.sidebar-right) nav.show .row {
        padding: 15px 0; }
        @media (min-width: 710px) {
          .sidebar:not(.sidebar-right) nav.show .row {
            padding: 0; } }
  @media (min-width: 980px) {
    .sidebar:not(.sidebar-right) [class*="col-"]:not(:last-child) {
      margin-bottom: 10px; } }
  .sidebar.sidebar-right {
    margin-right: 0; }
    @media (min-width: 710px) {
      .sidebar.sidebar-right {
        width: 100%; } }
    @media (min-width: 1220px) {
      .sidebar.sidebar-right {
        margin-left: 55px;
        width: 225px; } }
    .sidebar.sidebar-right:after {
      width: 1px;
      height: 100%;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: #dadada;
      display: none; }
      @media (min-width: 980px) {
        .sidebar.sidebar-right:after {
          right: auto;
          left: -1px;
          display: block; } }
      @media (min-width: 1220px) {
        .sidebar.sidebar-right:after {
          left: -15px;
          display: block; } }
    @media (min-width: 710px) {
      .sidebar.sidebar-right .sidebar-nav-item {
        margin-right: 0; } }
    @media (min-width: 1220px) {
      .sidebar.sidebar-right nav .row div:first-child .sidebar-nav-item:last-of-type {
        margin-bottom: 40px; } }
    @media (min-width: 1220px) {
      .sidebar.sidebar-right.sidebar-newsletter-right {
        margin-top: -80px; } }
    @media (min-width: 1800px) {
      .sidebar.sidebar-right.sidebar-newsletter-right {
        margin-top: -80px; } }

.custom-scroll-mask {
  padding: 30px 0;
  margin: 20px 0; }
  @media (min-width: 1220px) {
    .custom-scroll-mask {
      padding: 40px 0; } }
  .custom-scroll-mask .mCSB_scrollTools {
    opacity: 1 !important; }
    .custom-scroll-mask .mCSB_scrollTools .mCSB_draggerRail {
      background: #dadada;
      height: 10px;
      margin: 0; }
  .custom-scroll-mask .mCSB_dragger {
    background: none; }
  .custom-scroll-mask .mCSB_dragger_bar {
    background: #008c58 !important; }

.custom-scroll-mask.custom-history {
  padding: 40px 0;
  margin: 0 -15px; }
  .custom-scroll-mask.custom-history .mCSB_scrollTools .mCSB_draggerRail {
    background: #fff; }

.custom-scroll-holder {
  display: flex;
  flex-wrap: nowrap; }
  .custom-scroll-holder table {
    width: 100%; }
    .custom-scroll-holder table tr td:nth-Child(1) > div {
      margin-left: -15px; }
      .custom-scroll-holder table tr td:nth-Child(1) > div:nth-Child(2) {
        border: none; }

.history-block {
  flex-shrink: 0;
  width: 250px;
  color: #fff;
  font-size: 10px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }
  @media (min-width: 710px) {
    .history-block {
      width: 310px;
      font-size: 12px; } }
  @media (min-width: 980px) {
    .history-block {
      width: 315px; } }
  @media (min-width: 1220px) {
    .history-block {
      font-size: 16px;
      width: 400px; } }
  @media (min-width: 1800px) {
    .history-block {
      width: 483px; } }
  .history-block .content-part {
    height: 100%; }
    .history-block .content-part p {
      min-height: 60px; }
  .history-block:last-child .time-holder:before {
    width: 40%; }
  .history-block:last-child .time-holder:after {
    position: absolute;
    content: "";
    top: 15px;
    right: 8%;
    border-style: solid;
    border-width: 3px 0 3px 12px;
    border-color: transparent transparent transparent #fff;
    transform: translate(0, -50%); }
  .history-block .img-holder img {
    width: 100%; }
  .history-block .time-holder {
    position: relative;
    padding-top: 30px;
    font-size: 30px;
    text-align: center; }
    @media (min-width: 710px) {
      .history-block .time-holder {
        margin-top: 15px; } }
    .history-block .time-holder:before {
      position: absolute;
      top: 15px;
      left: 50%;
      width: calc(100% + 30px);
      transform: translateY(-50%);
      content: "";
      z-index: 2;
      height: 2px;
      background: #fff; }
    .history-block .time-holder .arrows {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      content: "";
      background: #fff;
      left: 50%;
      top: 15px;
      transform: translate(-50%, -50%);
      position: absolute; }
      .history-block .time-holder .arrows:before {
        width: 2px;
        height: 20px;
        position: absolute;
        content: "";
        top: 4px;
        background: #fff;
        transform: translate(-50%, -100%);
        -ms-transform: translate(3px, -20px); }
      .history-block .time-holder .arrows:after {
        position: absolute;
        content: "";
        top: -25px;
        border-style: solid;
        border-width: 0 3px 12px 3px;
        border-color: transparent transparent #fff transparent;
        transform: translateX(-50%);
        -ms-transform: translateX(1px); }

.lang-es .history-block .content-part p {
  min-height: 80px; }

.data-titles-table {
  float: left; }
  .data-titles-table span:first-child {
    padding-top: 1px !important; }

.scroll-line-margin .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  margin: 0 15px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: 0 !important;
  right: 0;
  bottom: auto !important;
  left: 0; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
    height: 10px !important; }
    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
      height: 10px !important;
      margin: 0 !important; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 0 !important;
  margin-top: 30px; }

.description-list {
  counter-reset: list;
  padding-left: 0; }
  .description-list li {
    list-style: none;
    counter-increment: list;
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    .description-list li:before {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      content: counter(list);
      background: #fff;
      margin-right: 15px;
      color: #54575c;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
  .description-list.num-inverse li {
    align-items: flex-start; }
    .description-list.num-inverse li:before {
      width: 30px;
      height: 30px;
      background: #1a1a1b;
      color: #fff;
      transform: translateY(-8px); }

/*list bullets without list element*/
.custom-number-rounded {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #1a1a1b;
  z-index: 1;
  border-radius: 50%;
  margin-right: 15px;
  vertical-align: top;
  text-align: center;
  padding-top: 5px; }

.custom-number-rounded-text {
  display: inline-block;
  width: calc(100% - 70px); }

#img-nums-1 {
  counter-reset: list; }
  #img-nums-1 li {
    list-style: none;
    counter-increment: list;
    position: absolute;
    z-index: 1; }
    #img-nums-1 li:before {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      content: counter(list);
      background: #fff;
      margin-right: 5px;
      color: #54575c;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 710px) {
        #img-nums-1 li:before {
          width: 40px;
          height: 40px; } }
    #img-nums-1 li:nth-child(1) {
      top: 74%;
      left: 56%; }
    #img-nums-1 li:nth-child(2) {
      top: 50%;
      left: 60%; }
    #img-nums-1 li:nth-child(3) {
      top: 38%;
      left: 38%; }
    #img-nums-1 li:nth-child(4) {
      top: 21%;
      left: 66%; }
    #img-nums-1 li:nth-child(5) {
      top: 45%;
      left: 8%; }

.danger-list {
  margin-bottom: 0; }
  .danger-list li {
    position: relative;
    z-index: 1;
    padding-left: 10px;
    line-height: 1.6; }
    .danger-list li:before {
      position: absolute;
      left: 0;
      content: "!";
      color: #ec1124;
      top: 0; }

.same-height-image {
  position: relative;
  z-index: 1; }
  @media (min-width: 710px) {
    .same-height-image {
      padding-bottom: 70px; } }
  @media (min-width: 710px) {
    .same-height-image p:last-child {
      margin-bottom: 0; } }
  .same-height-image .img-holder {
    min-height: 140px; }
    @media (min-width: 710px) {
      .same-height-image .img-holder {
        min-height: 100%; } }
    @media (min-width: 1800px) {
      .same-height-image .img-holder {
        min-height: 394px; } }
  .same-height-image .bg-holder {
    background-position: 0 75%; }
    @media (min-width: 710px) {
      .same-height-image .bg-holder {
        background-position: 34% 0;
        background-size: 178%; } }
    @media (min-width: 1220px) {
      .same-height-image .bg-holder {
        background-position: center center;
        background-size: cover; } }
    @media (min-width: 1800px) {
      .same-height-image .bg-holder {
        background-position: center 70%; } }
  .same-height-image .text-holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%; }

@media (min-width: 710px) {
  .d-flex-custom {
    display: -ms-flexbox !important;
    display: flex !important; } }

.image-cover {
  height: 100%; }
  .image-cover img {
    object-fit: cover;
    height: 100%;
    width: 100% !important; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .image-cover img {
        height: auto; } }

.detail-block {
  margin-bottom: 20px;
  /*comment*/ }
  .detail-block .content-holder {
    background: #f8f8f8; }
  .detail-block .img-holder {
    position: relative; }
    .detail-block .img-holder a {
      position: absolute;
      font-size: 40px;
      color: #fff;
      transition: color .3s;
      text-shadow: 1px 1px 4px #54575c;
      right: 15px;
      top: 15px;
      outline: none;
      line-height: 1; }
      .detail-block .img-holder a:hover, .detail-block .img-holder a:focus {
        color: #008c58; }
  .detail-block .row .img-holder {
    padding: 15px; }
    .detail-block .row .img-holder .angle-left,
    .detail-block .row .img-holder .angle-right {
      position: absolute;
      bottom: 15px;
      left: 15px;
      max-width: 45%; }
      @media (min-width: 1800px) {
        .detail-block .row .img-holder .angle-left,
        .detail-block .row .img-holder .angle-right {
          width: 35%;
          text-align: right; } }
    .detail-block .row .img-holder .angle-right {
      left: auto;
      right: 15px; }
      @media (min-width: 1800px) {
        .detail-block .row .img-holder .angle-right {
          text-align: left; } }
    @media (min-width: 710px) {
      .detail-block .row .img-holder img {
        max-width: 100%; } }
    .detail-block .row .img-holder img.w-auto {
      width: auto; }
  .detail-block .img-no-offset {
    max-width: 75%;
    margin: 0 auto;
    padding: 0;
    max-height: 90%; }
    @media (min-width: 710px) {
      .detail-block .img-no-offset {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 30px;
        max-width: none;
        margin-top: -15px; } }
    .detail-block .img-no-offset img {
      max-height: 100%; }
  .detail-block .col-auto {
    padding-bottom: 15px; }
    @media (min-width: 1800px) {
      .detail-block .col-auto {
        flex-basis: auto; } }

@media (min-width: 1220px) {
  .w-lg-50 {
    width: calc(50% - 3px);
    vertical-align: top; } }

ngb-tabset .nav-item .nav-link {
  display: block;
  padding: 0; }
  ngb-tabset .nav-item .nav-link div {
    padding-left: 15px;
    padding-right: 15px;
    transition: color .3s, background-color .3s; }
  ngb-tabset .nav-item .nav-link.disabled div {
    opacity: .2;
    font-size: 14px;
    padding: 12px 15px;
    text-transform: uppercase;
    font-weight: 300; }
  ngb-tabset .nav-item .nav-link.active div {
    color: #54575c;
    background: #fff; }

.line-left:after {
  width: 1px;
  height: auto;
  content: "";
  background-color: #fff;
  top: 30px;
  bottom: 30px;
  right: 7px;
  position: absolute; }

@media (min-width: 1800px) {
  app-boxes-sub-group .order-10 {
    position: absolute;
    bottom: 0;
    left: 0; } }

.line-right:after {
  width: 1px;
  height: auto;
  content: "";
  background-color: #fff;
  top: 30px;
  bottom: 30px;
  left: -8px;
  position: absolute; }

.checkbox-set {
  min-height: 100%; }
  @media (min-width: 1220px) {
    .checkbox-set {
      padding-bottom: 20px;
      padding-top: 20px; } }
  .checkbox-set .tip {
    top: 50%;
    right: -65px;
    overflow: visible;
    max-height: none;
    transform: translateY(-50%); }
    .checkbox-set .tip:hover i, .checkbox-set .tip:focus i {
      transform: translateY(-50%); }
    .checkbox-set .tip:hover .tip-content, .checkbox-set .tip:focus .tip-content {
      box-shadow: 0 0 3px #fff;
      visibility: visible;
      opacity: 1; }
    .checkbox-set .tip i {
      z-index: 2;
      font-size: 14px; }
    .checkbox-set .tip a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .checkbox-set .tip .tip-content {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      transition: box-shadow .3s linear .3s, visibility .3s, opacity .3s;
      width: 230px;
      height: auto;
      max-height: none;
      visibility: hidden;
      opacity: 0; }
      .checkbox-set .tip .tip-content:before {
        height: 100%; }
      .checkbox-set .tip .tip-content:after {
        display: none; }
  .checkbox-set .title-holder {
    margin-bottom: 20px; }
    .checkbox-set .title-holder i {
      font-size: 40px;
      margin-right: 10px; }
    .checkbox-set .title-holder .counter {
      margin-right: 10px; }
  @media (min-width: 1220px) {
    .checkbox-set .tab-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 1220px) {
    .checkbox-set .tab-pane {
      width: 50%;
      padding-right: 20px;
      flex-shrink: 0; } }
  @media (min-width: 1220px) {
    .checkbox-set .subtitle {
      width: 100%;
      margin-bottom: 20px; } }
  @media (min-width: 1220px) {
    .checkbox-set.arrowed {
      padding-top: 0; } }
  .checkbox-set.arrowed .custom-control-label span {
    display: flex;
    justify-content: space-between; }
    .checkbox-set.arrowed .custom-control-label span:after {
      font-family: icomoon !important;
      content: "\e910";
      transform: translateX(25px); }
  @media (min-width: 1220px) {
    .checkbox-set.pr-lg-3 {
      position: relative; } }
  @media (min-width: 1220px) {
    .checkbox-set.pr-lg-3:after {
      width: 1px;
      height: auto;
      content: "";
      background-color: #fff;
      top: 20px;
      bottom: 20px;
      right: 5px;
      position: absolute; } }
  @media (min-width: 1220px) {
    .checkbox-set.pr-lg-3 + .checkbox-set {
      position: relative; } }
  @media (min-width: 1220px) {
    .checkbox-set.pr-lg-3 + .checkbox-set:after {
      width: 1px;
      height: auto;
      content: "";
      background-color: #fff;
      top: 20px;
      bottom: 20px;
      left: -10px;
      position: absolute; } }

@media (min-width: 1220px) {
  .checkbox-set#Unterkonstruktionen {
    position: relative; } }

@media (min-width: 1220px) {
  .checkbox-set#Unterkonstruktionen:after {
    width: 1px;
    height: auto;
    content: "";
    background-color: #fff;
    top: 20px;
    bottom: 20px;
    left: -10px;
    position: absolute; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) {
    width: 100%; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .subtitle {
    width: 75%;
    order: 1;
    flex-shrink: 0; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .subtitle:nth-child(1) {
    width: 25%; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane {
    width: 37.5%;
    order: 2; } }

@media (min-width: 1800px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane {
    width: 25%; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane:nth-of-type(2) {
    width: 25%; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane:nth-of-type(5) {
    order: 10;
    padding-right: 0; } }

@media (min-width: 1800px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane:nth-of-type(5) {
    transform: translateX(100%);
    margin-top: -200px; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane:nth-of-type(7) {
    order: 9;
    margin-left: 25%; } }

@media (min-width: 1800px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane:nth-of-type(7) {
    margin-left: 0; } }

@media (min-width: 1220px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(4) .tab-pane:nth-of-type(6) {
    padding-right: 0; } }

@media (min-width: 1800px) {
  .fieldset > .tab-content > .tab-pane:nth-of-type(5) .custom-checkbox {
    width: 50%; } }

.info-group {
  position: relative;
  margin-right: 60px; }
  .info-group:after {
    width: 20px;
    height: 100%;
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    border-style: solid;
    border-color: #fff;
    border-width: 1px 1px 1px 0; }

.order-checkbox {
  min-height: 40px;
  padding: 0; }
  .order-checkbox input {
    margin-top: 7px; }
  .order-checkbox .custom-control-label {
    min-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px; }
  .order-checkbox a {
    transition: .3s all;
    line-height: 40px;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 0;
    color: #1a1a1b;
    height: 40px;
    width: 40px;
    text-align: center; }
    .order-checkbox a:hover {
      background: #008c58;
      color: #fff; }
    .order-checkbox a i {
      display: inline-block;
      font-size: 20px;
      line-height: unset; }
  .order-checkbox.custom-checkbox .custom-control-label:before {
    background: #f7f7f7 !important; }
  .order-checkbox.custom-checkbox .custom-control-label:after {
    background: transparent; }
  .order-checkbox .custom-control-input:checked + .custom-control-label:before {
    background: #f7f7f7 !important; }

.pinnwand-block {
  margin-bottom: 20px; }
  @media (min-width: 1220px) {
    .pinnwand-block {
      margin-bottom: 40px; } }
  .pinnwand-block .block-control {
    position: relative; }
    @media (min-width: 1220px) {
      .pinnwand-block .block-control > a i {
        font-size: 30px; } }
    .pinnwand-block .block-control > a h3 {
      transition: color .3s; }
      @media (max-width: 708px) {
        .pinnwand-block .block-control > a h3 {
          line-height: 40px; } }
    .pinnwand-block .block-control > a:not(.close) {
      padding-right: 40px;
      transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; }
      @media (min-width: 1220px) {
        .pinnwand-block .block-control > a:not(.close) {
          padding: 5px 90px 5px 40px; } }
      .pinnwand-block .block-control > a:not(.close) i:before {
        transform: rotate(-90deg);
        transition: transform .3s;
        display: inline-block; }
      .pinnwand-block .block-control > a:not(.close):hover {
        box-shadow: 0 0 3px #fff inset; }
        .pinnwand-block .block-control > a:not(.close):hover h3 {
          color: #fff; }
      .pinnwand-block .block-control > a:not(.close)[aria-expanded="true"] i:before {
        transform: rotate(0); }
    .pinnwand-block .block-control .close {
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 2;
      transform: translateY(-50%);
      opacity: 1;
      font-size: 20px;
      color: #54575c; }
      @media (min-width: 1220px) {
        .pinnwand-block .block-control .close {
          right: 40px; } }
  @media (min-width: 1220px) {
    .pinnwand-block .block-content .card {
      padding-left: 40px;
      padding-right: 40px; } }

.color-block .img-holder {
  max-height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 1800px) {
    .color-block .img-holder {
      max-height: 60px; } }
  .color-block .img-holder img {
    width: 100%; }

.wanted-break-lg {
  display: none; }
  @media (min-width: 1220px) {
    .wanted-break-lg {
      display: block; } }
  @media (min-width: 1800px) {
    .wanted-break-lg {
      display: none; } }

@media (min-width: 1220px) {
  .systemfinder .tab-pane {
    opacity: 1; } }

.systemfinder .fieldset {
  align-items: stretch;
  padding-bottom: 50px; }
  @media (min-width: 1220px) {
    .systemfinder .fieldset {
      align-items: flex-start; } }
  .systemfinder .fieldset .layout-wrap {
    align-items: stretch;
    min-height: 100%;
    flex-grow: 1; }
    @media (min-width: 1220px) {
      .systemfinder .fieldset .layout-wrap {
        align-items: flex-start; } }
  .systemfinder .fieldset > .tab-content > .active {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1220px) {
      .systemfinder .fieldset > .tab-content > .active {
        display: block; } }

.systemfinder .tab-content {
  flex-grow: 1; }

.systemfinder .deep-title {
  margin-bottom: 10px; }

.systemfinder .med {
  font-size: 15px; }
  @media (min-width: 710px) {
    .systemfinder .med {
      font-size: 30px;
      font-weight: 300; } }

.systemfinder .content-wrap {
  padding: 67px 0 30px; }
  @media (min-width: 710px) {
    .systemfinder .content-wrap {
      padding: 90px 0 65px; } }
  @media (min-width: 1220px) {
    .systemfinder .content-wrap {
      padding: 110px 0 65px; } }

.systemfinder h5 {
  font-size: 20px;
  font-weight: 600; }

.systemfinder h6 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400; }

.systemfinder .custom-checkbox {
  margin-bottom: 10px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%; }
  .systemfinder .custom-checkbox .custom-control-label {
    width: 100%;
    cursor: pointer;
    display: flex;
    color: #fff;
    line-height: 20px; }
    .systemfinder .custom-checkbox .custom-control-label span {
      padding: 6px 40px 6px 10px;
      transition: background-color .3s; }
      .hasActiveBox .systemfinder .custom-checkbox .custom-control-label span {
        background-color: #43464a; }
      .systemfinder .custom-checkbox .custom-control-label span:last-child {
        flex-grow: 1; }
      .systemfinder .custom-checkbox .custom-control-label span:not(:first-child) {
        position: relative; }
        .systemfinder .custom-checkbox .custom-control-label span:not(:first-child):before, .systemfinder .custom-checkbox .custom-control-label span:not(:first-child):after {
          position: absolute;
          height: 1px;
          width: 16px;
          content: "";
          transform: rotate(45deg) translateY(-50%);
          background: #fff;
          left: -23px;
          top: 50%; }
        .systemfinder .custom-checkbox .custom-control-label span:not(:first-child):after {
          transform: rotate(-45deg) translateY(-50%); }
    .systemfinder .custom-checkbox .custom-control-label:before {
      width: 30px;
      height: 30px;
      border-radius: 0;
      top: 0;
      transform: none;
      background: #a2a4a7;
      pointer-events: auto; }
    .systemfinder .custom-checkbox .custom-control-label:after {
      width: auto;
      height: auto;
      top: 15px;
      left: 16px;
      transform: translate(-50%, -50%);
      color: #000;
      background-image: none !important;
      content: "\e915";
      font-family: 'icomoon' !important;
      opacity: 0;
      transition: opacity .3s; }
    .systemfinder .custom-checkbox .custom-control-label:hover span {
      background: #2a2b2e; }
    .systemfinder .custom-checkbox .custom-control-label:focus:before {
      box-shadow: none; }
  .systemfinder .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: #6f7175;
    pointer-events: none;
    cursor: not-allowed; }
    .systemfinder .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      background: #6f7175; }
    .systemfinder .custom-checkbox .custom-control-input:disabled ~ .custom-control-label span {
      background: transparent; }
    .systemfinder .custom-checkbox .custom-control-input:disabled ~ .custom-control-label + .tip i {
      color: #6f7175 !important; }
  .systemfinder .custom-checkbox .custom-control-input:checked ~ .custom-control-label span {
    background: #2a2b2e; }
  .systemfinder .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background: #a2a4a7;
    box-shadow: none; }
  .systemfinder .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    color: #000;
    opacity: 1; }
  .systemfinder .custom-checkbox .custom-control-input:hover ~ .custom-control-label span {
    background: #2a2b2e; }
  .systemfinder .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none; }
  .systemfinder .custom-checkbox .tip {
    right: 0; }

.nav-pills {
  width: 225px;
  margin-right: 30px;
  position: relative; }
  @media (min-width: 980px) {
    .nav-pills:after {
      width: 1px;
      height: 100%;
      position: absolute;
      content: "";
      right: -15px;
      top: 0;
      background: #dadada; } }
  .block-inverse .nav-pills:after {
    background: #fff; }
  .nav-pills .btn {
    padding-top: 0;
    padding-bottom: 0; }
  .nav-pills .amount {
    margin-right: 5px; }
  .nav-pills li:not(.nav-item) {
    opacity: .2;
    font-size: 14px;
    padding: 12px 15px;
    text-transform: uppercase;
    font-weight: 300; }
  .nav-pills .nav-item + li:not(.nav-item) {
    margin-top: 20px; }

.result-review .badge {
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
  margin-right: 15px;
  margin-bottom: 10px; }
  .result-review .badge i {
    margin-right: 15px; }
  .result-review .badge .close {
    margin-left: 15px;
    text-shadow: none;
    color: #fff;
    opacity: 1;
    font-size: inherit; }
    .result-review .badge .close i {
      margin-right: 0; }
  .result-review .badge:hover .close, .result-review .badge:focus .close {
    color: inherit; }

.result-review .submit-holder {
  margin-bottom: 10px; }
  .result-review .submit-holder .btn {
    white-space: nowrap; }
    @media (min-width: 980px) {
      .result-review .submit-holder .btn i {
        margin-right: 0; } }
    @media (min-width: 1220px) {
      .result-review .submit-holder .btn i {
        margin-right: 15px; } }

.modalContainer .modal-dialog {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 709px) {
    .modalContainer .modal-dialog {
      max-width: 680px; } }
  @media (min-width: 979px) {
    .modalContainer .modal-dialog {
      max-width: 950px; } }
  @media (min-width: 1219px) {
    .modalContainer .modal-dialog {
      max-width: 1160px; } }
  @media (min-width: 1799px) {
    .modalContainer .modal-dialog {
      max-width: 1810px; } }

@media (min-width: 980px) {
  .page-actions-aside {
    flex-shrink: 0;
    width: 200px;
    padding-left: 40px; } }

@media (min-width: 1220px) {
  .page-actions-aside {
    padding-top: 30px; } }

.page-actions-aside a {
  width: 70px;
  height: 70px;
  margin: 0 auto 20px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 980px) {
    .page-actions-aside a {
      width: 160px;
      height: 160px;
      font-size: 80px; } }
  .page-actions-aside a i {
    margin: 0; }

.controls-holder {
  background-color: #fff;
  padding: 15px;
  max-height: 150px;
  overflow-y: hidden; }
  @media (min-width: 710px) {
    .controls-holder {
      max-height: 300px;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10; } }
  .controls-holder.show {
    overflow-y: auto; }
  .controls-holder .custom-control-label {
    position: relative; }
    .controls-holder .custom-control-label:before {
      transform: none;
      top: 0; }
    .controls-holder .custom-control-label:after {
      transform: none;
      top: 0; }

.filter-control {
  margin-bottom: 20px;
  position: relative; }
  .filter-control .btn.btn-info:not(:disabled):hover, .filter-control .btn.btn-info:not(:disabled):active {
    background: #008c58; }

#controls-wrap {
  position: relative;
  z-index: 9;
  transition: transform .1s;
  width: 100%; }
  #controls-wrap.fixed-position {
    height: 290px;
    margin-left: -100vw; }

.controls-opener {
  position: absolute;
  z-index: 10;
  right: -100vw;
  top: -60px;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  transform: rotate(45deg) translate(18px, -21px); }
  @media (min-width: 710px) {
    .controls-opener {
      transform: rotate(45deg) translate(57px, -36px);
      right: calc(-150vw + 385px); } }
  @media (min-width: 980px) {
    .controls-opener {
      right: calc(-150vw + 520px); } }
  .controls-opener i {
    transform: rotate(45deg) translateY(10px); }
  .controls-opener button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    background-color: rgba(0, 140, 88, 0.7);
    color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    padding: 0; }
  .fixed-position .controls-opener {
    opacity: 1;
    visibility: visible; }
    .opened-controls .fixed-position .controls-opener + .controls-row [class^="col-"] {
      transform: translateX(100vw); }

.controls-row {
  padding-right: 15px;
  padding-left: 15px; }
  .fixed-position .controls-row [class^="col-"] {
    transform: translateX(0);
    transition: transform .3s; }
    .fixed-position .controls-row [class^="col-"]:nth-of-type(1) {
      transition-delay: 0.15s; }
    .fixed-position .controls-row [class^="col-"]:nth-of-type(2) {
      transition-delay: 0.3s; }
    .fixed-position .controls-row [class^="col-"]:nth-of-type(3) {
      transition-delay: 0.45s; }
    .fixed-position .controls-row [class^="col-"]:nth-of-type(4) {
      transition-delay: 0.6s; }

.opened-controls:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  animation: fade-to-dark .3s;
  opacity: 1;
  visibility: visible;
  transition: opacity .3s, visibility .3s; }

.opened-controls.noOverlay:after {
  opacity: 0;
  visibility: hidden; }

#filtration-count {
  min-height: 20px; }

@keyframes fade-to-dark {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.grid {
  z-index: 3; }
  .grid .grid-item {
    height: 220px;
    padding-bottom: 20px;
    z-index: 2; }
    .grid .grid-item > a {
      width: 100%;
      height: 100%;
      display: block;
      position: relative; }
      .grid .grid-item > a:hover .caption {
        background: #000; }
    .grid .grid-item .caption {
      color: #fff;
      background: rgba(0, 0, 0, 0.7);
      transition: background-color .3s;
      padding: 15px;
      position: absolute;
      top: 100%;
      left: 0;
      right: 20%;
      transform: translateY(-100%);
      display: flex;
      align-items: center;
      min-height: 68px; }

.format-block {
  margin-bottom: 20px; }
  .format-block .top-block {
    margin-bottom: 20px; }
  .format-block .img-holder {
    margin-bottom: 20px; }
    .format-block .img-holder img {
      width: 100%; }

.common-data-list {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0; }
  .common-data-list dt {
    font-weight: normal;
    flex-shrink: 0;
    margin-right: 5px; }
  .common-data-list dd {
    margin: 0; }

.template-img-holder {
  height: 100px; }
  .template-img-holder img {
    width: 100%;
    height: 100%; }

.slick-wrap {
  position: relative;
  width: calc(100% - 80px); }
  @media (min-width: 1800px) {
    .slick-wrap {
      width: calc(100% - 65px); } }

.arrow-separator {
  margin: 20px 0;
  position: relative;
  border-bottom: 2px dashed #dadada; }
  .arrow-separator:after {
    position: absolute;
    content: "";
    border-width: 4px 0 4px 12px;
    border-color: #fff #fff #fff #dadada;
    border-style: solid;
    top: -3px;
    right: 0; }

.format-table {
  overflow: hidden;
  position: relative; }
  .format-table:after {
    content: "";
    right: -5px;
    width: 5px;
    top: 0;
    bottom: 0;
    box-shadow: -5px 0 5px 0 rgba(218, 218, 218, 0.5);
    position: absolute; }
    @media (min-width: 1800px) {
      .format-table:after {
        display: none; } }
  .format-table .slick-control {
    position: absolute;
    z-index: 8;
    left: 0;
    top: 150px;
    transform: translateX(-82px) rotate(90deg);
    text-shadow: 0 0 10px #dadada;
    color: #fff;
    transition: color .3s;
    font-size: 60px; }
    @media (min-width: 1800px) {
      .format-table .slick-control {
        display: none; } }
    .format-table .slick-control:hover {
      color: #008c58; }
    .format-table .slick-control.slick-next {
      transform: translateX(-12px) rotate(-90deg);
      left: auto;
      right: 0; }
  .format-table .slick-track,
  .format-table .slick-list {
    min-width: 100%; }

.data-titles {
  width: 55px;
  position: relative;
  margin-right: 15px;
  overflow: hidden;
  background: #fff;
  border-right: 1px solid #dadada;
  margin-top: 75px;
  height: 100%;
  max-height: 670px; }
  @media (min-width: 1220px) {
    .data-titles {
      margin-top: 85px;
      max-height: 675px; } }
  @media (min-width: 1800px) {
    .data-titles {
      z-index: -2; } }
  .data-titles span {
    padding: 15px 15px 15px 0; }
    .data-titles span:not(:last-child) {
      border-bottom: 1px solid #dadada; }
    .data-titles span:first-child {
      padding-top: 0; }

.lang-en .data-titles {
  width: 60px;
  margin-right: 10px; }

.lang-fr .data-titles {
  width: 80px;
  margin-right: 10px; }

.lang-it .data-titles {
  width: 85px;
  margin-right: 10px; }

.lang-es .data-titles {
  width: 75px;
  margin-right: 10px; }

.column-title {
  padding: 15px 15px 15px;
  margin: 0 7px 0 10px;
  border-bottom: 1px solid #dadada; }

.items-holder,
.scroll-wrap {
  padding: 10px;
  border-left: 1px solid #dadada; }
  @media (min-width: 1800px) {
    .items-holder,
    .scroll-wrap {
      padding-top: 0;
      margin-top: 10px; } }

.item-caption {
  padding: 5px 15px 15px 15px;
  white-space: nowrap; }

@media (min-width: 1800px) {
  .data-content {
    display: flex;
    flex-wrap: nowrap; } }

@media (min-width: 1800px) {
  .data-slide {
    flex-grow: 1; } }

.item-image {
  width: 62px;
  margin: 0 auto; }

.plus-list li {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .plus-list li:not(:last-child) {
    margin-bottom: 5px;
    line-height: 1.6; }
  .plus-list li:before {
    content: "+";
    margin-right: 10px; }

.ref-block {
  width: 100%;
  min-height: 200px;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 22px;
  font-weight: 300; }
  @media (min-width: 710px) {
    .ref-block {
      min-height: 460px;
      font-size: 30px; } }
  @media (min-width: 1220px) {
    .ref-block {
      min-height: 290px;
      font-size: 22px; } }
  @media (min-width: 1800px) {
    .ref-block {
      min-height: 500px;
      font-size: 36px; } }
  .ref-block:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 32%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); }
  .ref-block .text-holder {
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 2;
    padding: 15px 15px 15px 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) -200%, transparent 100%);
    width: 100%;
    min-height: 200px; }

.card-collapsible {
  width: 100%;
  height: 320px; }
  .card-collapsible h4 {
    flex-shrink: 0;
    white-space: nowrap; }
  .card-collapsible .card-content {
    overflow: hidden;
    position: relative; }
  .card-collapsible .text-holder {
    transform: translateY(100%);
    transition: transform .3s;
    flex-grow: 1;
    position: relative;
    z-index: 2;
    max-height: 256px;
    margin-top: 20px; }
  .card-collapsible .img-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 1;
    transition: opacity .3s; }
    .card-collapsible .img-holder img {
      width: 55%; }
      @media (min-width: 1220px) {
        .card-collapsible .img-holder img {
          width: 65%; } }
      @media (min-width: 1800px) {
        .card-collapsible .img-holder img {
          width: 50%; } }
  .card-collapsible .mCustomScrollBox {
    padding-bottom: 30px; }
  .card-collapsible .icon-info-circle {
    position: absolute;
    right: 0;
    bottom: 15px;
    opacity: 1;
    transition: opacity .3s; }

.card-text-up {
  transform: translateY(0%) !important; }

.card-image-hide, .card-info-button {
  transition: opacity .3s;
  opacity: 0 !important; }

.map-holder {
  position: relative; }
  .map-holder a {
    width: 10%;
    position: absolute; }
    .map-holder a.hovered img {
      transform: scale(1); }
    .map-holder a:before {
      width: 7px;
      height: 7px;
      background: #54575c;
      content: "";
      position: absolute; }
  .map-holder img {
    transform: scale(0.75);
    transition: transform .3s; }
  .map-holder [data-placed="br"] {
    bottom: 26%;
    left: 37%; }
    .map-holder [data-placed="br"]:before {
      left: -2%;
      bottom: -2%; }
  .map-holder [data-placed="pa"] {
    bottom: 38.3%;
    left: 30.7%; }
    .map-holder [data-placed="pa"]:before {
      left: -2%;
      bottom: 30%; }
  .map-holder [data-placed="cn"] {
    bottom: 54%;
    left: 75%; }
    .map-holder [data-placed="cn"]:before {
      left: -2%;
      bottom: -2%; }
  .map-holder [data-placed="ar"] {
    bottom: 12%;
    left: 35%; }
    .map-holder [data-placed="ar"]:before {
      left: -2%;
      bottom: 81%; }
  .map-holder [data-placed="ma"] {
    bottom: 42%;
    left: 40.5%; }
    .map-holder [data-placed="ma"]:before {
      left: 60%;
      bottom: 100%; }
  .map-holder [data-placed="us"] {
    bottom: 50%;
    left: 11%; }
    .map-holder [data-placed="us"]:before {
      left: 100%;
      bottom: 50%; }
  .map-holder [data-placed="gb"] {
    bottom: 65%;
    left: 38%; }
    .map-holder [data-placed="gb"]:before {
      left: 100%;
      bottom: -2%; }
  .map-holder [data-placed="de"] {
    bottom: 56%;
    left: 51%; }
    .map-holder [data-placed="de"]:before {
      left: -2%;
      bottom: 60%; }
  .map-holder [data-placed="au"] {
    bottom: 11%;
    left: 72.7%; }
    .map-holder [data-placed="au"]:before {
      left: 94%;
      bottom: 86%; }
  .map-holder .map-link.cn2 {
    bottom: 38%;
    left: 72%; }
    .map-holder .map-link.cn2:before {
      left: 17%;
      bottom: 100%; }
  .map-holder .map-link.us2 {
    bottom: 51%;
    left: 32%; }
    .map-holder .map-link.us2:before {
      left: 0%;
      bottom: 76%; }
  .map-holder [data-placed="zaf"] {
    bottom: 14%;
    left: 54.7%; }
    .map-holder [data-placed="zaf"]:before {
      left: 0%;
      bottom: 102%; }
  .map-holder [data-placed="egy"] {
    bottom: 42%;
    left: 56.5%; }
    .map-holder [data-placed="egy"]:before {
      left: -13%;
      bottom: 68%; }
  .map-holder [data-placed="can"] {
    bottom: 64%;
    left: 23%; }
    .map-holder [data-placed="can"]:before {
      left: 83%;
      bottom: -16%; }
  .map-holder [data-placed="fin"] {
    bottom: 70%;
    left: 55%; }
    .map-holder [data-placed="fin"]:before {
      left: -10%;
      bottom: 3%; }
  .map-holder [data-placed="rus"] {
    bottom: 55%;
    left: 61%; }
    .map-holder [data-placed="rus"]:before {
      left: 1%;
      bottom: 115%; }

.extended-download-block {
  border: 1px solid #dadada;
  margin-bottom: 20px; }
  .extended-download-block .block-title {
    padding: 20px;
    background: #dadada; }
    .extended-download-block .block-title i {
      font-size: 90px; }
      @media (min-width: 1220px) {
        .extended-download-block .block-title i {
          font-size: 190px; } }
    .extended-download-block .block-title .strong {
      font-size: 125%; }
  .extended-download-block .block-content {
    padding: 20px; }
  .extended-download-block .select-wrap {
    min-width: 200px;
    margin-right: 5px; }
  .extended-download-block dl:not(:last-child) {
    margin-bottom: 30px; }
    @media (min-width: 980px) {
      .extended-download-block dl:not(:last-child) {
        margin-bottom: 0; } }
  @media (min-width: 980px) {
    .extended-download-block dl {
      width: 100%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  @media (min-width: 980px) {
    .extended-download-block dd {
      display: flex;
      align-items: center; } }
  .extended-download-block .fake-cell {
    width: 25%;
    display: inline-block;
    vertical-align: top; }
    @media (min-width: 980px) {
      .extended-download-block .fake-cell {
        width: 90px;
        text-align: right; } }

.link-next {
  color: #008c58;
  padding: 15px 20px 15px 15px;
  transition: .3s all;
  position: relative;
  line-height: .7;
  border: none;
  background: transparent;
  cursor: pointer; }
  .link-next i {
    padding-left: 10px; }
  .link-next:hover {
    background: #008c58;
    color: #fff; }

.font-weight-bold--600 {
  font-weight: 600; }

.sd-block .card {
  border: none;
  border-radius: inherit; }

#catalogs-form .card {
  border: none;
  position: relative;
  background-color: transparent; }
  #catalogs-form .card h6 {
    padding-right: 0px;
    margin-bottom: 5px; }

#catalogs-form .card .lang-options {
  padding-top: 1rem;
  padding-bottom: .7rem;
  justify-content: flex-start;
  padding-right: 30px; }
  #catalogs-form .card .lang-options a {
    color: #ced4da; }
    #catalogs-form .card .lang-options a:hover {
      color: #1a1a1b; }
  #catalogs-form .card .lang-options li:first-child {
    padding-left: 0; }
  #catalogs-form .card .lang-options li.active a {
    color: #1a1a1b; }

#catalogs-form .card .lang-options li:not(:first-child):before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ced4da;
  content: "";
  left: 0; }

.block-inverse #catalogs-form .sd-block .card .list-unstyled li a {
  color: #dadada; }

.block-inverse #catalogs-form .sd-block .card .list-unstyled li.active a,
.block-inverse #catalogs-form .sd-block .card .list-unstyled li a:hover,
.block-inverse #catalogs-form .sd-block .card .list-unstyled li a:focus {
  color: #fff; }

.block-inverse #catalogs-form .select-wrap {
  background-color: transparent; }

.block-inverse #catalogs-form .form-control,
.block-inverse #catalogs-form .form-control:focus,
.block-inverse #catalogs-form .form-control:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff; }

.block-inverse #catalogs-form .custom-control-label,
.block-inverse #catalogs-form .select-wrap i,
.block-inverse #catalogs-form a,
.block-inverse #catalogs-form a:visited {
  color: #fff; }

.block-inverse #catalogs-form button.link-next {
  color: #fff;
  border: 1px solid #fff; }

.block-inverse #catalogs-form .custom-select option {
  color: #6f6f6f;
  background-color: #fff; }

.block-inverse #catalogs-form .custom-checkbox .custom-control-label:before {
  color: #fff;
  background-color: transparent !important;
  border: 1px solid #fff; }

@media (min-width: 709px) {
  .custom-table {
    font-size: 1rem; } }

@media (min-width: 709px) {
  .custom-table__head {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 2px; } }

.custom-table__head b {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; }
  @media (min-width: 709px) {
    .custom-table__head b {
      font-size: 1rem; } }

.custom-table__head-titles {
  margin-bottom: 25px; }

.custom-table .row {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 709px) {
    .custom-table .row {
      border-bottom: 1px solid #e8e8e8; } }
  .custom-table .row.custom-table__head-titles {
    border-bottom: none;
    margin-bottom: 15px; }

.custom-table [class^="col"] {
  padding: 0; }
  @media (min-width: 709px) {
    .custom-table [class^="col"] {
      padding-right: 10px;
      padding-left: 10px; } }

.custom-table .download-element {
  height: 40px; }

.file-logo {
  margin-right: 20px;
  overflow: hidden; }
  @media (min-width: 709px) {
    .file-logo {
      margin-right: 30px; } }

.sidebar-newsletter {
  margin-top: 15px;
  max-width: 350px;
  overflow: hidden; }
  @media (min-width: 710px) {
    .sidebar-newsletter {
      max-width: 100%; } }
  @media (min-width: 1219px) {
    .sidebar-newsletter {
      margin-top: 0;
      position: relative;
      padding-left: 0; } }
  .sidebar-newsletter div {
    column-count: 1; }
    @media (min-width: 710px) {
      .sidebar-newsletter div {
        column-count: 2; } }
    @media (min-width: 1220px) {
      .sidebar-newsletter div {
        column-count: 1; } }
  .sidebar-newsletter .hidden-element {
    display: none; }
    @media (min-width: 710px) {
      .sidebar-newsletter .hidden-element {
        display: block; } }
    @media (min-width: 1220px) {
      .sidebar-newsletter .hidden-element {
        display: none; } }

.sidebar-newsletter__row {
  text-align: center;
  flex-wrap: nowrap;
  padding-left: 0px; }

.sidebar-newsletter__item {
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  width: 40px;
  max-width: none;
  flex: 0 0 40px; }
  .sidebar-newsletter__item a {
    color: #54575c;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px; }
    .sidebar-newsletter__item a:hover, .sidebar-newsletter__item a.active {
      color: #fff;
      background-color: #54575c; }

.sidebar-newsletter__item--year {
  color: #54575c;
  font-weight: 600;
  margin-right: 15px; }

.sd-block {
  margin-top: 1rem; }
  .sd-block h3 {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    word-break: break-word; }
    .sd-block h3 i {
      color: #54575c;
      margin-right: 25px;
      margin-left: 6px;
      font-size: 40px; }
      @media (min-width: 709px) {
        .sd-block h3 i {
          font-size: 60px;
          margin-right: 45px; } }
    .sd-block h3 img {
      margin-right: 45px; }
    .sd-block h3:first-child {
      margin-top: 20px; }
  .sd-block .sidebar:after {
    display: none; }
  .sd-block .sidebar {
    font-weight: 400;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px; }
    @media (min-width: 709px) {
      .sd-block .sidebar {
        margin-bottom: 20px; } }
    .sd-block .sidebar .sidebar-opener i {
      padding-left: 5px !important; }
    .sd-block .sidebar span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1 auto; }
    .sd-block .sidebar .vertical-spacer {
      padding: 0 10px;
      overflow: initial;
      flex: none; }
  .sd-block .download-dropdown {
    padding: 0; }
    .sd-block .download-dropdown .sidebar-nav-item {
      margin-left: 0;
      margin-right: 0; }
      .sd-block .download-dropdown .sidebar-nav-item b {
        min-width: 131px; }
  .sd-block .card {
    position: relative; }
    .sd-block .card .list-unstyled {
      min-height: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 500;
      color: #54575c;
      cursor: pointer;
      line-height: 26px; }
      .sd-block .card .list-unstyled .active {
        font-weight: 600; }
      .sd-block .card .list-unstyled a {
        text-transform: uppercase; }
        .sd-block .card .list-unstyled a:focus, .sd-block .card .list-unstyled a:hover {
          color: #54575c; }
          .sd-block .card .list-unstyled a:focusafter, .sd-block .card .list-unstyled a:hoverafter {
            color: #dadada; }
        .sd-block .card .list-unstyled a:after {
          color: #dadada; }
          .sd-block .card .list-unstyled a:after:focus, .sd-block .card .list-unstyled a:after:hover {
            color: #dadada; }
      .sd-block .card .list-unstyled span {
        text-transform: uppercase; }
      .sd-block .card .list-unstyled li a {
        color: #dadada; }
      .sd-block .card .list-unstyled li.active a {
        color: #54575c; }
        .sd-block .card .list-unstyled li.active a:after {
          color: #dadada; }
  .sd-block .multi-download-wrapper {
    min-height: 40px;
    padding: 0;
    position: relative; }
    .sd-block .multi-download-wrapper .download-btn {
      transition: .3s all;
      line-height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      padding: 0;
      position: absolute;
      right: 0;
      color: #1a1a1b;
      height: 40px;
      width: 40px;
      text-align: center;
      top: -15px;
      bottom: auto; }
      .sd-block .multi-download-wrapper .download-btn:hover {
        background: #54575c;
        color: #fff; }
    .sd-block .multi-download-wrapper ul li a:after {
      content: " |";
      font-weight: normal; }
    .sd-block .multi-download-wrapper ul li:last-child a:after {
      display: none; }
  .sd-block .download-list-wrapper {
    position: relative;
    min-height: 40px;
    padding: 0; }
    .sd-block .download-list-wrapper .download-btn {
      transition: .3s all;
      line-height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      padding: 0;
      right: calc(50% - 20px);
      color: #1a1a1b;
      height: 40px;
      width: 40px;
      text-align: center;
      top: 0;
      position: absolute; }
      .sd-block .download-list-wrapper .download-btn:hover {
        background: #54575c;
        color: #fff; }
  .sd-block .list-unstyled .card h6 {
    margin: 5px 0;
    width: calc(100% - 50px); }
    @media (min-width: 710px) {
      .sd-block .list-unstyled .card h6 {
        margin-top: 5px;
        margin-bottom: 5px; } }
    @media (min-width: 1220px) {
      .sd-block .list-unstyled .card h6 {
        margin-top: 15px;
        margin-bottom: 5px; } }
  .sd-block .list-unstyled .card .card-img {
    background-color: #e8e8e8;
    height: 100%; }
  .sd-block .list-unstyled .card .card-icon {
    position: relative;
    height: 100%; }
    .sd-block .list-unstyled .card .card-icon:before {
      content: "";
      display: block;
      padding-top: 100%; }
    .sd-block .list-unstyled .card .card-icon i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(15);
      color: #ffffff;
      left: calc(50% - 10px); }
      @media (min-width: 1220px) {
        .sd-block .list-unstyled .card .card-icon i {
          transform: translateY(-50%) scale(10); } }

.sidebar--index {
  z-index: 9; }

@media (min-width: 560px) {
  .form-group.mb-sm-5 {
    margin-bottom: 30px !important; } }

@media (min-width: 710px) {
  .form-group.mb-sm-5 {
    margin-bottom: 40px !important; } }

.file-logo {
  width: 40px;
  height: 40px;
  display: inline-block; }
  .file-logo img {
    width: 100%; }

.sd-bg-holder > div {
  flex: 1 1 auto; }

.sd-bg-holder div {
  height: 100%; }

#block-cms-blocks-contact-image div {
  background-size: cover; }

.no-img {
  min-height: 205px;
  height: 100%;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  .no-img i {
    font-size: 60px;
    color: #fff; }

.sd-block {
  /*.sidebar:not(.sidebar-right) nav{
    @include media-breakpoint-up('md'){
      position: relative;
    }
  }*/ }
  @media (min-width: 979px) {
    .sd-block .sidebar:not(.sidebar-right) .collapse {
      opacity: 0;
      height: 0; } }
  @media (min-width: 979px) {
    .sd-block .sidebar:not(.sidebar-right) .collapse.show {
      opacity: 1;
      height: auto; } }
  .sd-block .sidebar:not(.sidebar-right) nav.show {
    position: relative !important;
    margin-top: 0 !important;
    padding-top: 0;
    padding-bottom: 0; }
    .sd-block .sidebar:not(.sidebar-right) nav.show .row {
      padding: 0; }

.icon2-downloads {
  display: inline-block;
  font-size: 20px;
  line-height: unset; }

@media (max-width: 708px) {
  #bim-download-categories.custom-table, #cad-download-categories.custom-table, .bim-download-categories.custom-table, .cad-download-categories.custom-table {
    display: none; } }

.mobile-list {
  width: 100%;
  flex-wrap: wrap;
  font-size: 15px; }

.custom-table__head .mobile-list b {
  font-size: 15px; }

@media (max-width: 708px) {
  .custom-table [class^="col"] {
    margin-top: 10px;
    margin-bottom: 10px; } }

#data-protection h2 {
  font-size: 125% !important;
  font-weight: 600 !important;
  line-height: 1.6 !important; }

#data-protection h3 {
  font-size: 100% !important;
  font-weight: 600 !important;
  line-height: 1.6 !important; }

.error-content-background-height {
  height: 100vh; }

.container.form-container {
  max-width: 1160px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .container.form-container h1 {
    line-height: 27px;
    font-weight: 300; }
    @media (min-width: 710px) {
      .container.form-container h1 {
        font-size: 45px;
        line-height: 54px; } }
    @media (min-width: 1220px) {
      .container.form-container h1 {
        font-size: 50px;
        line-height: 60px; } }
    @media (min-width: 1800px) {
      .container.form-container h1 {
        font-size: 70px;
        line-height: 84px; } }
  .container.form-container h3 {
    line-height: 35px; }
    @media (min-width: 710px) {
      .container.form-container h3 {
        font-size: 30px;
        line-height: 48px; } }
    @media (min-width: 1220px) {
      .container.form-container h3 {
        font-size: 36px;
        line-height: 57px; } }
  .container.form-container b {
    font-weight: 700; }
  @media (min-width: 710px) {
    .container.form-container .mb-sm-4 {
      margin-bottom: 40px !important; } }
  @media (min-width: 710px) {
    .container.form-container .mt-sm-4 {
      margin-top: 40px !important; } }
  .container.form-container .mb-4 {
    margin-bottom: 40px !important; }
  .container.form-container .mt-4 {
    margin-top: 40px !important; }
  .container.form-container .mb-5 {
    margin-bottom: 50px !important; }
  @media (min-width: 710px) {
    .container.form-container .mb-sm-5 {
      margin-bottom: 50px !important; } }
  .container.form-container .mt-5 {
    margin-top: 50px !important; }
  @media (min-width: 710px) {
    .container.form-container .mt-sm-5 {
      margin-top: 50px !important; } }
  .container.form-container .mb-6 {
    margin-bottom: 60px !important; }
  @media (min-width: 710px) {
    .container.form-container .mb-sm-6 {
      margin-bottom: 60px !important; } }
  .container.form-container .mt-6 {
    margin-top: 60px !important; }
  @media (min-width: 710px) {
    .container.form-container .mt-sm-6 {
      margin-top: 60px !important; } }
  @media (min-width: 1220px) {
    .container.form-container .mt-lg-8 {
      margin-top: 80px !important; } }
  .container.form-container .big-font {
    font-size: 22px;
    font-weight: 300;
    line-height: 32px; }
    @media (min-width: 710px) {
      .container.form-container .big-font {
        font-size: 30px;
        line-height: 48px; } }
    @media (min-width: 1220px) {
      .container.form-container .big-font {
        font-size: 36px;
        line-height: 45px; } }
  .container.form-container .small-font {
    font-size: 20px;
    line-height: 32px; }
  .container.form-container .logo-left {
    max-width: 150px; }
  .container.form-container .logo-right {
    max-width: 460px;
    width: 100%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    @media (min-width: 710px) {
      .container.form-container .logo-right {
        float: right; } }
  .container.form-container .question {
    font-size: 16px;
    line-height: 26px; }
    @media (min-width: 710px) {
      .container.form-container .question {
        font-size: 20px;
        line-height: 32px; } }
    @media (min-width: 1220px) {
      .container.form-container .question {
        font-size: 26px;
        line-height: 42px; } }
    @media (min-width: 1800px) {
      .container.form-container .question {
        font-size: 30px;
        line-height: 48px; } }
  .container.form-container .list-unstyled .card-img img {
    border: 1px solid #dadada; }
  .container.form-container .youtube-container {
    position: relative;
    padding-bottom: 50%;
    padding-top: 25px;
    height: 0; }
    @media (min-width: 710px) {
      .container.form-container .youtube-container {
        position: relative;
        padding-bottom: 53%;
        padding-top: 25px;
        height: 0; } }
    @media (min-width: 979px) {
      .container.form-container .youtube-container {
        position: relative;
        padding-bottom: inherit;
        padding-top: 15px;
        height: inherit; } }
    .container.form-container .youtube-container .youtube-frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 710px) {
        .container.form-container .youtube-container .youtube-frame {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding-left: 15px;
          padding-right: 15px; } }
      @media (min-width: 979px) {
        .container.form-container .youtube-container .youtube-frame {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 310px; } }
  .container.form-container .page-content .project-teaser-wrapper .cmf-block:first-child {
    width: 100%; }
    @media (min-width: 710px) {
      .container.form-container .page-content .project-teaser-wrapper .cmf-block:first-child {
        width: 50%; } }

.cr_form .cr_body {
  font-family: "Helvetica Neue LT", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #1a1a1b; }
  @media (min-width: 710px) {
    .cr_form .cr_body {
      font-size: 15px; } }

.cr_form .cr_ipe input {
  display: block;
  padding: 0 0.75rem !important;
  line-height: 38px;
  background: none !important;
  border: 1px solid #dadada;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: 40px; }

.cr_form .cr_ipe label {
  margin: 0;
  font-weight: 400;
  text-align: left;
  background-color: #fff;
  line-height: 38px; }

.cr_form .cr_ipe.submit_container {
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  background-color: #fff; }

.cr_form .cr_ipe .custom-checkbox {
  font-size: 16px !important;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 7px;
  margin-bottom: 7px; }

.cr_form .cr_ipe .custom-select {
  box-shadow: none; }

.cr_form .custom-checkbox .custom-control-label {
  line-height: 26px;
  font-weight: 500; }
  .cr_form .custom-checkbox .custom-control-label:before {
    top: 0; }
  .cr_form .custom-checkbox .custom-control-label:after {
    width: 26px;
    height: 26px;
    z-index: 1;
    top: 0;
    transform: scale(1);
    font-size: 33px;
    left: -3px; }

.cr_form .inactive {
  display: none; }

$white: #fff;
$black: #000;
$green: #008c58;
$sign-red: #ff0000;

$gray-100: #f7f7f7;
$gray-600: #54575c;

$prod1: #5f8890;
$prod2: #6b7983;
$prod3: #83afc3;
$prod4: #5b8083;
$prod5: #668997;

$color-values: $white, $black, $prod1, $prod2, $prod3, $prod4, $prod5, rgba(0,0,0,0), $green, $gray-100, $gray-600, $gray-600;
$color-names: 'white', 'black', 'prod1', 'prod2', 'prod3', 'prod4', 'prod5',  transparent, 'success', 'gray100', 'gray600', 'info';
$color-products-values: $prod1,$prod3,$prod2,$prod5,$prod4;
$color-products-names: 'keratwin','kerashape','keraion','kerajoin','kerayou';

$font-family-serif: Serif;
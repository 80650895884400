.map-holder {
  position: relative;

  a {
    width: 10%;
    position: absolute;

    &.hovered {

      img {
        transform: scale(1);
      }
    }

    &:before {
      @include size(7px);
      background: $gray-600;
      content: "";
      position: absolute;
    }
  }

  img {
    transform: scale(.75);
    transition: transform .3s;
  }

  [data-placed="br"] {
    bottom: 26%;
    left: 37%;

    &:before {
      left: -2%;
      bottom: -2%;
    }
  }

  [data-placed="pa"] {
    bottom: 38.3%;
    left: 30.7%;

    &:before {
      left: -2%;
      bottom: 30%;
    }
  }

  [data-placed="cn"] {
    bottom: 54%;
    left: 75%;

    &:before {
      left: -2%;
      bottom: -2%;
    }
  }

  [data-placed="ar"] {
    bottom: 12%;
    left: 35%;

    &:before {
      left: -2%;
      bottom: 81%;
    }
  }

  [data-placed="ma"] {
    bottom: 42%;
    left: 40.5%;

    &:before {
      left: 60%;
      bottom: 100%;
    }
  }

  [data-placed="us"] {
    bottom: 50%;
    left: 11%;

    &:before {
      left: 100%;
      bottom: 50%;
    }
  }

  [data-placed="gb"] {
    bottom: 65%;
    left: 38%;

    &:before {
      left: 100%;
      bottom: -2%;
    }
  }

  [data-placed="de"] {
    bottom: 56%;
    left: 51%;

    &:before {
      left: -2%;
      bottom: 60%;
    }
  }

  [data-placed="au"] {
    bottom: 11%;
    left: 72.7%;

    &:before {
      left: 94%;
      bottom: 86%;
    }
  }
  .map-link.cn2 {
    bottom: 38%;
    left: 72%;

    &:before {
      left: 17%;
      bottom: 100%;
    }
  }
  .map-link.us2 {
    bottom: 51%;
    left: 32%;

    &:before {
      left: 0%;
      bottom: 76%;
    }
  }
  [data-placed="zaf"] {
    bottom: 14%;
    left: 54.7%;

    &:before {
      left: 0%;
      bottom: 102%;
    }
  }
  [data-placed="egy"] {
    bottom: 42%;
    left: 56.5%;

    &:before {
      left: -13%;
      bottom: 68%;
    }
  }
  [data-placed="can"] {
    bottom: 64%;
    left: 23%;

    &:before {
      left: 83%;
      bottom: -16%;
    }
  }
  [data-placed="fin"] {
    bottom: 70%;
    left: 55%;

    &:before {
      left: -10%;
      bottom: 3%;
    }
  }
  [data-placed="rus"] {
    bottom: 55%;
    left: 61%;

    &:before {
      left: 1%;
      bottom: 115%;
    }
  }
}
.tip {
  position: absolute;
  bottom: 80px;
  right: 20px;
  padding: 5px;
  z-index: 9;
  overflow: hidden;
  margin-left: 20px;

  i {
    transform: translateY(-50%);
    transition: transform .3s linear .6s;
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 20px;
  }

  .tip-content {
    color: $white;
    padding: 10px 40px 10px 10px;
    background: transparent;
    max-height: 40px;
    width: 40px;
    position: relative;
    transition: max-height .3s linear .6s, width .3s linear .6s;

    p {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      z-index: -1;
      bottom: 50px;
      transform: translateX(-40px);
      background: transparent;
      transition: background-color .3s linear .3s, transform .3s linear .3s;

      @include media(">lg") {
        bottom: 0;
      }
    }

    &:after {
      content: "";
      height: 150px;
      max-height: 1px;
      width: 100%;
      display: block;
      transition: max-height .3s linear .6s;

      @include media(">lg") {
        display: none;
      }
    }

    >p {
      opacity: 0;
      transition: opacity .3s linear 0s;
    }
  }

  &:hover,
  &:focus {
    outline: none;

    i {
      transform: translateY(calc(-50% - 25px));
      transition-delay: 0s;

      @include media(">lg") {
        transform: translateY(-50%);
      }
    }

    .tip-content {
      visibility: visible;
      width: 100%;
      max-height: 350px;
      transition-delay: 0s;
      padding-right: 60px;

      @include media(">sm") {
        width: 530px;
      }

      @include media(">lg") {
        width: 530px;
      }

      @include media(">xlg") {
        width: 700px;
      }

      &:before {
        background-color: $gray-600;
        transform: translateX(-100%);
        transition-delay: .3s;
      }

      &:after {
        max-height: 50px;
        transition-delay: 0s;
      }

      >p {
        opacity: 1;
        transition-delay: .6s;
      }
    }
  }
}
.w-lg-50 {

  @include media(">lg") {
    width: calc(50% - 3px);
    vertical-align: top;
  }
}

ngb-tabset {

  .nav-item {

    .nav-link {
      display: block;
      padding: 0;

      div {
        padding-left: 15px;
        padding-right: 15px;
        transition: color .3s, background-color .3s;
      }

      &.disabled {

        div {
          opacity: .2;
          font-size: 14px;
          padding: 12px 15px;
          text-transform: uppercase;
          font-weight: 300;
        }
      }

      &.active {

        div {
          color: #54575c;
          background: #fff;
        }
      }
    }
  }
}

.line-left {

  &:after {
    width: 1px;
    height: auto;
    content: "";
    background-color: #fff;
    top: 30px;
    bottom: 30px;
    right: 7px;
    position: absolute;
  }
}

app-boxes-sub-group {

  .order-10 {

    @include media(">xlg") {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.line-right {

  &:after {
    width: 1px;
    height: auto;
    content: "";
    background-color: #fff;
    top: 30px;
    bottom: 30px;
    left: -8px;
    position: absolute;
  }
}

.checkbox-set {
  min-height: 100%;

  @include media(">lg") {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .tip {
    top: 50%;
    right: -65px;
    overflow: visible;
    max-height: none;
    transform: translateY(-50%);

    &:hover,
    &:focus {

      i {
        transform: translateY(-50%);
      }

      .tip-content {
        box-shadow: 0 0 3px $white;
        visibility: visible;
        opacity: 1;
      }
    }

    i {
      z-index: 2;
      font-size: 14px;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .tip-content {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      transition: box-shadow .3s linear .3s, visibility .3s, opacity .3s;
      width: 230px;
      height: auto;
      max-height: none;
      visibility: hidden;
      opacity: 0;

      &:before {
        height: 100%;
      }

      &:after {
        display: none;
      }
    }
  }

  .title-holder {
    margin-bottom: 20px;

    i {
      font-size: 40px;
      margin-right: 10px;
    }

    .counter {
      margin-right: 10px;
    }
  }

  .tab-content {

    @include media(">lg") {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .tab-pane {

    @include media(">lg") {
      width: 50%;
      padding-right: 20px;
      flex-shrink: 0;
    }
  }

  .subtitle {

    @include media(">lg") {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &.arrowed {

    @include media(">lg") {
      padding-top: 0;
    }

    .custom-control-label {

      span {
        display: flex;
        justify-content: space-between;

        &:after {
          font-family: icomoon !important;
          content: "\e910";
          transform: translateX(25px);
        }
      }
    }
  }

  &.pr-lg-3 {

    @include media(">lg") {
      position: relative;
    }

    &:after {

      @include media(">lg") {
        @include size(1px, auto);
        content: "";
        background-color: $white;
        top: 20px;
        bottom: 20px;
        right: 5px;
        position: absolute;
      }
    }

    + .checkbox-set {

      @include media(">lg") {
        position: relative;
      }

      &:after {

        @include media(">lg") {
          @include size(1px, auto);
          content: "";
          background-color: $white;
          top: 20px;
          bottom: 20px;
          left: -10px;
          position: absolute;
        }
      }
    }
  }
}

.checkbox-set#Unterkonstruktionen {

  @include media(">lg") {
    position: relative;
  }

  &:after {

    @include media(">lg") {
      @include size(1px, auto);
      content: "";
      background-color: $white;
      top: 20px;
      bottom: 20px;
      left: -10px;
      position: absolute;
    }
  }
}


.fieldset {

  >.tab-content {

    >.tab-pane {

      &:nth-of-type(4) {

        @include media(">lg") {
          width: 100%;
        }

        .subtitle {

          @include media(">lg") {
            width: 75%;
            order: 1;
            flex-shrink: 0;
          }

          &:nth-child(1) {

            @include media(">lg") {
              width: 25%;
            }
          }
        }

        .tab-pane {

          @include media(">lg") {
            width: 37.5%;
            order: 2;
          }

          @include media(">xlg") {
            width: 25%;
          }

          &:nth-of-type(2) {

            @include media(">lg") {
              width: 25%;
            }
          }

          &:nth-of-type(5) {

            @include media(">lg") {
              order: 10;
              padding-right: 0;
            }

            @include media(">xlg") {
              transform: translateX(100%);
              margin-top: -200px;
            }
          }

          &:nth-of-type(7) {

            @include media(">lg") {
              order: 9;
              margin-left: 25%;
            }

            @include media(">xlg") {
              margin-left: 0;
            }
          }

          &:nth-of-type(6) {

            @include media(">lg") {
              padding-right: 0;
            }
          }
        }
      }

      &:nth-of-type(5) {

        .custom-checkbox {

          @include media(">xlg") {
            width: 50%;
          }
        }
      }
    }
  }
}

.info-group {
  position: relative;
  margin-right: 60px;

  &:after {
    @include size(20px, 100%);
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    border-style: solid;
    border-color: $white;
    border-width: 1px 1px 1px 0;
  }
}

.order-checkbox {
  min-height: 40px;
  padding: 0;

  input {
    margin-top: 7px;
  }
  .custom-control-label {
    min-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  a {
    transition: .3s all;
    line-height: 40px;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 0;
    color: #1a1a1b;
    height: 40px;
    width: 40px;
    text-align: center;

    &:hover {
      background: #008c58;
      color: #fff;
    }

    i {
      display: inline-block;
      font-size: 20px;
      line-height: unset;
    }
  }
  &.custom-checkbox {
    .custom-control-label {
      &:before {
        background: $gray-100 !important;
      }
      &:after {
        background: transparent;
      }
    }
  }
  .custom-control-input:checked + .custom-control-label {
    &:before {
      background: $gray-100 !important;
    }
  }
}
.ref-block {
  width: 100%;
  min-height: 200px;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 22px;
  font-weight: 300;

  @include media(">sm") {
    min-height: 460px;
    font-size: 30px;
  }

  @include media(">lg") {
    min-height: 290px;
    font-size: 22px;
  }

  @include media(">xlg") {
    min-height: 500px;
    font-size: 36px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 32%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
  }

  .text-holder {
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 2;
    padding: 15px 15px 15px 20px;
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) -200%, transparent 100%);
    width: 100%;
    min-height: 200px;
  }
}
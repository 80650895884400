.custom-control-input:not(:checked) ~ .custom-control-label:hover:after{
  opacity: .1;
}

.card{
  .custom-control-label{
    padding-top: .4em;
  }
}

.custom-radio,
.custom-checkbox {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 0;

  .custom-control-label {
    font-weight: 500;
    color: $gray-600;
    cursor: pointer;
    padding-left: 40px;
    line-height: 26px;
    &:before {
      @include size(26px);
      background: $gray-300;
      top: 7px;
      z-index: 1;
    }
    &:after {
      @include size(12px);
      top: 50%;
      transform: translateY(-50%);
      left: 7px;
      z-index: 2;
      background: $gray-300;
      background-image: none !important;
      border-radius: 50%;
      opacity: 0;
      transition: opacity .3s;
    }

  }

  .custom-control-input {

    &:checked {

      + .custom-control-label {

        &:before {
          background: $gray-300;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &:active {

      ~ .custom-control-label {

        &:before {
          background-color: $gray-300;
        }
      }
    }

    &:focus {

      + .custom-control-label {

        &:before {
          outline: none;
          box-shadow: none;
        }
      }
    }

    &:disabled {

      + .custom-control-label {
        color: $gray-300;
        cursor: not-allowed;

        &:before {
          background: $gray-300;
        }
      }
    }
  }
}

.custom-radio{
  .custom-control-input {

    &:checked {

      + .custom-control-label {

        &:after {
          opacity: 1;
          background: #54575c;
          transform: none;
          top: 14px;
        }
      }
    }
  }
}

.custom-checkbox {

  .custom-control-input {

    &:indeterminate {

      + .custom-control-label {

        &:before {
          background: $gray-300;
        }
      }

      &:disabled {

        + .custom-control-label {

          &:before {
            background: $gray-300;
          }
        }
      }
    }
  }

  .custom-control-label {
    &:before {
      border-radius: 0;
    }
    &:after {
      @include size(auto);
      top: 17%;
      left: 6px;
      background-image: none !important;
      background-color: transparent;
      content: "\e916";
      font-family: 'icomoon' !important;
      font-weight: bold;
      transform: scale(1.9);
    }
  }
}

.form-control {

  input {
    font-size: 14px;
    transition: border-top-color .3s;
  }
}

.custom-select {
  font-weight: 500;
  position: relative;
  z-index: 2;

  option {
    font-weight: 500;
  }

  &:disabled {
    z-index: 0;
    cursor: not-allowed;

    ~ i {
      color: #dadada;
    }
  }
}

.select-wrap {
  background: $white;
  position: relative;
  z-index: 0;
  i {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%) scale(2);
    color: #1a1a1b;
    font-size: 20px;
    right: 25px;
  }
}

.newsletter-content {
  .select-wrap {
    i {

    }
  }
}


.form-control {
  color: $gray-500;
  min-height: 40px;
  &:focus{
    color: $gray-900;
  }
}

select.form-control:hover {
  color: $gray-900;
}

.flash-notice {
  border: none;
  padding: 15px;
  background: $green;
  color: $white;
  margin-bottom: 30px;

  p {
    margin: 0;
  }
}

.flash-error {
  border: none;
  padding: 15px;
  background: $sign-red;
  color: $white;
  margin-bottom: 30px;

  p {
    margin: 0;
  }
}

.lang-es {
  .custom-radio {
    .custom-control-label {
      &:after {
        top: 0;
        transform: translateY(120%);
      }
    }
  }
}
.sidebar {
  position: relative;
  z-index: 5;
  font-size: 16px;
  margin-bottom: 20px;

  @include media(">md") {
    width: 225px;
    margin-right: 30px;
    flex-shrink: 0;
    font-weight: 500;
  }

  @include media(">lg") {
    margin-right: 55px;
  }

  &:after {

    @include media(">md") {
      @include size(1px, 100%);
      position: absolute;
      content: "";
      right: -15px;
      top: 0;
      background: #dadada;
    }

    .block-inverse & {
      background: $white;
    }
  }

  ul {
    margin: 0;
  }

  .subnav {

    @include media(">md") {
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s;
    }

    a {
      padding-left: 15px;
      font-size: 14px;
      margin-left: 10px;
    }

    .sub-active {

      a {
        pointer-events: none;
        color: #fff;
        background: #54575c;
      }
    }
  }

  .sidebar-nav-item {
    margin-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;

    @include media(">md") {

      margin-left: 0;
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {

      .subnav {

        @include media(">md") {
          max-height: 300px;
        }
      }

      &> a.colored-success {
        color: #ffffff !important;
        background: #008c58;
      }
    }
  }
  .sidebar-nav-item.child-item {
    margin: 10px 0;
  }

  .collapse {
    &.show.in {
      .sidebar-nav-item {
        &.child-item {
          margin: 10px 0 10px 20px;
        }
      }
    }
  }

  a {
    display: flex;
    padding: 10px 15px;
    transition: color .3s, background-color .3s;
    color: $gray-600;
    background: $white;
    align-items: center;


    > div {
      width: 100%;
    }

    &:hover,
    &:focus {
      color: $white;
      background: $gray-600;
    }

    i {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      text-align: center;
      font-size: 20px;
    }

    &.colored-success {

      &:hover,
      &:focus {
        color: $white !important;
        background: $success;
      }
    }
  }

  .active {

    &:not(.sidebar-nav-item) {

      >a {
        color: $white;
        background: $gray-600;

        i {

          &.icon-angle-down-bold {
            float: right;
            margin-right: -5px;
          }
        }
      }
    }

    >a {

      @include media(">md") {
        color: $white;
        background: $gray-600;
      }
    }
  }

  .block-inverse & {

    .collapse {
      background: $gray-600;

      @include media(">md") {
        background: transparent;
      }
    }

    a {
      color: $white;
      background: none;

      &:hover,
      &:focus {
        color: $gray-600;
        background: $white;
      }
    }

    .active {

      >a {
        color: $gray-600;
        background: $white;
      }
    }
  }

  &:not(.sidebar-right) {

    nav {
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 20;
      top: 100%;
      background: $white;
      display: block;
      max-height: 300px;

      @include media(">sm") {
        max-height: none;
        padding-top: 15px;
        padding-bottom: 5px;
      }

      @include media(">md") {
        opacity: 1;
        visibility: visible;
        max-height: none;
        position: static;
        padding-top: 0;
      }

      &.show {
        opacity: 1;
        visibility: visible;
        box-shadow: 0 0 20px rgba($black, .2);

        .row {
          padding: 15px 0;

          @include media(">sm") {
            padding: 0;
          }
        }
      }
    }

    [class*="col-"] {

      &:not(:last-child) {

        @include media(">md") {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.sidebar-right {
    margin-right: 0;

    @include media(">sm") {
      width: 100%;
    }

    @include media(">lg") {
      margin-left: 55px;
      width: 225px;
    }

    &:after {
      @include size(1px, 100%);
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: #dadada;
      display: none;


      @include media(">md") {
        right: auto;
        left: -1px;
        display: block;
      }

      @include media(">lg") {
        left: -15px;
        display: block;
      }
    }


    .sidebar-nav-item {

      @include media(">sm") {
        margin-right: 0;
      }

      &:last-child {

      }
    }
    nav {
      .row {
        div {
          &:first-child {
            .sidebar-nav-item {
              &:last-of-type {
                @include media(">lg") {
                  margin-bottom: 40px;
                }

              }
            }
          }
        }
      }
    }
    &.sidebar-newsletter-right {
      @include media(">lg") {
        margin-top: -80px;
      }
      @include media(">xlg") {
        margin-top: -80px;
      }
    }
  }
}
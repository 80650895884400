.plus-list {

  li {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    &:not(:last-child) {
      margin-bottom: 5px;
      line-height: 1.6;
    }

    &:before {
      content: "+";
      margin-right: 10px;
    }
  }
}
.cr_form {
  .cr_body {
    font-family: "Helvetica Neue LT", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #1a1a1b;

    @include media(">sm") {
      font-size: 15px;
    }
  }

  .cr_ipe {
    input {
      display: block;
      padding: 0 0.75rem !important;
      line-height: 38px;
      background: none !important;
      border: 1px solid #dadada;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      min-height: 40px;
    }

    label {
      margin: 0;
      font-weight: 400;
      text-align: left;
      background-color: #fff;
      line-height: 38px;
    }

    &.submit_container {
      margin: 0;
      font-weight: 400;
      line-height: 1.2;
      text-align: left;
      background-color: #fff;
    }

    .custom-checkbox {
      font-size: 16px !important;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .custom-select{
      box-shadow: none;
    }
  }

  .custom-checkbox {
    .custom-control-label {

      line-height: 26px;
      font-weight: 500;

      &:before {
        top: 0;
      }

      &:after {
        width: 26px;
        height: 26px;
        z-index: 1;
        top: 0;
        transform: scale(1);
        font-size: 33px;
        left: -3px;
      }
    }
  }

  .inactive {
    display: none;
  }
}


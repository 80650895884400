.btn {
  text-align: left;
  font-weight: 500;
  white-space: normal;
  line-height: 1.6;

  span {
    line-height: 22px;
  }

  &:disabled {
    background: $white;
    color: $gray-300;
    cursor: not-allowed;
  }

  i {
    margin-right: 15px;
    line-height: 40px;
  }

  &.reduced {
    line-height: 30px;

    i {
      line-height: 30px;
    }

    &:hover,
    &:active {
      background: $white !important;
      color: $gray-600 !important;
    }
  }

  &.bordered {
    line-height: 38px;

    i {
      line-height: 38px;
    }
  }

  &.flex-row-reverse {

    i {
      margin-right: 0;
      margin-left: 15px;
      width: 16px;
    }
  }

  &.btn-info {

    &:not(:disabled) {
      background: $white;
      color: $gray-600;

      &:hover,
        //&:focus,
      &:active {
        background: $gray-600;
        color: $white;
      }
    }
  }

  &.btn-default {

    &:not(:disabled) {
      color: $white;
      background: $gray-600;

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: $gray-600;
        background: $white;
      }
    }
  }

  &.btn-success {

    &:not(:disabled) {
      background: $white;
      color: $success;

      &:hover,
      &:focus,
      &:active {
        background: $success;
        color: $white;
      }
    }
  }

  &.btn-icon-right {

    i {
      margin-left: 15px;
      margin-right: 0;
    }

    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.btn-custom.btn:not(:disabled):not(.disabled) {
  background: none;
  color: #ffffff;
  padding: 9px 15px;
}

.btn-custom.btn:not(:disabled):not(.disabled):hover {
  background: #ffffff;
  color: #54575c;;
}
.description-list {
  counter-reset: list;
  padding-left: 0;

  li {
    list-style: none;
    counter-increment: list;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:before {
      @include size(40px);
      border-radius: 50%;
      content: counter(list);
      background: $white;
      margin-right: 15px;
      color: $gray-600;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.num-inverse {

    li {
      align-items: flex-start;

      &:before {
        @include size(30px);
        background: #1a1a1b;
        color: $white;
        transform: translateY(-8px);
      }
    }
  }
}

/*list bullets without list element*/
.custom-number-rounded {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #1a1a1b;
  z-index: 1;
  border-radius: 50%;
  margin-right: 15px;
  vertical-align: top;
  text-align: center;
  padding-top: 5px;
}
.custom-number-rounded-text {
  display: inline-block;
  width: calc(100% - 70px);
}

#img-nums-1 {
  counter-reset: list;

  li {
    list-style: none;
    counter-increment: list;
    position: absolute;
    z-index: 1;

    &:before {
      @include size(25px);
      border-radius: 50%;
      content: counter(list);
      background: $white;
      margin-right: 5px;
      color: $gray-600;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media(">sm") {
        @include size(40px);
      }
    }

    &:nth-child(1) {
      top: 74%;
      left: 56%;
    }

    &:nth-child(2) {
      top: 50%;
      left: 60%;
    }

    &:nth-child(3) {
      top: 38%;
      left: 38%;
    }

    &:nth-child(4) {
      top: 21%;
      left: 66%;
    }

    &:nth-child(5) {
      top: 45%;
      left: 8%;
    }
  }
}
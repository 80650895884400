.extended-download-block {
  border: 1px solid #dadada;
  margin-bottom: 20px;

  .border-connect {

  }

  .block-title {
    padding: 20px;
    background: #dadada;

    i {
      font-size: 90px;

      @include media(">lg") {
        font-size: 190px;
      }
    }

    .strong {
      font-size: 125%;
    }
  }

  .block-content {
    padding: 20px;
  }

  .select-wrap {
    min-width: 200px;
    margin-right: 5px;
  }

  dl {
    &:not(:last-child) {
      margin-bottom: 30px;

      @include media(">md") {
        margin-bottom: 0;
      }
    }

    @include media(">md") {
      width: 100%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  dd {
    @include media(">md") {
      display: flex;
      align-items: center;
    }
  }

  .fake-cell {
    width: 25%;
    display: inline-block;
    vertical-align: top;

    @include media(">md") {
      width: 90px;
      text-align: right;
    }
  }
}
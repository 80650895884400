.danger-list {
  margin-bottom: 0;

  li {
    position: relative;
    z-index: 1;
    padding-left: 10px;
    line-height: 1.6;

    &:before {
      position: absolute;
      left: 0;
      content: "!";
      color: $danger;
      top: 0;
    }
  }
}
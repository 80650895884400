body {
  min-width: 320px;
  font-size: 14px;
  overflow: hidden;

  @include media(">sm") {
    font-size: 15px;
  }

  @include media(">lg") {
    font-size: 16px;
  }

  &.initialStatePassed {
    overflow: auto;

    &.modal-open {
      overflow: hidden;
    }
  }
  
  &.initialStatePassing {
    overflow: auto;
  }
}

p {
  line-height: 1.6;
}

a {
  color: $body-color;
  transition: color .3s;

  &:hover,
  &:focus {
    color: $success;
  }
}

.lead {
  font-family: $font-family-serif;
  font-style: italic;
  letter-spacing: 0.5px;
}

.med {
  font-weight: 600;
}

ol, ul {
  padding-left: 20px;

  &.default-list {

    li {
      margin-bottom: 5px;
      line-height: 1.6;
    }
  }
}

dt {
  font-weight: normal;
  margin-right: 5px;
}

#wrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.container {
  @include media("<sm") {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.top-banner h1 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  @include media(">sm") {
    font-size: 50px;
    line-height: 60px;
  }

  @include media(">lg") {
    font-size: 80px;
    line-height: 90px;
  }

  @include media(">xlg") {
    font-size: 120px;
    line-height: 130px;
  }
}

.top-banner h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  @include media(">sm") {
    font-size: 20px;
    line-height: 32px;
  }

  @include media(">lg") {
    font-size: 26px;
    line-height: 42px;
  }

  @include media(">xlg") {
    font-size: 30px;
    line-height: 48px;
  }
}

h2 {
  line-height: 27px;
  font-weight: 300;

  @include media(">sm") {
    font-size: 45px;
    line-height: 54px;
  }

  @include media(">lg") {
    font-size: 50px;
    line-height: 60px;
  }

  @include media(">xlg") {
    font-size: 70px;
    line-height: 84px;
  }

  small {
    font-weight: 300;

    @include media(">md") {
      font-size: 100%;
    }
  }

  b {
    font-weight: 700;

    small {
      font-weight: 700;
      display: inline-block;
      vertical-align: top;
      line-height: 1.8;
      font-size: 50%;
    }
  }
}

h1, h3 {
  line-height: 35px;
  font-weight: 300;
  color: $gray-900;

  @include media(">sm") {
    font-size: 30px;
    line-height: 48px;
  }

  @include media(">lg") {
    font-size: 36px;
    line-height: 60px;
  }
}

h4 {
  font-size: 125%;
  font-weight: 600;
  line-height: 1.6;
}

.title-holder {
  margin-bottom: 20px;
}

img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

.full-height {
  min-height: 100%;
}

.bg-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  picture {
    display: none;
  }
}

.common-section {
  padding: 30px 0;

  .img-holder {
    margin-bottom: 15px;
    position: relative;

    @include media(">sm") {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
    }
  }
}

.no-transition {

  * {
    transition: none !important;
  }

  header {

    &:before,
    &:after {
      transition: none !important;
    }
  }
}

.go-to-content {
  color: $white;
  font-size: 60px;
  transition: color .3s;
  line-height: 1;
  margin: 30px 0;
  display: inline-block;

  @include media(">lg") {
    font-size: 80px;
  }

  @include media(">xlg") {
    font-size: 100px;
  }

  &:hover,
  &:focus {
    color: $success;
  }
}

.block-inverse {
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }

  .breadcrumb-item {
    font-size: 12px;

    @include media(">sm") {
      font-size: 16px;
    }

    a {
      color: $white;
      transition: color.3s;

      &:hover,
      &:focus {
        color: $success;
      }
    }

    &.active {
      color: $white;
    }

    &:before {
      color: $white;
    }
  }
}

.block-inverse.get-image {
  background-attachment: fixed;
  background-size: cover;

  p {
    letter-spacing: 0.5px;
  }
  .med {
    letter-spacing: 1px;
  }
}

.block-inverse.get-image.content-wrap {
  background-attachment: local ;
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item {

  a,
  &:before {
    color: inherit;
  }

  &:not(.active) {

    &:hover {
      text-decoration: underline;
    }
  }
}

.content-wrap {
  padding: 65px 0 15px;
  background-repeat: repeat-y;
  background-size: 100%;
  background-position: center top;
  background-size: cover;

  @include media(">sm") {
    padding: 80px 0 20px;
  }

  @include media(">lg") {
    padding: 115px 0 25px;
  }

  &.bg-no-repeat {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .tip {
    bottom: 0;
    right: 0;
    display: none;

    @include media(">sm") {
      display: block;
    }

    .tip-content {

      &:after {
        display: none;
      }

      &:before {
        bottom: 0;
      }
    }

    &:hover,
    &:focus {

      i {
        transform: translateY(-50%);
      }
    }
  }

  &.extended {
    padding: 80px 0 15px;

    @include media(">sm") {
      padding: 115px 0 40px;
    }

    @include media(">lg") {
      padding: 140px 0 35px;
    }
  }
}

.content-wrap-project {
  background-color: #54575c;
  color: #ffffff;
}

.layout-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;

  @include media(">md") {
    flex-direction: row;
    align-items: flex-start;
  }

  .layout-wrap {
    flex-wrap: wrap;

    @include media(">lg") {
      flex-wrap: nowrap;
    }

    .page-content {
      .row {
        display: flex;

        @include media(">sm") {
          display: block;
        }
      }
    }
  }

  .tab-content {

    .layout-wrap {
      flex-wrap: nowrap;
    }
  }
}

.page-content {
  flex-grow: 1;
  overflow: hidden;

  .project-teaser-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .cmf-block {
      float: left;
      width: 100%;
      padding: 15px;

      @include media(">sm") {
        width: 50%;
      }

      &:first-child {
        width: 100%;
      }
    }
  }
}

hr {

  .block-inverse & {
    border-top-color: $white;
  }
}

.img-quote {
  margin: 0;

  color: $black;

  q {

    &:before,
    &:after {
      display: none;
    }
  }

  cite {
    color: $gray-900;
    font-style: normal;
  }
}

@for $i from 1 through length($color-products-values) {
  .first-letter-#{nth($color-products-names, $i)} {

    &:first-letter {
      float: left;
      font-size: 475%;
      color: nth($color-products-values, $i);
      line-height: 1;
      margin-right: 5px;

      @include media(">lg") {
        font-size: 470%;
      }
    }
  }
}

.new-item {
  padding: 0 18px;
  margin-right: 15px;
  border: 1px solid $danger;
  transform: translateY(-20%);
}

.xl-covered-gray-100 {

  @include media(">xlg") {
    background: $gray-100;
  }
}

.sm-img-cover {

  @include media(">sm") {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  img {
    width: 100%;

    @include media(">sm") {
      max-width: none;
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.sm-shrink {
  @include media(">sm") {
    flex-shrink: 1;
  }
}

.title{
  text-overflow: ellipsis;
  display: block;
}

.display_none {
  display: none;
}
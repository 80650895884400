.color-block {

  .img-holder {
    max-height: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(">xlg") {
      max-height: 60px;
    }

    img {
      width: 100%;
    }
  }
}

.wanted-break-lg{
  display: none;

  @include media(">lg") {
    display: block;
  }
  @include media(">xlg") {
    display: none;
  }
}
.link-next{
  color: $green;
  padding: 15px 20px 15px 15px;
  transition: .3s all;
  position: relative;
  line-height: .7;
  border: none;
  background: transparent;
  cursor: pointer;
  i{
    padding-left: 10px;
  }
  &:hover{
    background: $green;
    color: $white;
  }
}

.font-weight-bold--600{
  font-weight: 600;
}
.sd-block .card{
  border: none;
  border-radius: inherit;
}
#catalogs-form{
  .card{
    border: none;
    position: relative;
    background-color: transparent;

    h6{
      padding-right: 0px;
      margin-bottom: 5px;
    }
  }

  .card .lang-options{
    padding-top: 1rem;
    padding-bottom: .7rem;
    justify-content: flex-start;
    padding-right: 30px;

    a{
      color: $gray-400;
      &:hover{
        color: $gray-900;
      }
    }
    li:first-child{
      padding-left: 0;
    }
    li.active a {
      color: $gray-900;
    }
  }

  .card .lang-options li:not(:first-child):before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $gray-400;
    content: "";
    left: 0;
  }
}

.block-inverse #catalogs-form {
  .sd-block .card {
    .list-unstyled {
      li a {
        color: #dadada;
      }

      li.active a,
      li a:hover,
      li a:focus {
        color: #fff;
      }
    }
  }

  .select-wrap {
    background-color: transparent;
  }

  .form-control,
  .form-control:focus,
  .form-control:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }

  .custom-control-label,
  .select-wrap i,
  a,
  a:visited {
    color: #fff;
  }

  button.link-next {
    color: #fff;
    border: 1px solid #fff;
  }

  .custom-select option {
    color: #6f6f6f;
    background-color: #fff;
  }

  .custom-checkbox .custom-control-label:before {
    color: #fff;
    background-color: transparent !important;
    border: 1px solid #fff;
  }
}



//.card__download,
//.link-download{
//  display: inline-block;
//  background: url("../images/btn-black-arrow-r.png") no-repeat center;
//  transform: rotate(90deg);
//  transition: .3s all;
//  &:hover{
//    background: $gray-600 url("../images/btn-white-arrow-r.png") no-repeat center;
//  }
//}
//
//.card__download{
//  width: 40px;
//  height: 40px;
//  position: absolute;
//  bottom: 20px;
//  right: 0;
//  z-index: 1;
//}
//
//.link-download{
//  width: 26px;
//  height: 26px;
//  background-size: 20px;
//  &:hover{
//    background: transparent url("../images/btn-black-arrow-r.png") no-repeat center;
//  }
//}

.custom-table{
  //margin-bottom: 30px;
  @include media-breakpoint-up('sm'){
    //margin-bottom: 40px;
    font-size: $font-size-base;
  }
  &__head{
    @include media-breakpoint-up('sm'){
      border-bottom: 1px solid $gray-300;
      padding-bottom: 2px;
    }
    b{
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      @include media-breakpoint-up('sm'){
        font-size: $font-size-base;
      }
    }
    &-titles{
      margin-bottom: 25px;
    }
  }
  .row{
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-up('sm'){
      border-bottom: 1px solid $gray-300;
    }
    &.custom-table__head-titles{
      border-bottom: none;
      margin-bottom: 15px;
    }
  }
  & [class^="col"]{
    padding: 0;
    @include media-breakpoint-up('sm'){
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .download-element {
    height: 40px;
  }
}

.file-logo{
  margin-right: 20px;
  overflow: hidden;
  @include media-breakpoint-up('sm'){
    margin-right: 30px;
  }
}

.sidebar-newsletter{
  margin-top: 15px;
  max-width: 350px;
  overflow: hidden;
    @include media(">sm") {
      max-width: 100%;
    }
    @include media-breakpoint-up('lg'){
      margin-top: 0;
      position: relative;
      padding-left: 0;

    }

  div {
    column-count: 1;

    @include media(">sm") {
      column-count: 2;
    }
    @include media(">lg") {
      column-count: 1;
    }
  }
  .hidden-element {
    display: none;
    @include media(">sm") {
      display: block;
    }
    @include media(">lg") {
      display: none;
    }
  }
}

.sidebar-newsletter__row{
  text-align: center;
  flex-wrap: nowrap;
  padding-left: 0px;

}

.sidebar-newsletter__item{
  @extend .col-10;
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  width: 40px;
  max-width: none;
  flex: 0 0 40px;
  a{
    color: $gray-600;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    &:hover,
    &.active{
      color: $white;
      background-color: $gray-600;
    }
  }
}

.sidebar-newsletter__item--year{
  color: $gray-600;
  font-weight: 600;
  margin-right: 15px;
}

.sd-block{
  margin-top: 1rem;
  h3 {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    word-break: break-word;
    i {
      color: $gray-600;
      margin-right: 25px;
      margin-left: 6px;
      font-size: 40px;
      @include media-breakpoint-up('sm'){
        font-size: 60px;
        margin-right: 45px;
      }
    }
    img {
      margin-right: 45px;
    }
    &:first-child{
      margin-top:20px;
    }
  }
  .sidebar:after{
    display: none;
  }
  .sidebar{
    font-weight: 400;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    @include media-breakpoint-up('sm'){
      margin-bottom: 20px;
    }
    .sidebar-opener{
      i {
        padding-left: 5px !important;
      }
    }
    span{
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1 auto;
    }
    .vertical-spacer {
      padding: 0 10px;
      overflow: initial;
      flex: none;
    }
  }
  .download-dropdown {
    padding: 0;

    .sidebar-nav-item {

      margin-left: 0;
      margin-right: 0;

      b {
        min-width: 131px;
      }
    }

  }

  .card {
    position: relative;

    .list-unstyled {

      min-height: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 500;
      color: #54575c;
      cursor: pointer;
      line-height: 26px;

      .active {
        font-weight: 600;
      }

      a {
        text-transform: uppercase;

        &:focus, &:hover {
          color: #54575c;

          &after {
            color: #dadada;
          }
        }
        &:after {
          color: #dadada;
          &:focus, &:hover {
            color: #dadada;
          }

        }
      }
      span {
        text-transform: uppercase;
      }
      li {
        a{
          color: #dadada;
        }
        &.active {
          a{
            color: #54575c;

            &:after {
              color: #dadada;
            }
          }
        }
      }
    }
  }
  .multi-download-wrapper {
    min-height: 40px;
    padding: 0;
    position: relative;

    .download-btn {
      transition: .3s all;
      line-height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      padding: 0;
      position: absolute;
      right: 0;
      color: #1a1a1b;
      height: 40px;
      width: 40px;
      text-align: center;
      top: -15px;
      bottom: auto;

      &:hover {
        background: #54575c;
        color: #fff;
      }
    }
    ul {
      li {
        a {
          &:after {
            content: " |";
            font-weight: normal;
          }
        }

        &:last-child {
          a {
            &:after {
              display: none;
            }

          }
        }
      }
    }
  }
  .download-list-wrapper {
    position: relative;
    min-height: 40px;
    padding: 0;

    .download-btn {
      transition: .3s all;
      line-height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      padding: 0;
      right: calc(50% - 20px);
      color: #1a1a1b;
      height: 40px;
      width: 40px;
      text-align: center;
      top: 0;
      position: absolute;

      &:hover {
        background: #54575c;
        color: #fff;
      }
    }
  }
  .list-unstyled {
    .card {
      h6 {
        margin: 5px 0;
        width: calc(100% - 50px);

        @include media(">sm") {
          margin-top: 5px;
          margin-bottom: 5px;
        }
        @include media(">lg") {
          margin-top: 15px;
          margin-bottom: 5px;
        }
      }
      .card-img{
        background-color: #e8e8e8;
        height: 100%;
      }
      .card-icon {
        position: relative;
        height: 100%;

        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }

        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%)scale(15);
          color: #ffffff;
          left: calc(50% - 10px);

          @include media(">lg") {
            transform: translateY(-50%)scale(10);
          }
        }
      }
    }
  }
}

.sidebar--index{
  z-index: 9;
}

@media (min-width: 560px) {
  .form-group.mb-sm-5 {
    margin-bottom: 30px !important;
  }
}

@media (min-width: 710px) {
  .form-group.mb-sm-5 {
    margin-bottom: 40px !important;
  }
}

.file-logo {
  width: 40px;
  height: 40px;
  display: inline-block;
  img{
    width: 100%;
  }
}

.sd-bg-holder > div{
  flex: 1 1 auto;
}

.sd-bg-holder div{
  height: 100%;
}

#block-cms-blocks-contact-image {
  div {
    background-size: cover;
  }
}

.no-img{
  min-height: 205px;
  height: 100%;
  background-color: $gray-300;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  i {
    font-size: 60px;
    color: $white;
  }
}

.sd-block{
  /*.sidebar:not(.sidebar-right) nav{
    @include media-breakpoint-up('md'){
      position: relative;
    }
  }*/
  .sidebar:not(.sidebar-right) .collapse{
    @include media-breakpoint-up('md'){
      opacity: 0;
      height: 0;
    }
  }
  .sidebar:not(.sidebar-right) .collapse.show{
    @include media-breakpoint-up('md'){
      opacity: 1;
      height: auto;
    }
  }
  .sidebar:not(.sidebar-right) nav.show {
    position: relative !important;
    margin-top: 0 !important;
    padding-top: 0;
    padding-bottom: 0;

    .row {
      padding: 0;
    }
  }
}

.icon2-downloads {
  display: inline-block;
  font-size: 20px;
  line-height: unset;
}

#bim-download-categories.custom-table, #cad-download-categories.custom-table, .bim-download-categories.custom-table, .cad-download-categories.custom-table {

  @include media("<sm") {
    display: none;
  }
}
.mobile-list {
  width: 100%;
  flex-wrap: wrap;
  font-size: 15px;
}

.custom-table__head {
  .mobile-list {
    b{
      font-size: 15px;
    }

  }
}

.custom-table [class^="col"] {
  @include media("<sm") {
     margin-top: 10px;
     margin-bottom: 10px;
  }
}
.multiwrap {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @include media(">md") {
    flex-direction: row;
  }

  h2 {
    transform-origin: top left;
    transform: scale(.5);
    transition: transform .5s;
  }

  .page-title-holder {
    padding-top: 50px;
    max-width: 100%;
    transition: top .5s;

    @include media(">sm") {
      padding-top: 80px;
    }

    @include media(">md") {
      transition: max-width .5s, left .5s;
      padding-top: 125px;
    }

    @include media(">md") {
      padding-top: 180px;
    }
  }

  .go-to-content {
    opacity: 0;
    transition: opacity .5s, color .3s;
  }

  .top-banner {
    min-height: 1px;
    max-height: 20vh;
    transition: max-height .5s;

    @include media(">md") {
      max-width: 20%;
      min-height: 100vh;
      height: 100vh;
      transition: max-width .5s;
    }
  }

  &:hover,
  &:focus {

    .top-banner {
      max-width: 100%;
      flex-grow: 1;
      overflow: hidden;

      &:not(:hover),
      &:not(:focus) {
        min-height: 1px;
        max-height: 10vh;

        @include media(">md") {
          max-width: 150px;
          min-height: 100vh;
        }

        @include media(">lg") {
          max-width: 200px;
        }

        .page-title-holder {
          max-height: 400px;
          top: -10px;

          @include media(">md") {
            max-width: 400px;
            left: -50%;
          }
        }
      }

      &:hover {
        max-width: 100%;
        max-height: 60vh;

        @include media(">md") {
          max-width: 100%;
          min-height: 100vh;
        }

        @include media(">lg") {
          max-width: 100%;
        }

        .go-to-content {
          opacity: 1;
        }

        h2 {
          transform: scale(1);
        }

        .page-title-holder {


          @include media(">md") {
            max-width: none;
            left: 0;
          }
        }
      }
    }
  }
}

.top-banner {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;

  .initialStatePassed & {
    display: none;
  }
  .initialStatePassed2 & {
    display: none;
  }
  .initialtemp & {
    display: none;
  }

  .initialStatePassed.initialStatePassing .staticBanner & {
    display: flex;
  }

  .slider {
    flex-grow: 1;
    display: flex;
    position: relative;
    z-index: 1;

    .slick-list {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .slick-track {
      min-height: 100%;
      display: flex;
    }

    ~ .container {
      left: 0;
      right: 0;
      position: absolute;
      z-index: 1000;
      width: 100px;
    }
  }

  &> .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
  }

  .slick-slide {
    width: 100vw;
    height: auto;
    min-height: 100vh;

    @include media("landscape") {
      height: 100vh;
    }

    @include media(">sm") {
      height: 100vh;
    }
  }

  .slick-dots {
    position: absolute;
    top: auto !important;
    left: 0;
    right: 0;
    padding: 0 15px;
    margin: 0 auto;
    list-style: none;
    z-index: 8;
    width: 100%;
    bottom: 244px !important;

    @include media(">sm") {
      max-width: 680px;
      bottom: 21px !important;
    }

    @include media(">md") {
      max-width: 950px;
    }

    @include media(">lg") {
      max-width: 1160px;
    }

    @include media(">xlg") {
      max-width: 1810px;
    }

    li {
      display: inline-block;
      position: relative;
      z-index: 90;

      button {
        @include size(60px, 10px);
        background: $white;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0;
        margin: 0 20px 0 0;
        border: none;
        text-indent: -9999px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        outline: none;
        box-shadow: 3px 5px 5px 0 rgba($black,0.2);
        transition: background-color .3s;
      }

      &.slick-active {
        button {
          background: $gray-600;
        }
      }
    }
  }

  .breadcrumb {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    margin: 0 auto;
    padding: 0 20px;
    text-shadow: 1px 2px 5px rgba($black, .2);

    @include media(">sm") {
      top: 90px;
      padding: 0 15px;
    }

    @include media(">lg") {
      top: 145px;
    }

    .breadcrumb-item {
      color: $white;
      position: relative;
      z-index: 100;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      a,
      &:before {
        color: inherit;
      }

      &.active {
        color: $white;
        pointer-events: none;
      }
    }
  }
  .page-title {
    h2 {
      font-size: 22px;
      line-height: 28px;

      @include media(">sm") {
        font-size: 50px;
        line-height: 60px;
      }

      @include media(">lg") {
        font-size: 80px;
        line-height: 90px;
      }

      @include media(">xlg") {
        font-size: 120px;
        line-height: 130px;
      }
    }

    h1, h3 {
      a {
        color: $white;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.home-banner-items {

  padding-bottom: 0;

  @include media(">sm") {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateY(-100%);
    z-index: 5;
    padding-bottom: 15px;
  }

  &> div {
    &> div {
      &> p {
        margin-bottom: 0;
      }
    }
  }
}

.banner-link {
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  color: $white;
  margin: 0 -20px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0px 15px rgba(0, 0, 0,0.15))drop-shadow(0px 0 15px rgba(0, 0, 0,0.15));
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 0 0 100px rgba(0, 0, 0, 0.5);
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;

  @include media(">sm") {
    margin: 0 0 15px;
    min-width: 300px;
    padding: 18px 20px;
  }

  @include media(">lg") {
    min-width: 370px;
    padding: 34px 40px;
  }

  @include media(">xlg") {
    min-width: 630px;
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;

    @include media(">sm") {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
    }
    @include media(">xlg") {
      transform: scale(1.03);
      -webkit-transform: scale(1.03);
      -moz-transform: scale(1.03);
    }
  }

  strong {
    font-size: 22px;
    font-weight: 300;
    margin-top: 10px;

    @include media(">sm") {
      font-size: 30px;
    }

    @include media(">lg") {
      font-size: 36px;
    }

    @include media(">xlg") {
      font-size: 70px;
    }
  }
}

.page-title-holder {
  position: absolute;
  top: 0;
  color: $white;
  left: 0;
  right: 0;
  z-index: 2;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 100px rgba(0, 0, 0, 0.5);
  padding-top: 90px;
  letter-spacing: 0.7px;

  @include media(">sm") {
    padding-top: 107px;
  }

  @include media(">lg") {
    padding-top: 120px;
  }
  @include media(">xlg") {
    padding-top: 160px;
  }
}

.initialStatePassed.initialStatePassing .top-banner.slider-banner {
  display: block;
}

.initialStatePassed .top-banner.slider-banner {
  display: block;
}

body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block > ul {
  margin-top: -111111111111px; /*rainer*/
}
body:not(.initialStatePassed) .header:not(.smallHeader).show .nav-block > ul {
  margin-top: 0; /*rainer*/
}
body:not(.initialStatePassed) .header:not(.smallHeader) .nav-block:hover >ul {
  margin-top: 0;
}
.smallHeader .nav-content {
  margin-left: -999999999px;
  opacity: 0;
}
.smallHeader.show.in .nav-content {
  opacity: 1;
}
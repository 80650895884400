.detail-block {
  margin-bottom: 20px;

  .content-holder {
    background: #f8f8f8;
  }

  .img-holder {
    position: relative;

    a {
      position: absolute;
      font-size: 40px;
      color: $white;
      transition: color .3s;
      text-shadow: 1px 1px 4px $gray-600;
      right: 15px;
      top: 15px;
      outline: none;
      line-height: 1;

      &:hover,
      &:focus {
        color: $success;
      }
    }
  }

  /*comment*/
  .row {

    .img-holder {
      padding: 15px;

      .angle-left,
      .angle-right {
        position: absolute;
        bottom: 15px;
        left: 15px;
        max-width: 45%;
        
        @include media(">xlg") {
          width: 35%;
          text-align: right;
        }
      }

      .angle-right {
        left: auto;
        right: 15px;
        
        @include media(">xlg") {
          text-align: left;
        }
      }

      img {
        @include media(">sm") {
          max-width: 100%;
        }

        &.w-auto {
          width: auto;
        }

      }
    }
  }

  .img-no-offset {
    max-width: 75%;
    margin: 0 auto;
    padding: 0;
    max-height: 90%;

    @include media(">sm") {
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 30px;
      max-width: none;
      margin-top: -15px;
    }

    img {
      max-height: 100%;
    }
  }

  .col-auto {
    padding-bottom: 15px;
    
    @include media(">xlg") {
      flex-basis: auto;
    }
  }
}
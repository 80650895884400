$green:   #008c58;
$red: #ec1124;

$white:    #fff;
$gray-100: #f7f7f7;
$gray-200: #e9ecef;
$gray-300: #e8e8e8;
$gray-400: #ced4da;
$gray-500: #6f6f6f;
$gray-600: #54575c;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #1a1a1b;
$black:    #000;

$grays: ();
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
), $theme-colors);

$body-color:                $gray-900;

$grid-breakpoints: (
        xs: 0,
        sm: 709px,
        md: 979px,
        lg: 1219px,
        xl: 1799px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

$container-max-widths: (
        sm: 680px,
        md: 950px,
        lg: 1160px,
        xl: 1810px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-columns: 60;

$font-family-sans-serif:      "Helvetica Neue LT", "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$line-height-base: 1.2;

$h1-font-size: 30px;
$h2-font-size: 22px;
$h3-font-size: 22px;
$h4-font-size: 22px;

$headings-margin-bottom: 0;

$link-hover-decoration: none;

// Breadcrumbs

$breadcrumb-padding-y:              1.5rem;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          1rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-900;
$breadcrumb-active-color:           $gray-900;
$breadcrumb-divider:                ">";

// Cards

$card-spacer-y:                     .75rem;
$card-spacer-x:                     20px;
$card-border-width:                 $border-width;
$card-border-radius:                $border-radius;
$card-border-color:                 rgba($black, .125);
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                       rgba($black, .03);
$card-bg:                           $white;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 ($grid-gutter-width / 2);
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-border-radius:           0;
$btn-transition:              color .3s ease-in-out, background-color .3s ease-in-out;
$btn-padding-y:               0;
$btn-padding-x:               15px;
$btn-line-height:             40px;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            0;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              none;
$btn-focus-width:             0;
$btn-focus-box-shadow:        none;
$btn-disabled-opacity:        1;
$btn-active-box-shadow:       none;

$btn-link-disabled-color:     $gray-600;

$btn-block-spacing-y:         10px;

// Forms

$input-padding-y:                       0;
$input-padding-x:                       $input-btn-padding-x;
$input-line-height:                     38px;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $white;
$input-disabled-bg:                     transparent;

$input-color:                           #1a1a1b;
$input-border-color:                    #dadada;
$input-border-width:                    1px;
$input-box-shadow:                      none;

$input-border-radius:                   0;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              #1a1a1b;
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                none;

$input-placeholder-color:               #dadada;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height:                          calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm:                 ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg:                 ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border});

$custom-select-padding-y:           0;
$custom-select-padding-x:          15px;
$custom-select-height:              40px;
$custom-select-indicator-padding:   20px; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         38px;
$custom-select-color:               $input-color;
$custom-select-disabled-color:      #dadada;
$custom-select-bg:                  transparent;
$custom-select-disabled-bg:         #f7f7f7;
$custom-select-bg-size:             8px 10px; // In pixels because image dimensions
$custom-select-indicator-color:     #1a1a1b;
$custom-select-indicator:           none;
$custom-select-border-width:        1px;
$custom-select-border-color:        #dadada;
$custom-select-border-radius:       0;

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-box-shadow:    none;

$custom-select-font-size-sm:        75%;
$custom-select-height-sm:           $input-height-sm;

$custom-select-font-size-lg:        125%;
$custom-select-height-lg:           $input-height-lg;


$form-group-margin-bottom:          10px;
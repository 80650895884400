@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=695fdabe-5695-4a6d-aa8b-da776f937652");
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../fonts/helveticaNeue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
  src:url("../fonts/helveticaNeue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"),url("../fonts/helveticaNeue/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),url("../fonts/helveticaNeue/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"),url("../fonts/helveticaNeue/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype"),url("../fonts/helveticaNeue/82a4e1c2-7ffd-4c58-86fa-4010a6723c8e.svg#82a4e1c2-7ffd-4c58-86fa-4010a6723c8e") format("svg");
  font-weight: normal;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../fonts/helveticaNeue/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
  src:url("../fonts/helveticaNeue/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("../fonts/helveticaNeue/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url("../fonts/helveticaNeue/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),url("../fonts/helveticaNeue/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"),url("../fonts/helveticaNeue/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
  font-weight: 500;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../fonts/helveticaNeue/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix");
  src:url("../fonts/helveticaNeue/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix") format("eot"),url("../fonts/helveticaNeue/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2") format("woff2"),url("../fonts/helveticaNeue/050b1948-f226-4d20-a65a-15d8ed031222.woff") format("woff"),url("../fonts/helveticaNeue/beb88be6-2a60-46fe-914f-6892b3ae46ce.ttf") format("truetype"),url("../fonts/helveticaNeue/e5567978-93ef-4de1-804d-1f0e3654a014.svg#e5567978-93ef-4de1-804d-1f0e3654a014") format("svg");
  font-weight: 600;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../fonts/helveticaNeue/170b06bb-fec9-4e1b-9fa8-ddc9b686d8b5.eot?#iefix");
  src:url("../fonts/helveticaNeue/170b06bb-fec9-4e1b-9fa8-ddc9b686d8b5.eot?#iefix") format("eot"),url("../fonts/helveticaNeue/76623803-2b62-4c34-be21-c81ead28b379.woff2") format("woff2"),url("../fonts/helveticaNeue/327669a6-efac-4cce-9e96-13dd2d2e680e.woff") format("woff"),url("../fonts/helveticaNeue/b32ece14-a4d6-4dd6-959c-aad90747cb77.ttf") format("truetype"),url("../fonts/helveticaNeue/92786305-3921-440d-8e97-3188c1fa8070.svg#92786305-3921-440d-8e97-3188c1fa8070") format("svg");
  font-weight: 300;
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../fonts/helveticaNeue/aabcacc3-942c-44aa-9cec-85c00e69b36d.eot?#iefix");
  src:url("../fonts/helveticaNeue/aabcacc3-942c-44aa-9cec-85c00e69b36d.eot?#iefix") format("eot"),url("../fonts/helveticaNeue/b73a7721-ce68-4068-be2f-e354675b54c0.woff2") format("woff2"),url("../fonts/helveticaNeue/1be79393-9ab6-4f5e-8ff8-786d00be4238.woff") format("woff"),url("../fonts/helveticaNeue/c2335bef-8128-4a40-bd76-bb763d638a84.ttf") format("truetype"),url("../fonts/helveticaNeue/9874e59c-6906-4c68-a432-cabfeaf49c06.svg#9874e59c-6906-4c68-a432-cabfeaf49c06") format("svg");
  font-weight: 700;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?cmvaog');
  src:  url('../fonts/icomoon.eot?cmvaog#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?cmvaog') format('truetype'),
  url('../fonts/icomoon.woff?cmvaog') format('woff'),
  url('../fonts/icomoon.svg?cmvaog#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon2-"], [class*=" icon2-"], [class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  display: inline-block;
  transform: scale(2);
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before{
  content: "\e916";
}
.icon-youtube:before {
  content: "\e915";
}
.icon-angle-down:before {
  content: "\e909";
}
.icon-angle-down-bold:before{
  content: "\e909";
  font-weight: bold;
}
.icon-angle-left:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-instagram:before {
  content: "\e913";
}
.icon-angle-right:before {
    content: "\e90b";
}
.icon-angle-up:before {
  content: "\e90c";
}
.icon-arrow-left:before {
  content: "\e90d";
}
.icon-pinterest:before {
  content: "\e914";
}
.icon-arrow-right:before {
  content: "\e90e";
}
.icon-arrow-up:before {
  content: "\e90f";
}
.icon-close:before {
  content: "\e910";
}
.icon-info-circle:before {
  content: "\e912";
}
.icon2-img:before,
.icon-img:before{
  content: "\e807";
}
.icon2-surface:before,
.icon-surface:before{
  content: "\e906";
}
.icon-angle-up:before{
  content: "\e901";
  transform: rotate(180deg);
}
.icon-mail:before {
  content: "\e914";
}
.icon2-textur:before,
.icon-textur:before{
  content: "\e907";
}
.icon2-cart:before,
.icon-cart:before{
  content: "\e905";
}
.icon2-comments:before,
.icon-comments:before{
  content: "\e908";
}
.icon2-arrows:before,
.icon-arrows:before{
  content: "\e904";
}
.icon2-univer:before,
.icon-univer:before{
  content: "\e900";
}
.icon2-farben:before,
.icon-farben:before{
  content: "\e903";
}
.icon2-snow:before,
.icon-snow:before{
  content: "\e902";
}
.icon2-cog:before,
.icon-cog:before{
  content: "\e901";
}
.icon2-architekt:before,
.icon-architekt:before{
  content: "\e800";
}
.icon2-bim:before,
.icon-bim:before{
  content: "\e801";
}
.icon2-cad-files:before,
.icon-cad-files:before{
  content: "\e803";
}
.icon2-downloads:before,
.icon-downloads:before{
  content: "\e804";
}
.icon2-bestellen:before,
.icon-bestellen:before{
  content: "\e802";
}
.icon2-leistungserkla:before,
.icon-leistungserkla:before{
  content: "\e805";
}
.icon2-news:before,
.icon-news:before{
  content: "\e806";
}
.icon2-referenzen:before,
.icon-referenzen:before{
  content: "\e807";
}
.icon2-regeldetails:before,
.icon-regeldetails:before{
  content: "\e808";
}
.icon2-technische:before,
.icon-technische:before{
  content: "\e809";
}
.icon2-zertifizierungen:before,
.icon-zertifizierungen:before{
  content: "\e80a";
}
.icon2-catalog:before,
.icon-catalog:before{
  content: "\e802";
}

.card-collapsible {
  @include size(100%, 320px);

  h4 {
    flex-shrink: 0;
    white-space: nowrap;
  }

  .card-content {
    overflow: hidden;
    position: relative;
  }

  .text-holder {
    transform: translateY(100%);
    transition: transform .3s;
    flex-grow: 1;
    position: relative;
    z-index: 2;
    max-height: 256px;
    margin-top: 20px;
  }

  .img-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 1;
    transition: opacity .3s;

    img {
      width: 55%;

      @include media(">lg") {
        width: 65%;
      }

      @include media(">xlg") {
        width: 50%;
      }
    }
  }
  .mCustomScrollBox {
    padding-bottom: 30px;
  }

  .icon-info-circle {
    position: absolute;
    right: 0;
    bottom: 15px;
    opacity: 1;
    transition: opacity .3s;
  }
}

.card-text-up {
  transform: translateY(0%) !important;
}

.card-image-hide , .card-info-button{
  transition: opacity .3s;
  opacity: 0 !important;
}
#data-protection {

  h2 {
    font-size: 125% !important;
    font-weight: 600 !important;
    line-height: 1.6 !important;
  }

  h3 {
    font-size: 100% !important;
    font-weight: 600 !important;
    line-height: 1.6 !important;
  }
}

.error-content-background-height {
  height: 100vh;
}

.container.form-container {
  max-width: 1160px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1 {
    line-height: 27px;
    font-weight: 300;

    @include media(">sm") {
      font-size: 45px;
      line-height: 54px;
    }

    @include media(">lg") {
      font-size: 50px;
      line-height: 60px;
    }

    @include media(">xlg") {
      font-size: 70px;
      line-height: 84px;
    }
  }

  h3 {
    line-height: 35px;

    @include media(">sm") {
      font-size: 30px;
      line-height: 48px;
    }

    @include media(">lg") {
      font-size: 36px;
      line-height: 57px;
    }
  }

  b{
    font-weight: 700;
  }
  .mb-sm-4 {
    @include media(">sm") {
      margin-bottom: 40px !important;
    }
  }
  .mt-sm-4 {
    @include media(">sm") {
      margin-top: 40px !important;
    }
  }
  .mb-4{
    margin-bottom: 40px !important;
  }
  .mt-4{
    margin-top: 40px !important;
  }
  .mb-5 {
    margin-bottom: 50px !important;
  }
  .mb-sm-5 {
    @include media(">sm") {
      margin-bottom: 50px !important;
    }
  }
  .mt-5 {
    margin-top: 50px !important;
  }
  .mt-sm-5 {
    @include media(">sm") {
      margin-top: 50px !important;
    }
  }
  .mb-6{
    margin-bottom: 60px !important;
  }
  .mb-sm-6 {
    @include media(">sm") {
      margin-bottom: 60px !important;
    }
  }
  .mt-6{
    margin-top: 60px !important;
  }
  .mt-sm-6 {
    @include media(">sm") {
      margin-top: 60px !important;
    }
  }
  .mt-lg-8 {
    @include media(">lg") {
      margin-top: 80px !important;
    }
  }

  .big-font {
    font-size: 22px;
    font-weight: 300;

    line-height: 32px;

    @include media(">sm") {
      font-size: 30px;
      line-height: 48px;
    }

    @include media(">lg") {
      font-size: 36px;
      line-height: 45px;
    }
  }
  .small-font {
    font-size: 20px;
    line-height: 32px;
  }
  .logo-left {
    max-width: 150px;
  }
  .logo-right {
    max-width: 460px;
    width: 100%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @include media(">sm") {
      float:right;
    }
  }
  .question {
    font-size: 16px;
    line-height: 26px;

    @include media(">sm") {
      font-size: 20px;
      line-height: 32px;
    }

    @include media(">lg") {
      font-size: 26px;
      line-height: 42px;
    }

    @include media(">xlg") {
      font-size: 30px;
      line-height: 48px;
    }
  }
  .list-unstyled {
    .card-img {
      img {
        border: 1px solid #dadada;
      }
    }
  }
  .youtube-container{
    position: relative;
    padding-bottom: 50%;
    padding-top: 25px;
    height: 0;

    @include media(">sm") {
      position: relative;
      padding-bottom: 53%;
      padding-top: 25px;
      height: 0;
    }

    @include media(">=md") {
      position: relative;
      padding-bottom: inherit;
      padding-top: 15px;
      height: inherit;
    }
    .youtube-frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 15px;
      padding-right: 15px;

      @include media(">sm") {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media(">=md") {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 310px;
      }
    }
  }
  .page-content {
    .project-teaser-wrapper {
      .cmf-block {
        &:first-child {
          width: 100%;

          @include media(">sm") {
            width: 50%;
          }
        }
      }
    }
  }
}
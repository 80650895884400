.custom-scroll-mask {
  padding: 30px 0;
  margin: 20px 0;

  @include media(">lg") {
    padding: 40px 0;
  }

  .mCSB_scrollTools {
    opacity: 1 !important;

    .mCSB_draggerRail {
      background: $grey;
      height: 10px;
      margin: 0;
    }
  }

  .mCSB_dragger {
    background: none;
  }

  .mCSB_dragger_bar {
    background: $success !important;
  }
}

.custom-scroll-mask.custom-history {
  padding: 40px 0;
  margin: 0 -15px;

  .mCSB_scrollTools {
    .mCSB_draggerRail {
      background: $white;
    }
  }
}

.custom-scroll-holder {
  display: flex;
  flex-wrap: nowrap;

  table {
    width: 100%;

    tr {
      td {
        &:nth-Child(1) {
          &> div {
            margin-left: -15px;

            &:nth-Child(2){
              border: none;
            }
          }
        }
      }
    }
  }
}

.history-block {
  flex-shrink: 0;
  width: 250px;
  color: $white;
  font-size: 10px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;

  @include media(">sm") {
    width: 310px;
    font-size: 12px;
  }

  @include media(">md") {
    width: 315px;
  }

  @include media(">lg") {
    font-size: 16px;
    width: 400px;
  }

  @include media(">xlg") {
    width: 483px;
  }

  .content-part {
    height: 100%;

    p {
      min-height: 60px;
    }
  }

  &:last-child {

    .time-holder {

      &:before {
        width: 40%;
      }

      &:after {
        position: absolute;
        content: "";
        top: 15px;
        right: 8%;
        border-style: solid;
        border-width: 3px 0 3px 12px;
        border-color: transparent transparent transparent $white;
        transform: translate(0, -50%);
      }
    }
  }

  .img-holder {

    img {
      width: 100%;
    }
  }

  .time-holder {
    position: relative;
    padding-top: 30px;
    font-size: 30px;
    text-align: center;

    @include media(">sm") {
      margin-top: 15px;
    }

    &:before {
      position: absolute;
      top: 15px;
      left: 50%;
      width: calc(100% + 30px);
      transform: translateY(-50%);
      content: "";
      z-index: 2;
      height: 2px;
      background: $white;
    }

    .arrows {
      @include size(8px);
      border-radius: 50%;
      content: "";
      background: $white;
      left: 50%;
      top: 15px;
      transform: translate(-50%, -50%);
      position: absolute;

      &:before {
        @include size(2px, 20px);
        position: absolute;
        content: "";
        top: 4px;
        background: $white;
        transform: translate(-50%, -100%);
        -ms-transform: translate(3px, -20px);
      }

      &:after {
        position: absolute;
        content: "";
        top: -25px;
        border-style: solid;
        border-width: 0 3px 12px 3px;
        border-color: transparent transparent $white transparent;
        transform: translateX(-50%);
        -ms-transform: translateX(1px);
      }
    }
  }
}

.lang-es {
  .history-block {
    .content-part {
      p {
        min-height: 80px;
      }
    }
  }
}

.data-titles-table {
  float: left;

  span {
    &:first-child{
      padding-top: 1px !important;
    }
  }
}

.scroll-line-margin .mCSB_scrollTools.mCSB_scrollTools_horizontal{
  margin: 0 15px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: 0 !important;
  right: 0;
  bottom: auto !important;
  left: 0;

  .mCSB_dragger {
    height: 10px !important;

    .mCSB_dragger_bar {
      height: 10px !important;
      margin: 0 !important;
    }
  }
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 0 !important;
  margin-top: 30px;
}
.pinnwand-block {
  margin-bottom: 20px;

  @include media(">lg") {
    margin-bottom: 40px;
  }

  .block-control {
    position: relative;

    >a {

      i {

        @include media(">lg") {
          font-size: 30px;
        }
      }

      h3 {
        transition: color .3s;

        @include media("<sm") {
          line-height: 40px;
        }
      }

      &:not(.close) {
        padding-right: 40px;
        transition: background-color .3s, color.3s, box-shadow .3s;

        @include media(">lg") {
          padding: 5px 90px 5px 40px;
        }

        i {

          &:before {
            transform: rotate(-90deg);
            transition: transform .3s;
            display: inline-block;
          }
        }

        &:hover {
          box-shadow: 0 0 3px $white inset;

          h3 {
            color: $white;
          }
        }

        &[aria-expanded="true"] {

          i {

            &:before {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .close {
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 2;
      transform: translateY(-50%);
      opacity: 1;
      font-size: 20px;
      color: $gray-600;

      @include media(">lg") {
        right: 40px;
      }
    }
  }

  .block-content {

    .card {

      @include media(">lg") {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10 !important;
  height: auto !important;
  display: block !important;
  overflow: visible;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    position: absolute;
    content: "";
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(to bottom, rgba($black,1) -100%, rgba($black,0) 100%);

    @include media(">sm") {
      height: 120px;
    }

    @include media(">lg") {
      height: 140px;
    }

    @include media(">xlg") {
      height: 180px;
    }
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background: $gray-600;
    content: "";
    transform: translateX(-100%);
    transition: transform .3s;
    width: 100%;
    @include media(">sm"){
      transition: transform .2s;
    }
    @include media(">lg") {
      bottom: auto;
      top: 100%;
      transform: none;
      background: $white;
      max-width: 1130px;
      height: 1px;
      margin: 0 auto;
    }

    @include media(">xlg") {
      max-width: 1780px;
    }
  }

  &.show {
    z-index: 2000 !important;

    &:after {
      transform: none;
    }
  }

  &.collapsing {
    z-index: 1000 !important;
  }

  &.smallHeader {

    &:before {

      @include media(">lg") {
        height: 120px;
      }
    }
  }
  .nav-block {
    li:last-child {
      margin-bottom: 0;
    }
  }
  .socials {
    li {
      a {
        font-size: 20px !important;
        opacity: 0.7;
      }
    }
  }
}

.header{

  a {
    color: $white;
  }

  .nav-block,
  .navigation-bottom {
    padding: 0 15px 9px;

    @include media(">lg") {
      padding: 0 0 9px;
    }

    .block-title {
      margin-bottom: 11px;
    }

    a {
      display: block;
      padding: 9px 15px;
      margin: 0 0 -1px;
      transition: color .3s, background-color .3s;
      overflow: hidden;
      line-height: 22px;

      @include media(">lg") {
        padding: 9px 10px;
      }

      @include media(">xlg") {
        padding: 9px 15px;
      }

      &:hover,
      &:focus {
        background: $white;
        color: $gray-600;
      }

      i {
        float: right;
        margin-right: 30px;
        line-height: 22px;
        display: none;
      }
    }

    b {
      font-weight: 600;
    }

    ul {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 11px;
    }
  }

  .navigation-bottom {
    text-align: center;

    @include media(">sm") {
      text-align: right;
    }
  }

  &:not(.smallHeader) {

    body:not(.initialStatePassed) & {

      >.container {

        @include media(">lg") {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 15px;
          padding-bottom: 15px;
          min-height: 86px;
          height: 86px;
        }

        [class*="col-"] {

          @include media(">lg") {
            width: auto;
            flex-grow: 0;
            max-width: none;
            flex-basis: auto;
            flex-shrink: 0;
          }
        }
      }

      .nav-content {

        @include media(">lg") {
          overflow: visible;
          flex-grow: 1;
          transition: none;
        }
      }

      .nav-block {

        @include media(">lg") {
          position: relative;
          z-index: 1;
          padding: 0;
        }

        >ul {

          @include media(">lg") {
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
            position: absolute;
            top: 36px;
            right: 0;
            width: auto;
            background: $white;
            padding: 15px;
            z-index: 200;
          }

          &:before {

            @include media(">lg") {
              content: "";
              display: block;
              height: 30px;
              position: absolute;
              bottom: 100%;
              width: 100%;
            }
          }

          a {

            @include media(">lg") {
              color: $gray-600;
              margin: 0;
              white-space: nowrap;
            }

            &:hover,
            &:focus {

              @include media(">lg") {
                background: $gray-600;
                color: $white;
              }
            }
          }
        }

        .block-title {

          @include media(">lg") {
            border: none;
            margin-bottom: 0;
          }

          a {

            @include media(">lg") {
              padding: 0;
              display: inline;
              margin: 0;
              font-size: 18px;
              font-weight: 300;
            }

            &:hover,
            &:focus {

              @include media(">lg") {
                background: transparent;
                color: $white;
                text-decoration: none;
              }
            }
          }
        }

        &:hover {

          >ul {

            @include media(">lg") {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      .navigation-bottom {

        @include media(">lg") {
          display: none;
        }
      }

      .top-nav {

        @include media(">lg") {
          padding: 0;
          margin: 0 -15px 15px;
        }

        >li {

          @include media(">lg") {
            margin: 0 15px;
          }
        }

        .socials {

          a {
            @include media(">lg") {
              font-size: 20px;
            }
          }
        }

        a {

          @include media(">lg") {
            font-size: 16px;
            font-weight: 300;
          }

          &:hover,
          &:focus {

            @include media(">lg") {
              text-decoration: none;
            }
          }
        }
      }

      #nav {

        @include media(">lg") {
          padding: 0;
        }
      }

      .nav-head {

        @include media(">lg") {
          padding: 0;
          border: none;
        }
      }

      .bottom-nav {

        @include media(">lg") {
          margin-bottom: 0;
        }

        a {

          @include media(">lg") {
            position: relative;
          }

          i {

            @include media(">lg") {
              position: absolute;
              top: 50%;
              transform:translateY(-50%);
              right: 20px;
            }
          }
        }

        [class*="hover-covered-"] {

          &:hover,
          &:focus {

            @include media(">lg") {
              color: $white !important;
            }
          }
        }
      }
    }
  }

  &.smallHeader {

    &:after {

      @include media(">lg") {
        display: none;
      }
    }
  }
}

.footer {

  a {
    color: $white;
  }

  .nav-block,
  .navigation-bottom {
    padding: 0 15px 9px;

    @include media(">lg") {
      padding: 0 0 9px;
    }

    .block-title {
      margin-bottom: 11px;
    }

    a {
      display: block;
      padding: 9px 15px;
      margin: 0 0 -1px;
      transition: color .3s, background-color .3s;
      overflow: hidden;
      line-height: 22px;

      @include media(">lg") {
        padding: 9px 10px;
      }

      @include media(">xlg") {
        padding: 9px 15px;
      }

      &:hover,
      &:focus {
        background: $white;
        color: $gray-600;
      }

      i {
        float: right;
        margin-right: 30px;
        line-height: 22px;
        display: none;
      }
    }

    b {
      font-weight: 600;
    }

    ul {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 11px;
    }
  }

  .navigation-bottom {
    text-align: center;

    @include media(">sm") {
      text-align: right;
    }
  }

  &.smallHeader {

    &:after {

      @include media(">lg") {
        display: none;
      }
    }
  }
}

.nav-head {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $white;

  @include media(">sm") {
    padding: 17px 0;
  }

  .smallHeader & {

    @include media(">lg") {
      width: 100%;
    }
  }
}

.logo, #block-cms-blocks-logo {
  max-width: 210px;
  line-height: 1;

  @include media(">sm") {
    max-width: 380px;
  }

  @include media(">md") {
    width: 380px;
  }

  img {
      width: 100%;
  }
}

.nav-content {
  background: $gray-600;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
  transform: translateX(-100vw);
  transition: transform .3s;
  height: 100vh;
  overflow-y: auto;
  padding-top: 40px;
  display: block !important;

  @include media(">sm") {
    padding-top: 90px;
    transition: transform .2s;
  }

  @include media(">lg") {
    position: static;
    transform: none;
    background: transparent;
    padding-top: 0;
    height: auto;
    z-index: 2;
    transition: none;
  }

  a {
    font-size: 16px;
    font-weight: 500;
  }

  .smallHeader & {

    @include media(">lg") {
      position: absolute;
      transform: translateX(-100vw);
      background: $gray-600;
      padding-top: 90px;
      z-index: -2;
      height: 100vh;
      transition: transform .3s;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bottom-nav {

      .row {

        @include media(">lg") {
          justify-content: center !important;
        }
      }
    }
  }

  header.show & {
    transform: none;
    margin-left: 0 !important;
  }
}

.nav-toggler {
  @include size(18px, 16px);
  position: relative;
  margin-left: 10px;

  @include media(">sm") {
    @include size(30px, 26px);
  }

  @include media(">lg") {
    display: none;
  }

  .initialStatePassed & {

    @include media(">lg") {
      display: block;
    }
  }

  &:before,
  &:after,
  span {
    @include size(100%, 4px);
    position: absolute;
    background: $white;
    right: 0;
    width: 18px;

    @include media(">sm") {
      height: 6px;
      left: 0;
      width: 100%;
    }
  }

  &:before,
  &:after {
    transform: none;
    transition: transform .3s;
    content: "";
    transform-origin: left;
  }

  span {
    transition: opacity .3s;
    opacity: 1;
    top: 6px;

    @include media(">sm") {
      top: 10px;
    }
  }

  &:after {
    top: 12px;

    @include media(">sm") {
      top: 20px;
    }
  }

  &[aria-expanded="true"] {

    span {
      opacity: 0;
    }

    &:before {
      transform: rotate(42deg);
    }

    &:after {
      transform: rotate(-42deg);
    }
  }
}

.top-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 0;

  @include media(">sm") {
    justify-content: flex-end;
  }

  @include media(">md") {
    align-items: center;
  }

  .header & {
    margin-bottom: 20px;
  }

  >li {
    order: 3;
    margin: 0 14px;

    @include media(">sm") {
      line-height: 1;
    }

    &.language-select {
      order: 1;

      @include media(">sm") {
        order: 10;
      }
    }
  }
}

.language-select {
  width: 100%;

  @include media(">sm") {
    width: auto;
  }

  header:not(.small-header) & {

    @include media(">lg") {
      position: relative;
    }

    &:hover,
    &:focus {

      .lang-options {

        @include media(">lg") {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .lang-options {
    opacity: 0;

    li {
      padding: 0 10px;
      margin-bottom: 11px;
    }
  }

}

.lang-select-opener {
  display: none;

  header:not(.smallHeader) & {

    @include media(">lg") {
      display: inline-block;
      position: relative;
      text-align: right;
    }

    i {
      margin-left: 5px;
    }
  }
}

.lang-options {
  display: flex;
  justify-content: center;

  .header & {
    margin-bottom: 40px;
    margin-top: 20px;

    @include media(">sm") {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .active a {
    pointer-events: none;
  }

  header:not(.smallHeader) & {

    @include media(">lg") {
      flex-direction: column;
      position: absolute;
      top: 25px;
      right: 0;
      padding: 15px 5px;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      background: $white;
    }

    .active a {

      @include media(">lg") {
        background: $gray-600 ;
        color: $white;
      }
    }

    li {

      @include media(">lg") {
        display: block;
      }
    }

    a {

      @include media(">lg") {
        padding: 9px 15px;
        display: block;
        color: $gray-600;
        background: $white;
        transition: color.3s, background .3s;
        text-align: center;
      }

      &:hover {

        @include media(">lg") {
          color: $white;
          background: $gray-600;
          text-decoration: none !important;
        }
      }
    }
  }

  li {
    position: relative;
    line-height: 1;
    margin-bottom: 0;

    &:not(:first-child) {

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: $white;
        content: "";
        left: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
      padding: 0 10px;
    }
  }
}

.socials-holder {
  width: 100%;

  @include media(">md") {
    width: auto;
  }

  .top-nav & {
    order: 2;

    @include media(">sm") {
      order: 15;
    }
  }
}

.socials {
  margin-bottom: 20px;

  @include media(">sm") {
    margin-top: 20px;
  }

  @include media(">md") {
    margin: 0;
  }

  li {
    margin: 0 7px;

    a {
      font-size: 40px !important;
      transition: color .3s;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  i{
    transform: scale(1);
  }
}

.smallHeader {
  .language-select {
    .lang-options {
      li {
        margin-bottom: 0;
        padding: 0 10px;
      }
    }
  }
}
.header.show {
  .language-select {
    .lang-options {
      opacity: 1;

      li {
        margin-bottom: 0;
        padding: 0 10px;
      }
    }
  }
  .socials {
    li {
      a {
        font-size: 40px !important;
        opacity: 1;
      }
    }
  }
}

.no-link {
  .nav-block {
    .block-title > a {
      pointer-events: none;
    }
  }
}

body:not(.initialStatePassed) .header:not(.smallHeader) .top-nav > li:hover >ul {   /*rainer*/
  margin-top: 0;
}